import React, { Component } from 'react';
import Pagination from '../common/pagination';
import OrdersTable from '../common/ordersTable'
import { getCustomerOrders } from '../../services/cartService';

class MyOrders extends Component {

    state = {
        sortColumn: {path:'created',order:'desc'},
        orders: {},
        start: 0,
        limit: 10,
        init: false
    } 

    async componentDidMount() {
        if(!this.state.init) {
            try {
                const orders = await this.getOrders(0);
                const limit = orders.pageInfo.pageSize;
                this.setState({orders,limit,init:true});
            }
            catch(exception) {
                if(exception.response && exception.response.status >= 400) {
                    const errors = {...this.state.errors};
                    console.log(exception.response.data.message);
                }
            }
        }
    }

    handleSortByColumn = sortColumn => {     
        this.setState({sortColumn});
    }

    handleFilterByPage = async (page) => { 
        let orders = this.state.orders;
        const start = orders.pageInfo.pageSize * (page-1);
        orders = await this.getOrders(start);
        const limit = orders.pageInfo.pageSize;
        this.setState({orders, start, limit});
    }
    
    async getOrders(start) {
        const orders = await getCustomerOrders(start, this.state.limit);
        for (var i=0;i<orders.documents.length;i++) {
            const orderTotal = orders.documents[i].totalPrice + orders.documents[i].shippingTotalPrice + orders.documents[i].shippingSalesTax;
            orders.documents[i].shippingText = orders.documents[i].shipping.shipperName;
            orders.documents[i].totalText = orderTotal;
        }
        return orders;
    }

    render() { 
        const {orders,init} = this.state;
        if(!init) {
            return '';
        }
        return (
            <React.Fragment>
                <h1>My Orders</h1>
                <Pagination 
                    currentPage={orders.pageInfo.currentPage}
                    itemsCount={orders.pageInfo.rowCount} 
                    pageSize={orders.pageInfo.pageSize}
                    onPageChange={this.handleFilterByPage} />
                <OrdersTable 
                    orders={orders.documents}
                    sortColumn="created"
                    onSort={this.handleSortByColumn} 
                    orderPage="/account/order/"
                    storePage="/store/"
                />

            </React.Fragment>
        );
    }
}
 
export default MyOrders;