//import jwtDecode from 'jwt-decode';
import http from "./httpService";
import config from "../config.json";

const imageEndpoint = `${config.imageApiRouteBase}`;

export async function saveImage(storeUuid, formdata) { //, headers) {
  const axiosConfig = {headers:
    { 'content-type': 'multipart/form-data' }
  };
  // 'Content-Type': `multipart/form-data; boundary=----------------planetB`
  let response = await http.post(imageEndpoint + "/new/" + storeUuid, 
  formdata, axiosConfig);
  return response.data;
}
export async function getImagesData(imageData) {
  let response = await http.post(imageEndpoint + "s", imageData);
  return response.data; 
}
export async function getImage(id) {
  let response = await http.get(imageEndpoint + "/" + id);
  return response.data; 
}
export async function deleteImage(id) {
  let response = await http.delete(imageEndpoint + "/" + id);
  return response.data; 
}