import React, { Component } from 'react';
import Table from "../common/table";
import Pagination from '../common/pagination';
import { toast } from 'react-toastify';
import * as adminService from '../../services/adminService';
import { Link } from "react-router-dom";
import SearchBox from '../common/searchBox';
import {formatTimestamp} from "../../utils/format.js"

class DashboardMerchantTable extends Component {
    
    state = {  
        merchantData: [],
        searchValue: '',
        limit: 10,
        sortColumn: {path:"updated", order:"desc"},
        init: false
    } 

    async componentDidMount() {
        if(this.state.init) {
            return;
        }
        const merchantData = await this.getData(0, this.state.limit, this.state.sortColumn.path, this.state.sortColumn.order,  '');
        this.setState({merchantData,init:true});
    }

    getData = async(start, limit, sortColumn, sortOrder, query) => {
        let merchantData = [];
        try {
            let response = await adminService.getDashboardData('merchant',query,start,limit);
            if(response.data) {
                merchantData = response.data;
            }
        } catch(exception) {
            if(exception.response.data) {
                const msg = exception.response.data.message ? exception.response.data.message : "an unhandled exception occurred";
                toast.error(msg);
            } else {
                toast.error("an unhandled exception occurred");
            }
        }
        return merchantData;
    }

    handleFilterByPage = async (page) => {    
        const pageInfo = this.state.merchantData.pageInfo;
        pageInfo.currentPage = page;
        const start = pageInfo.pageSize * (page-1);
        
        const merchantData = await this.getData(start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order,this.state.searchValue);
        this.setState({merchantData});
    }
    
    handleSortByColumn = async sortColumn => { 
        const pageInfo = this.state.merchantData.pageInfo;
        const merchantData = await this.getData(pageInfo.start, pageInfo.pageSize, sortColumn.path, sortColumn.order,this.state.searchValue);
        this.setState({merchantData,sortColumn});
    }

    handleSearch = async searchValue => {
        const pageInfo = this.state.merchantData.pageInfo;
        const merchantData = await this.getData(pageInfo.start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, searchValue);
        this.setState({searchValue,merchantData});
    }

    render() { 
        const {merchantData, searchValue, sortColumn} = this.state;
        const tableColumns = [
            {
                name: 'Company', 
                path: "company.companyName",
                content: merchant => <Link to={`/business/${merchant.uuid}`}>{merchant.company.companyName}</Link>
            },
            {
                name: 'City', 
                path: 'location.city'
            },
            {
                name: 'State', 
                path: 'location.state'
            },
            {
                name: 'Verified?', 
                path: 'verified',
                content: merchant => merchant.verified === true ? "yes" : "no"
            },
            {
                name: 'Blocked?', 
                path: 'blocked',
                content: merchant => merchant.blocked === true ? "yes" : "no"
            },
            {
                name: 'Uploads Allowed?',
                path: 'uploadsAllowed',
                content: merchant => merchant.uploadsAllowed === true ? "yes" : "no"
            },
            {
                name: 'Created',
                path: 'created',
                content: merchant => formatTimestamp(merchant.created, "timestamp")
            },
            {
                name: 'Updated',
                path: 'updated',
                content: merchant => formatTimestamp(merchant.updated, "timestamp")
            }
        ];

        if(merchantData && merchantData.pageInfo && null !== merchantData.documents) {
            return (
                <React.Fragment>
                    <Pagination 
                        currentPage={merchantData.pageInfo.currentPage}
                        itemsCount={merchantData.pageInfo.rowCount} 
                        pageSize={merchantData.pageInfo.pageSize}
                        onPageChange={this.handleFilterByPage} />  
                    <SearchBox value={searchValue} onChange={this.handleSearch} />  
                    <Table 
                        tableClasses="pb-table dashboard-table"
                        data={merchantData.documents}
                        tableColumns={tableColumns}
                        sortColumn={sortColumn}
                        onSort={this.handleSortByColumn}
                    />
                </React.Fragment>
            );    
        } else {
            return (
                <React.Fragment>
                    <SearchBox value={searchValue} onChange={this.handleSearch} />
                    <p>No matching records.</p>
                </React.Fragment>
            );       
        }
    }
}

export default DashboardMerchantTable;