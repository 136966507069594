import React, {useEffect} from 'react';
import {logout} from '../services/userService';

const Logout = () => {
    useEffect(async () => {
        await logout();
        window.location= '/';
    },[]);
    return(<React.Fragment></React.Fragment>);
}
 
export default Logout;