import React, { Component } from 'react';
import { Link } from "react-router-dom";
class HomeNyc extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="landing1">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col col-md-6">
                                    <h2 className="fatty">What is Planet B Market?</h2>
                                    <p>Planet B is an online marketplace for local and small businesses. Planet B provides one-stop
                                        shopping to make it easy for customers to shop with their heart.
                                        <br />&nbsp;<br />
                                        Register your store to join our official launch on October 17, 2023!
                                        <br />&nbsp;<br />
                                        <Link to={"/signup"}>
                                                <button alt={"register your store"} className="btn btn-success">Click here to register your store</button>
                                        </Link>
                                    </p>
                                    <h2 className="fatty">How does it work?</h2>
                                    <p>Small businesses create their online stores in Planet B Market.&nbsp;
                                        Customers shop across all of the market's stores, and can filter by store location, ownership
                                        and more.&nbsp;
                                        Customers can place orders containing items from one or several stores. The market platform
                                        magically calculates, collects, and remits sales tax for orders.&nbsp;
                                        Each merchant receives notifications when items from their stores are purchased, and handles
                                        order fulfillment for their items.</p>
                                </div>

                                <div className="col col-md-6">
                                    <h2>One-Stop Local and Small Business Shopping</h2>
                                    <p>Planet B is an online marketplace for local and small businesses. Planet B provides one-stop
                                        shopping to make it easy for customers to shop with their heart.</p>
                                    <h2>Stay Local, Sell National</h2>
                                    <i className="fa-solid fa-flag-usa fa-2xl"></i>
                                    <p>Open your doors to the entire USA from the comfort of your existing business.</p>
                                    <h2>No Commitment, Low Fees</h2>
                                    <p>Cancel anytime, no contract required, no membership fees, no hidden fees.
                                        Planet B only charges when sales are made, 6% and 30 cents per transaction, which includes payment processing fees.
                                        <br /><em>A discounted rate is in effect for all sales placed in 2023: 2.9% and 30 cents per transaction.</em></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <h2>Reach out for more information</h2>
                                    <div className="fatty">info@planetbmarket.com</div>
                                </div>
                                <div className="col col-md-6">
                                    <div className="fatty">844-Planet-B</div>
                                    <br />844-752-6382
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HomeNyc;