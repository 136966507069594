import { useEffect } from 'react'; 
import { useParams } from "react-router-dom";

const RouteParams = (props) => {

    //const [searchParams] = useParams();
    const routeParams = useParams();
    const onGetParams = props.onGetParams;
    useEffect(() => {      
      // raise the params up to the parent to handle
      onGetParams(routeParams);
    }, [routeParams,onGetParams]);
 
    return '';
  };
  

export default RouteParams;