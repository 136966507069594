import React from 'react';
import _ from 'lodash';
import { getContextualPages } from '../../utils/pagination';
import PropTypes from 'prop-types';

const Pagination = ({currentPage, pageSize, itemsCount, onPageChange }) => {
    // or, destructure right in the argument as above
    //const {currentPage, pageSize, itemsCount, onPageChange } = props;
    //const start = currentPage === 1 ? 0 : currentPage * pageSize - 1;
    const pagesCount = Math.ceil(itemsCount / pageSize);
    const pages = getContextualPages(currentPage, pagesCount);

    // straight js way of doing the same thing:
    //pages = Array(pageCount).fill().map((_, idx) => 1 + idx)
    if(pages.length > 1) {
        return(
            <nav aria-label="Page navigation">
                <ul className="pagination">
                { pages.map(page => 
                    getPageLink(page, currentPage, onPageChange, pages, pagesCount)
                    ) 
                }
                </ul>
            </nav>
        );
    }
    else {
        return (<nav />);
    }
};

function getPageLink(page, currentPage, onPageChange, pages, pagesCount) {
    const iPage = parseInt(page, 10);
    if(isNaN(iPage)) {
        let rando = pagesCount + Math.floor(Math.random() * 100);
        for(var p = 0; p < pages.length; p++) {
            if(rando === p) {
                rando++;
            }
        }
        return (
            <li key={rando} className="page-item">
                <div className="page-nolink" href="#">{page}</div>
            </li>
        );
    } else {
        return (
            <li key={page} className={page === currentPage ? "page-item active" : "page-item"}>
                <a onClick={() => onPageChange(page)} className="page-link" href="#">{page}</a>
            </li>
        );
    }
}

// Defining property types 
// forces the caller to pass properties as defined here
Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired, 
    pageSize: PropTypes.number.isRequired, 
    itemsCount: PropTypes.number.isRequired, 
    onPageChange: PropTypes.func.isRequired
};

export default Pagination;