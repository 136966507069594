import React from 'react'; // , {Component, Fragment}
import Joi from 'joi-browser';
import InputForm from './inputForm';
import QueryParams from './queryParams';

class SearchForm extends InputForm {

    state = {
        data: {searchterm: ''},
        errors: {}
    };

    // schema doesn't have to be part of the state
    // because the schema doesn't change
    schema = {
        searchterm: Joi.string().label('Search term').allow(''),
    };
  
    getParams = async (queryParams) => {
        const searchValue = queryParams.q ? queryParams.q : '';
        this.setState({data:{searchterm:searchValue}});
    }
     
    doSubmit = async() => {
        try {
            window.location = '/products?q=' + this.state.data.searchterm;
        }
        catch(exception) {
            //console.log('exception', exception);
            if(exception.response && exception.response.status === 400) {
                const errors = {...this.state.errors};
                errors.searchterm = exception.response.data;
                this.setState({errors});
            }
        }
    }

    render() { 
        return (
            <div className="search-form center-align">
                <form onSubmit={this.handleSubmit}>
                    {
                        this.renderInput(
                        "searchterm", "", "text", 
                        "", ""
                        )
                    }
                    { this.renderSubmitButton("Search") }
                </form>
                <QueryParams onGetParams={this.getParams} />                
            </div>
        );       
    }
};

export default SearchForm;
