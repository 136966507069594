import React from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import { cancelOrder } from '../../services/merchantService';
import { getUserUuid } from '../../services/authenticationService';

class MyStoreOrderCancelForm extends InputForm {

    state = {
        orderUuid: null,
        order: {},
        data: {
            comment: '',
        },
        errors: {},
        schema: {
            _id: Joi.string(),
            comment: Joi.string().required().label('Comment'),            
        }
    };

    async componentDidMount() {
        const {order} = this.props;
        const orderUuid = order.uuid;
        this.setState({ order, orderUuid });
    }

    doSubmit = async() => {
        try {
            let {order, orderUuid, data} = this.state;
            let commentObj = {};
            if(data.comment.length > 0) {
                const userUuid = getUserUuid();
                commentObj = {
                    userUuid: userUuid,
                    commentText: data.comment
                };
            }
            await cancelOrder(orderUuid, commentObj)
            toast.success("Canceling payment...");
            setTimeout( () =>{
                window.location = '/mybusiness/' + order.merchantUuid + '?tab=orders';
            }, 3500);
        }
        catch(exception) {
            this.setState({working:false});
            toast.error("An unexpected error occurred!");
        }
    }
           
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Cancel Order</h1>
                        <p>Use the form below to cancel this order. Please enter a comment to let the customer know why you are canceling the order.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                "comment", "Comment", "textarea",
                                "", "", ""
                                )
                            }
                            { this.renderSubmitButton("Cancel order") }
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )   
    }
}

export default MyStoreOrderCancelForm;