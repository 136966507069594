import React, { Component } from 'react';
import TextBlock from './textBlock';
import { Link } from "react-router-dom";

const Footer = (props) => {
    return (
        <div className="footer">            
            <div className="row">
                <div className="col-0 col-md-1">
                </div>
                <div className="col-12 col-md-3">
                    <div className="footer-link">
                        <Link to={`/signup`} tabIndex="0" title="Register your store">Register Your Store</Link>
                    </div>
                    <div className="footer-link">
                        <Link to={`/about`} title="About Planet B Market" tabIndex="0">About</Link>
                    </div>
                    <div className="footer-link">
                        <Link to={`/faq`} tabIndex="0" title="Frequently Asked Questions">FAQ</Link>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="footer-link">
                        <Link to={`/privacy`} title="Privacy" tabIndex="0">Privacy Policy</Link>
                    </div>
                    <div className="footer-link">
                        <Link to={`/terms`} title="Terms of Service" tabIndex="0">Terms of Service</Link>
                    </div>
                    <div className="footer-link">
                        <Link to={`/careers`} title="Careers" tabIndex="0">Careers</Link>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="footer-branding">
                        <div className="footer-text">info@planetbmarket.com</div>
                        <div className="footer-text phone">844-PLANETB (844-752-6382)</div>
                    </div>
                </div>
                <div className="col-0 col-md-1">
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextBlock message={'Copyright ' + new Date().getFullYear() + " Planet B"} classes="centered"></TextBlock>
                </div>
            </div>
        </div>
    );
}
 
export default Footer;