import http from "./httpService";
import jwtDecode from 'jwt-decode';
import config from "../config.json";
import authenticationService from '../services/authenticationService';

const registerEndpoint = `${config.usersApiRouteBase}/register`;
const registerMerchantEndpoint = `${config.usersApiRouteBase}/registerMerchant`;
const userBaseEndpoint = `${config.usersApiRouteBase}/`;
const userAllowedEndpoint = `${config.usersApiRouteBase}/allowed`;
const loginEndpoint = `${config.usersApiRouteBase}/login`;
const forgotPasswordEndpoint = `${config.usersApiRouteBase}/forgotPassword`;
const logoutEndpoint = `${config.usersApiRouteBase}/logout`;
const verifyEndpoint = `${config.usersApiRouteBase}/verify`;

export async function register(user) {
  // put user object containing username, password to users/register
  let response = await http.put(registerEndpoint, user);
  return response;
}

export async function registerMerchant(merchantSignup) {
  let response = await http.put(registerMerchantEndpoint, merchantSignup);
  return response;
}

export async function verify(user) {
  // put user object containing username, token to users/verify
  let response = await http.post(verifyEndpoint, user);
  return response;
}

export async function forgotPassword(user) {
  let response = await http.post(forgotPasswordEndpoint, user);
  return response;
}

export async function login(user) {
  let response = await http.post(loginEndpoint, user);
  return response;
}

export async function logout() {
  let response = {};
  try {
    response = await http.post(logoutEndpoint);
  } catch(ex) {

  }
  await authenticationService.logout();
  return response;
}

export async function getUser() {
  const userid = authenticationService.getUserUuid();
  if(userid) { 
    try {
      let response = await http.get(userBaseEndpoint + userid);
      return response;
    }
    catch(ex) {
    }
  } 
  return null;
}

export async function userMayAccess(user, entityType, action, id) {
  const request = {
    "uuid": user.uuid,
    "entity": entityType,
    "action": action,
    "id": id
  };
  let response = await http.post(userAllowedEndpoint, request);
  return response;
}

export async function saveProfile(user) {
  // const profileToSave = {
  //   email: user.username,
  //   password: user.password,
  //   name: user.name
  // }
  let response = await http.put(userBaseEndpoint + user.uuid, user);
  return response;
}