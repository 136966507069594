import React from 'react';

const Input = ({label, name, error, helpId, helpText, classes, ...rest}) => {
    const helpDiv = !helpId.length ? '' : <small id={helpId} className="form-text text-muted">{helpText}</small>;
    const describedBy = !helpId.length ? '' : helpId;
    classes = "form-group " + (classes ? classes : "");
    return( 
        <div className={classes}>
            <label htmlFor={name}>{label}</label>
            <input 
                {... rest}
                name={name} 
                className="form-control"
                id={name} 
                data-errortext={helpText && helpText.length > 0 ? helpText : ""}
                aria-describedby={describedBy} 
                 />
            {helpDiv}
            { error && <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            }
        </div>
    )
}
 
export default Input;