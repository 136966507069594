import React,{ useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";
import { getMerchantOrder } from '../../services/merchantService';
//import { getPaymentDetails } from '../../services/paymentService';
import OrderView from '../common/orderView';
import MyStoreOrderEditForm from './myStoreOrderEditForm';
import MyStoreOrderCancelForm from './myStoreOrderCancelForm';

const MyStoreOrder = ({label, name, error, helpId, helpText, ...rest}) => {
    const {id} = useParams();
    const [orderData, setOrderData] = useState();
    const [message, setMessage] = useState("");

    useEffect(async () => {
        const getOrderData = async () => {
            const order = await getMerchantOrder(id);
            // const payment = await getPaymentDetails(order.paymentIntentId);
            // console.log(payment);
            return order;
        }

        if(!orderData) {
            const o = await getOrderData();
            setOrderData(o);

            if(o.orderState === "processing" && !o.connectedAccountId) {
                const message = "This payment cannot be captured yet because your store doesn't have a bank account connected to the market. The payment processor releases uncaptured authorizations/holds for funds 5 days after the auth date. Please contact support to take care of this before the hold expires, otherwise this order will be canceled.";
                setMessage(message);
            }
        } 

    },[]);

    if(orderData) {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12"><h1>Order Details</h1></div>
                    {message && <p className="urgent">{message}</p>}
                </div>
                <div className="row">
                    <div className="col">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link className="breadcrumb-item" to={'/mybusiness/' + orderData.merchantUuid + '?tab=orders'}>
                                    Store Orders
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                        </ol>
                    </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Link to={"/mystore/order/" + orderData.uuid + "/shipslip"}>view printable order slip</Link>
                        <OrderView order={orderData} isCustomer={false} />
                    </div>
                </div>
                {orderData.orderState === "processing" && orderData.connectedAccountId && <div className="row">
                    <div className="col-6">
                        <MyStoreOrderEditForm order={orderData} />
                    </div>
                    <div className="col-6">
                        <MyStoreOrderCancelForm order={orderData} />
                    </div>
                </div>}
            </React.Fragment>
        );
    }
    else {
        return ('');
    }
}
 
export default MyStoreOrder;