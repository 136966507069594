import React, { Component, Fragment } from 'react';
import Joi from 'joi-browser';
import InputForm from '../common/inputForm';
import ImageUpload from '../common/imageUpload';

class ImageEdit extends InputForm {

    state = {
        data: {
            _id:'',
            alt: ''
        },
        errors: {}
    };

    schema = {
        _id: Joi.string(),
        alt: Joi.string().required().label('Alternative Text'),
    };

    async componentDidMount() {
        let image = this.props.imageData; 
        this.setState({data:this.mapToViewModel(image)});
    }
  
    mapToViewModel(image) {
        return {
            _id: image.uuid,
            alt: image.alt
        };
    }

    handleSave = async(evt, imageData) => {
        evt.preventDefault();
        // bubble the save to the parent
        await this.props.handleSave(evt,imageData);     
    } 
  
    render() {
        const {parentEntity, handleSubmit, handleCancel} = this.props;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Upload Image for {parentEntity}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={handleSubmit}>
                            <ImageUpload 
                                label="Image" 
                                name="upload" // this has to match the name of the field expected by the API
                                fileTypes=".jpg,.jpeg,.png"
                                error="" 
                                helpId="storeImageHelp"
                                helpText=""
                                classes=""
                            />
                            {
                                this.renderInput(
                                "alt", "Alternative Text", "text", 
                                "altHelp", "Text to be displayed when image cannot be rendered", ""
                                )
                            }
                            <button className="btn btn-dark btn-rightspace float-left" onClick={(evt) => this.handleSave(evt,this.state.data)}>Save</button>
                            <button className="btn btn-danger btn-rightspace float-left" onClick={handleCancel}>Cancel</button>

                        </form>
                    </div>
                </div>
            </React.Fragment>
        )   
    }
}

export default ImageEdit;