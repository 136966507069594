import React, { Component } from 'react';
import config from "../../config.json";
class Terms extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Terms of Service</h1>
                        <p>Version 1.3 effective 2023-07-31</p>

                        <h2>General</h2>
                        <p>Planet B Market ("Planet B") provides the information and features on this website as provided in these
                            Terms of Service (TOS). Planet B may update the TOS at any time and without prior
                            notice by posting a new version at
                            &nbsp;<a title="link to Planet B terms of service" href="/terms">{config.reactUrl}/terms</a>.
                            The information on this site and your use of it is subject to the most recent version
                            of the TOS posted. We reserve the right to modify or replace these terms at any time.
                            If you continue to use this service after revisions become effective, you agree
                            to be bound by the revised terms.
                        </p>
                        <p>Registration data and certain other information about you is subject to our
                            &nbsp;<a title="link to Planet B privacy policy" href="/privacy">privacy policy</a>.</p>
                        <p>You agree that Planet B may terminate your access to the Planet B website and services
                            for violations of the TOS and/or legitimate
                            verified requests by authorized law enforcement or other government agencies.</p>
                        <p>The TOS constitutes the entire agreement between you and Planet B and governs your use
                            of the Planet B website and services, superseding any prior agreements between you and
                            Planet B with respect to the Planet B website and services.
                        </p>
                        <p>
                            The failure of Planet B to exercise or enforce any right or provision of the TOS shall not
                            constitute a waiver of such right or provision. If any provision of the TOS is found by a
                            court of competent jurisdiction to be invalid, the parties nevertheless agree that the court
                            should endeavor to give effect to the parties' intentions as reflected in the provision, and
                            the other provisions of the TOS remain in full force and effect.
                        </p>

                        <h2>Using Planet B Market</h2>
                        <p>You understand that all information, data, text, software, graphics or other materials
                            ("Content"), whether publicly posted or privately transmitted, are the sole responsibility
                            of the person from whom such Content originated. This means that you, and not Planet B,
                            are entirely responsible for all Content that you upload, post, email, transmit or
                            otherwise make available via Planet B Market.</p>

                        <p>Planet B does not control the Content posted via the Planet B Market and,
                            as such, does not guarantee the accuracy, integrity or quality of such
                            Content. Under no circumstances will Planet B be liable in any way for any
                            Content, including, but not limited to, any errors or omissions in any
                            Content, or any loss or damage of any kind incurred as a result of the
                            use of any Content posted, emailed, transmitted or otherwise made available
                            via the Planet B Service.</p>

                        <p>You agree to <strong>not</strong> use the Planet B Service to:
                        </p>
                        <ul>
                            <li>upload, post, email, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable or harmful to minors;</li>
                            <li>impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                            <li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Planet B website or services;</li>
                            <li>screen scrape or crawl the website, manually copy or otherwise copy the content of the Planet B website</li>
                            <li>upload, post, email, transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or other relationships;</li>
                            <li>upload, post, email, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;</li>
                            <li>upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," or any other form of solicitation;</li>
                            <li>upload, post, email, transmit or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                            <li>disrupt the normal flow of dialogue, or otherwise act in a manner that negatively affects other users' ability to engage in shopping or selling;</li>
                            <li>interfere with or disrupt the Planet B website or services, or servers or networks connected to the Planet B website and services; or disobey any requirements, procedures, policies or regulations of networks connected to the Planet B website and services;</li>
                            <li>intentionally or unintentionally violate any applicable local, state, national or international law,</li>
                            <li>"stalk" or otherwise harass another; and/or collect or store personal data about other users in connection with the prohibited conduct and activities set forth in paragraphs above</li>
                        </ul>

                        <p>You promise us that:</p>
                        <ul>
                            <li>You are of legal age to form a binding contract and are not a person barred from receiving services under the laws of the United States or other applicable jurisdiction</li>
                            <li>You have the legal right to use the credit card that is used to pay your purchase.</li>
                            <li>You will provide true, accurate, current and complete information about yourself as prompted when you submit any information on our site.</li>
                            <li>You will maintain and promptly update your account and store data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Planet B has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Planet B has the right to suspend or terminate your account and refuse any and all current or future use of the Planet B Market, or any portion thereof.</li>
                            <li>You won't share your username and password with anyone else.</li>
                            <li>You will notify us immediately if you find a security vulnerability from this website.</li>
                            <li>You will notify us immediately if you notice an unauthorized use of your user account.</li>
                            <li>You accept the privacy policy of this website. Note that this doesn't mean that you waive your individual rights described in the privacy policy.</li>
                        </ul>

                        <p>You acknowledge that Planet B may or may not pre-screen Content, but that
                            Planet B and its designees shall have the right (but not the obligation)
                            in their sole discretion to pre-screen, refuse, move, or remove any Content
                            that is available via the Planet B website and which violates the TOS.
                        </p>
                        <p>You agree that you must evaluate, and bear all risks associated with,
                            the use of any Content, including any reliance on the accuracy,
                            completeness, or usefulness of such Content. You acknowledge,
                            consent and agree that Planet B may access, preserve and disclose
                            your account information and Content if required to do so by law
                            or in a good faith belief that such access preservation or disclosure
                            is reasonably necessary to:
                        </p>
                        <ul>
                            <li>comply with legal process;</li>
                            <li>enforce the TOS;</li>
                            <li>respond to claims that any Content violates the rights of third parties;</li>
                            <li>respond to your requests for customer service; or</li>
                            <li>protect the rights, property or personal safety of Planet B its users and the public.</li>
                        </ul>

                        <h2>Selling on Planet B Market</h2>
                        <p>Planet B Market exists to provide one-stop local shopping. Toward that aim,
                            Planet B Market is only for use by two types of merchants: brick and mortar stores;
                            and manufacturers, artists and artisans who are producing goods.
                            Planet B Market is specifically not available for use by businesses that
                            exist as virtual (not brick-and-mortar) reseller entities, entities that
                            resell products made by others and who do not have a brick-and-mortar public store.
                            We reserve the right to deactivate merchant accounts and stores that attempt
                            to skirt this requirement.</p>
                        <p>As a merchant selling on the Planet B Market you agree to all of the
                            Terms of Service, and additionally you agree to:</p>
                        <ul>
                            <li>accurately present your business and all of the merchandise you sell&nbsp;
                                on Planet B Market; including an accurate count of the available stock&nbsp;
                                of each product and factual information about the product,&nbsp;
                                and any other details that are listed about the product;</li>
                            <li>un-publish or delete products which are no longer available;</li>
                            <li>respond to orders placed with your Planet B store within one (1)&nbsp;
                                business day of receipt of the order notification; where responding&nbsp;
                                means acknowledging the order, or performing any number of order&nbsp;
                                actions such as confirming shipping price or actually&nbsp;
                                shipping the order;</li>
                            <li>report to Planet B support any problematic customers, defined as&nbsp;
                                those communicating to you in a threatening or abusive manner,&nbsp;
                                repeatedly generating chargebacks for your store,&nbsp;
                                repeatedly canceling orders, or any other behavior that&nbsp;
                                is problematic for you as the merchant;</li>
                            <li>sell only products which are legal to sell in your jurisdictions and&nbsp;
                                the United States of America; additionally that you will not list nor&nbsp;
                                attempt to sell on Planet B cannabis products, tobacco products, nor firearms,&nbsp;
                                in accordance with the terms specified by our payment processor, Stripe.
                                Read more in Stripe's terms:&nbsp;
                                <a title="Stripe terms for online selling" href="https://stripe.com/en-gb-us/legal/restricted-businesses">https://stripe.com/en-gb-us/legal/restricted-businesses</a>
                            </li>
                        </ul>

                        <h2>Disclaimer of Warranties, Limitation of Liabilities</h2>
                        <p>We try to keep our website and services safe, secure, and functioning
                            properly, but we cannot guarantee the continuous operation of or
                            access to our website and services. Notification (email and text messages)
                            and other functionality in Planet B's applications may not occur in real
                            time. Such functionality is subject to delays beyond Planet B's control.
                        </p>
                        <p>You agree that you are making use of our website and services at your own risk,
                            and that they are being provided to you on an "AS IS" and "AS AVAILABLE" basis.
                            Accordingly, to the extent permitted by applicable law, we exclude all express
                            or implied warranties, terms and conditions including, but not limited to,
                            implied warranties of merchantability, fitness for a particular purpose,
                            and non-infringement.
                        </p>
                        <p>In addition, to the extent permitted by applicable law, in no event will
                            Planet B be liable to you or any third party under any claim at law or
                            in equity for any consequential damages or losses (including, but not
                            limited to, loss of money, goodwill or reputation, profits, other
                            intangible losses, or any special, indirect, or consequential damages),
                            and all such damages or losses are expressly excluded by this agreement
                            whether or not they were foreseeable or Planet B was advised of such
                            damages or losses. Without limiting the generality of the foregoing,
                            we (including our our officers, directors, agents and employees) are
                            not liable, and you agree not to hold us responsible, for any damages
                            or losses (including, but not limited to, loss of money, goodwill or
                            reputation, profits, other intangible losses, or any special, indirect,
                            or consequential damages) resulting directly or indirectly from:
                        </p>
                        <ul>
                            <li>the content you provide (directly or indirectly) using our website or services;</li>
                            <li>your use of or your inability to use our website or services;</li>
                            <li>pricing, shipping, format, or other guidance provided by Planet B;</li>
                            <li>delays or disruptions in our website or services;</li>
                            <li>viruses or other malicious software obtained by accessing or linking to our website or services;</li>
                            <li>glitches, bugs, errors, or inaccuracies of any kind in our website or services;</li>
                            <li>damage to your hardware device from the use of any Planet B website or services;</li>
                            <li>the content, actions, or inactions of third parties, including items listed using our website or services or the destruction of allegedly fake items;</li>
                            <li>a suspension or other action taken with respect to your account or breach of the Using Planet B Section above;</li>
                            <li>the duration or manner in which your listings appear in search results</li>
                            <li>your need to modify practices, content, or behavior, or your loss of or inability to do business, as a result of changes to this Terms of Service or our policies.</li>
                        </ul>
                        <p>Some jurisdictions do not allow the disclaimer of warranties or exclusion of damages,
                            so such disclaimers and exclusions may not apply to you.
                        </p>

                        <h2>Applicable Law</h2>
                        <p>You agree that, except to the extent inconsistent with or preempted
                            by federal law, the laws of the State of Maryland, without regard to
                            principles of conflict of laws, will govern this User Agreement and
                            any claim or dispute that has arisen or may arise between you and Planet B.
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Terms;