import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from '../common/pagination';
import RouteParams from '../common/routeParams';
import MyProductsTable from './myProductsTable';
import MyProductsGrid from './myProductsGrid';
import MyStoreProductsImport from './myStoreProductsImport';
import PopMakeSure from '../common/popMakeSure';
import * as merchantService from '../../services/merchantService';
import {getUser} from '../../services/userService';
import {deleteProduct} from '../../services/productService';
import { importProducts } from '../../services/productService';
import PopWait from '../common/popWait';

class MyStoreProducts extends Component {

  state = {
    storeUuid: '',
    store: {},
    searchValue: null,
    products: [],
    selectedProducts: [],
    pageInfo: {
      rowCount: 0,
      pageCount: 0,
      pageSize: 12,
      currentPage: 0
    },
    sortColumn: {path:'title',order:'asc'},
    viewType: "grid",
    init:false,
    displayImportForm: false,
    deleting: false,
    user: null,
    working: false
  }
  
  getQueryParams = async (queryParams) => {
    if(this.state.init === true) {
      // if we already loaded everything don't do it again
      return;
    }
    //@todo search param
    const searchValue = queryParams.q ? queryParams.q : ''; 
    //   const start = 0;
    //   const limit = 100;
    //   const searchResults = await doSearch(searchValue, selectedFacets, start, limit);
    //   this.setState({products:searchResults.documents, totalCount:searchResults.resultsCount,
    //     init:true, queryParams, searchValue, }); //productFacets:searchResults.facets,selectedFacets});
    this.setState({searchValue});
  }

  getRouteParams = async (routeParams) => {
    if(this.state.init === true) {
      // if we already loaded everything don't do it again
      return;
    }

    const storeUuid = routeParams.id;
    try {
      // this makes no sense but works
      // set init to true to pretend we finished getting data
      // in theory this would trigger a fresh render
      // but in practice it prevents multiple subsequent renders of the RouteParam component
      // which prevents 3x each API call
      this.setState({init:true});

      const userData = await getUser();
      const user = userData && userData.data ? userData.data : null;
      if(null === user) {
          window.location = '/login';
      }

      const storeResponse = await merchantService.getMerchantStore(storeUuid);
      const store = storeResponse.data;
      const productsData = await merchantService.getStoreProducts(storeUuid, 0, this.state.pageInfo.pageSize);
      this.setState({user,products:productsData.documents, storeUuid, store, 
        pageInfo: productsData.pageInfo});
    }
    catch(exception) {
      console.log(exception);
      if(exception.response && exception.response.status >= 400) {
          if(exception.response.status === 401) {
            //@tdo window.location = '/unauthorized';
          }
          toast.error(exception.response.data.message);
        }
        else if (exception.message) {
            toast.error(exception.message);
        }
    }  
  }

  handleDelete = async() => {      
    this.setState({deleting:true});
  }

  handleDeleteConfirm = async() => {
    const selectedProducts = this.state.selectedProducts;
    for (var idx=0;idx<selectedProducts.length;idx++) {
      await deleteProduct(selectedProducts[idx]);
    }
    // re-get the store products
    const {pageSize, currentPage} = this.state.pageInfo;
    const start = (((currentPage - 1) * pageSize) + 1);
    const productsData = await merchantService.getStoreProducts(this.state.storeUuid, start, pageSize);
    this.setState({products:productsData.documents, pageInfo: productsData.pageInfo, 
      selectedProducts:[], deleting:false});
  }

  handleDeleteCancel = async() => {
    this.setState({deleting:false});
  }

  handleImportProductsClick = async() => {
    this.setState({displayImportForm:true, working:false});
  }

  handleImportCancel = async(evt) => {
    evt.preventDefault();
    this.setState({displayImportForm:false, working:false});
  }

  handleImportProducts = async(evt) => {
    evt.preventDefault();
    try {
      this.setState({doDisplayForm:false,working:true});
      await this.importProducts(evt);

      // re-get the store products
      const {pageSize, currentPage} = this.state.pageInfo;
      const start = (((currentPage - 1) * pageSize) + 1);
      const productsData = await merchantService.getStoreProducts(this.state.storeUuid, start, pageSize);
      this.setState({products:productsData.documents, pageInfo: productsData.pageInfo});
    }
    catch(exception) {
      console.log(exception);
      if(exception.response && exception.response.status === 404) {
          // redirect to the Notfound page
          // replace the history so that clicking the back button 
          // goes to the last page prior to the one with the bad product id
          window.location = '/notfound';
      }
      else {
          console.log(exception);
          toast.error('An unhandled exception occurred!');
      }
    }
    this.setState({displayImportForm:false,working:false});
  }    

  importProducts = async(evt) => {
      let response = {};
      if(evt.target.form[0]) {
          const {storeUuid} = this.state;
          const file = evt.target.form[0].files[0];
          let formData = new FormData();
          formData.append(
              "upload",
              file,
              file.name
          );
          formData.append('notes', evt.target.form[1].value); 
          response = await importProducts(storeUuid, formData);
      }        
      return response;
  }

  handleFilterByPage = async (page) => {
    const {pageInfo} = this.state;
    pageInfo.currentPage = page;
    const start = pageInfo.pageSize * (page-1);
    const productsData = await merchantService.getStoreProducts(this.state.storeUuid, start, pageInfo.pageSize);
    this.setState({products:productsData.documents, pageInfo: productsData.pageInfo});
}

  handleSortByColumn = sortColumn => {     
    //@todo sort using query to API
    this.setState({sortColumn});
  }
  
  handleSelect = ({currentTarget}) => {
    const idParts = currentTarget.id.split("_");
    if(idParts.length < 2) {
      return;
    }
    const targetId = idParts[1];
    let selectedProducts = this.state.selectedProducts;
    const p = selectedProducts.find(x => x === targetId);
    if(!p && currentTarget.checked) {
      selectedProducts.push(targetId);
    } 
    else if (p && !currentTarget.checked) {
      selectedProducts = selectedProducts.filter(x => x !== targetId);
    }
    this.setState({selectedProducts});
  }

  // handleSearch = query => {
  //   this.setState({searchValue: query, currentPage: 1});
  // }

  getProductsMessage() {
    //const storeName = this.state.currentStore._id === '0' ? "" : " " + this.state.currentStore.name;
    const {rowCount, pageSize, currentPage} = this.state.pageInfo;
    const {searchValue} = this.props;
    
    if(rowCount === 0) {
      let searchStr = "";
      if(searchValue.trim().length > 0) {
        searchStr = ' for \'' + searchValue + '\'';
      }
      return('No matching products' + searchStr);
    }
    else {

        const pageStart = (((currentPage - 1) * pageSize) + 1);
        let pageEnd = pageStart + pageSize - 1;
        if(pageEnd > rowCount) {
          pageEnd = rowCount;
        }
        const pageMessage = pageStart + " to " + pageEnd;
        const matchMessage = searchValue && searchValue.length ? " products matching '" + searchValue + "'" : " products";
        return ('Showing ' + pageMessage + " of " + rowCount + matchMessage);
      }
  }
  
  renderProducts() {
    const {sortColumn, viewType, products, pageInfo, selectedProducts, 
      store, storeUuid } = this.state;
    const {rowCount, pageSize, currentPage} = pageInfo;

    // const {searchValue,selectedFacets} = this.props; //user,
    // console.log(products);
    // console.log(productFacets);
      return (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href={"/mybusiness/"+store.merchantUuid}>My Business</a></li>
                  <li className="breadcrumb-item"><a href={"/mybusiness/"+store.merchantUuid+"?tab=store-"+store.uuid}>{store.storeName}</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Products</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="content">
                  <Link to={"/mystore/" + storeUuid + "/products/new"}>
                    <button className="btn btn-success m-2 float-right">New Product</button>
                  </Link>
                  {store.uploadsAllowed && <button className="btn btn-success m-2 float-right" onClick={this.handleImportProductsClick}>Import Products</button>}
                  <button className="btn btn-danger m-2 float-right" onClick={this.handleDelete}>Delete Products</button>
                  <PopMakeSure 
                    handleConfirm={this.handleDeleteConfirm}
                    handleCancel={this.handleDeleteCancel}
                    visible={this.state.deleting} 
                    message="Are you sure you want to delete these products?"
                  />
                <div>
                  <div className="spaced-icon"><a onClick={() => this.showView('grid')} className={this.getViewClasses("grid",viewType)} title="grid view" tabIndex="0"><i className="fa fa-th-large" aria-hidden="true"></i></a></div>
                  <div className="spaced-icon"><a onClick={() => this.showView('list')} className={this.getViewClasses("list",viewType)} title="list view" tabIndex="0"><i className="fa fa-list" aria-hidden="true"></i></a></div>
                </div>
                {/* <SearchBox value={searchValue} onChange={this.handleSearch} /> */}
                <Pagination 
                  currentPage={currentPage}
                  itemsCount={rowCount} 
                  pageSize={pageSize}
                  onPageChange={this.handleFilterByPage} />               

                {this.getProductsView(viewType, products, sortColumn, selectedProducts)}

                <Pagination 
                  currentPage={currentPage}
                  itemsCount={rowCount} 
                  pageSize={pageSize}
                  onPageChange={this.handleFilterByPage} />               

              </div>
            </div>
          </div>
          {/* <RouteParams onGetParams={this.getRouteParams} />
          <QueryParams onGetParams={this.getQueryParams} /> */}
        </React.Fragment>
      );
    };

    getViewClasses(viewType, selectedViewType) {
      let classes = "view-link " + viewType;
      if(selectedViewType === viewType) {
        classes += " active";
      }
      return classes;
    }

    getProductsView(viewType, products, sortColumn, selectedProducts) {
      if((!products || products.length < 1) && this.state.store.uuid) {
        return(<p className="tall">This store doesn't have any products yet.</p>);
      }
      if(viewType === "grid") {        
        return (
          <MyProductsGrid
          products={products}
          selectedProducts={selectedProducts}
          store={this.state.store}
          onDelete={this.handleDelete}
          onSort={this.handleSortByColumn} 
          onSelect={this.handleSelect}
          />  
        );
      }
      else {
        return (
          <MyProductsTable 
          products={products}
          selectedProducts={selectedProducts}
          store={this.state.store}
          sortColumn={sortColumn}
          onDelete={this.handleDelete}
          onSort={this.handleSortByColumn} 
          onSelect={this.handleSelect}
          />
        );
      }
    }

    showView(viewType) {
      this.setState({viewType});
      localStorage.setItem("viewType", viewType);
    }

    renderImportForm(store, displayImportForm) {
      if(store.uploadsAllowed) {
        return(      
          <MyStoreProductsImport 
            storeUuid={store.storeUuid}
            parentEntity={store.storeName}
            doDisplayForm={displayImportForm}
            handleImportProducts={this.handleImportProducts}
            handleImportCancel={this.handleImportCancel}
          />
        );
      }
    }

    render() { 
      const {store, displayImportForm, working, init} = this.state;
      if(!init) {
        return (
          <React.Fragment>
            <RouteParams onGetParams={this.getRouteParams} />
            {/* <QueryParams onGetParams={this.getQueryParams} /> */}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          {this.renderImportForm(store,displayImportForm)}
          <div>
              { this.renderProducts() }
          </div>
          <PopWait visible={working} message="Hang in there! It might take several minutes to import all of the products and their images."/>
        </React.Fragment>
      );
    }

}
 
export default MyStoreProducts;