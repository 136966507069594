import React, { Component, Fragment } from 'react';
import Joi from 'joi-browser';
import InputForm from '../common/inputForm';
import { getUsStates, getCountries } from '../../services/lookupService';

class LocationEditForm extends InputForm {

    state = {
        data: {
            country: '',
            state: '', 
            city: '',
            neighborhood: ''
        },        
        errors: {},
        usStates: [],
        countries: [],
        init: false
    };
    schema = {
        country: Joi.string().allow('').label('Country'),
        state: Joi.string().allow('').label('State'),
        city: Joi.string().allow('').label('City'),
        neighborhood: Joi.string().allow('').label('Neighborhood')
    };

    async componentDidMount() {
        const emptyLocation = this.state.data;
        const loc = this.props.location ? this.props.location : emptyLocation; 
        const data = this.mapToViewModel(loc);

        let usStates = await getUsStates();
        // include a default -- Please select -- options
        usStates = this.addDefaultToSelectList(usStates);

        let countries = await getCountries();
        countries = this.addDefaultToSelectList(countries);

        this.setState({data,usStates,countries,init:true});
    }
  
    mapToViewModel(location) {
        let loc = {
            country: location.country ? location.country : '',
            state: location.state ? location.state : '', 
            city: location.city ? location.city : '',
            neighborhood: location.neighborhood ? location.neighborhood : ''
        };
        return loc;
    }

    addDefaultToSelectList(selectListValues) {
        let values = [];
        const v = {"_id":"","name":"-- Please select --"}
        values.push(v);
        values.push(...selectListValues)
        return values;
    }
    
    handleSave = async(evt, location) => {
        evt.preventDefault();
        // bubble the save to the parent
        await this.props.handleSave(evt,location);     
    } 
  
    render() {
        const {parentEntity, handleLocation, handleCancel, includeCountry, includeHood} = this.props;
        const {usStates, countries, init} = this.state;
        if(!init) {
            return('');
        }
        return (
            <React.Fragment>
                {/* <div className="row">
                    <div className="col">
                        <h1>Edit Location for {parentEntity}</h1>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col">
                        <form onSubmit={handleLocation}>
                            { includeCountry &&
                                this.renderInput(
                                "country", "Country", "select", 
                                "", "", "", countries
                                )
                            }
                            {
                                this.renderInput(
                                "state", "State", "select", 
                                "", "", "", usStates
                                )
                            }
                            {
                                this.renderInput(
                                "city", "City", "text", 
                                "", "", ""
                                )
                            }
                            { includeHood &&
                                this.renderInput(
                                "neighborhood", "Neighborhood", "text", 
                                "", "", ""
                                )
                            }
                            <button className="btn btn-dark btn-rightspace float-left" onClick={(evt) => this.handleSave(evt,this.state.data)}>Save</button>
                            <button className="btn btn-danger btn-rightspace float-left" onClick={handleCancel}>Cancel</button>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )   
    }
}

export default LocationEditForm;
