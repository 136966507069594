import React, { Component } from 'react';

class Careers extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Careers</h1>
                        <p>Think you're a good fit for Planet B? <br />
                            Email info@planetbmarket.com
                            with your CV and some words to help us understand why you're interested
                            and where you think you would fit in.
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Careers;