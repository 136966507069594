import React from 'react';
import {Link} from 'react-router-dom';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import RouteParams from '../common/routeParams';
import InputForm from '../common/inputForm';
import EntityImages from './entityImages';
import { getProduct, saveProduct } from '../../services/productService';
import { getMerchant } from '../../services/merchantService';
import MyShippingOptions from './myShippingOptions';
import PhysicalDimensions from './../common/physicalDimensions';
import LocationEditForm from './../common/locationEditForm';
import MyProductVariationEditForm from './myProductVariationEditForm';
import MyProductVariationCreateForm from './myProductVariationCreateForm';

class MyStoreProductEditForm extends InputForm {

    state = {
        productUuid: null,
        product: null,
        merchantUuid: null,
        merchant: null,
        storeUuid: null,
        storeName: null,
        shippingOptions: null,
        mfrLocation: {
            country:"",
            state:"",
            city:"",
            neighborhood:""
        },
        data: {
            productName: '', 
            quantityAvailable: 0, 
            published: true,
            price: 0.00,
            taxable: true,
            taxRate: 0.00,
            description: '',
            sku: '',
            barcode: '',
            upc: '',
        },
        currentTab: '',
        displayTabs: [],
        errors: {},
        doDisplayVariationForm: false
    };

    getRouteParams = async(routeParams) => {
        if(this.state.init) {
            // if we already loaded everything don't do it again
            return;
        }

        const productUuid = routeParams.id ? routeParams.id : ''; 
        let product = {};
        let mfrLocation = this.state.mfrLocation;
        let storeUuid = '';
        let storeName = '';
        let merchantUuid = '';
        let merchant = {};
        let shippingOptions = null;
        let displayTabs = [];
        let currentTab = '';
        try {
            if(null === productUuid || productUuid === 'new') return;
            // this makes no sense but works
            // set init to true to pretend we finished getting data
            // in theory this would trigger a fresh render
            // but in practice it prevents multiple subsequent renders of the RouteParam component
            // which prevents 3x each API call
            this.setState({init:true});

            // based on the incoming id param, get the product details
            product = await getProduct(productUuid);
            storeUuid = product.storeUuid;
            storeName = product.storeName;
            if(product.mfrLocation && product.mfrLocation.length) {
                mfrLocation = product.mfrLocation[0]
            }
            merchantUuid = product.merchantUuid;
            const merchantResponse = await getMerchant(merchantUuid);
            merchant = merchantResponse.data;
            if(product.shippingOptions) {
                shippingOptions = product.shippingOptions;
            }           

            currentTab = "product-" + product.uuid;
            displayTabs.push({
                type: "product",
                data: product,
                classes: "product active",
                label: "Details",
                key: currentTab
            });
            displayTabs.push({
                type: "product-images",
                data: product,
                classes: "product",
                label: "Images",
                key: currentTab + "-images"
            });

            if(product.variations && product.variations.length > 0) {
                for (var idx=0;idx<product.variations.length;idx++) {                            
                    displayTabs.push({
                        type: "product-variation",
                        data: product.variations[idx],
                        classes: "product",
                        label: product.variations[idx].variationName,
                        key: `${currentTab}-variation-${product.variations[idx].uuid}`
                    });
                }
            }

            displayTabs.push({
                type: "product-dimensions",
                data: product,
                classes: "product",
                label: "Dimensions",
                key: currentTab + "-dimensions"
            });
            displayTabs.push({
                type: "product-shipping",
                data: product,
                classes: "product",
                label: "Shipping",
                key: currentTab + "-shipping"
            });
            displayTabs.push({
                type: "product-mfrLocation",
                data: product,
                classes: "product",
                label: "Manufacture Location",
                key: currentTab + "-mfrLocation"
            });
        }
        catch(exception) {
            if(exception.response && exception.response.status === 404) {
                // redirect to the Notfound page
                // replace the history so that clicking the back button 
                // goes to the last page prior to the one with the bad product id
                window.location = '/notfound';
            }
            else if(exception.response.status === 401) {
                window.location = '/unauthorized';
            }
            else {
                console.log(exception);
                toast.error('An unhandled exception occurred!');
            }
        }
        this.setState({product, merchantUuid, storeUuid, storeName,
            data: this.mapToViewModel(product),
            productUuid, merchant, shippingOptions,
            displayTabs, currentTab, mfrLocation});
    }
  
    schema = {
        _id: Joi.string(),
        productName: Joi.string().required().label('Product Name'),
        description: Joi.string().allow("").label('Description'),
        upc: Joi.string().allow("").label('Universal Product Code (UPC)'),
        sku: Joi.string().allow("").label('Stock Keeping Unit (SKU)'),
        barcode: Joi.string().allow("").label('Barcode'),
        quantityAvailable: Joi.number().min(0).required().label('Quantity Available'),
        published: Joi.string().required().label('Published'),
        //Joi.number().min(0).max(100).required().label('Number in Stock'),
        // productRating: Joi.number().min(1).max(10).required().label('Rate'),
        price: Joi.number().min(0.0).required().label('Price'),
        taxable: Joi.string().required().label('Taxable'),
        taxRate: Joi.number().allow("").min(0.0).label('Tax Rate')
    };

    // moving to new async design pattern: 
    // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    // from DannyMoshe comment on https://stackoverflow.com/questions/47970276/is-using-async-componentdidmount-good

    mapToViewModel(product) {
        let p = {
            _id: product.uuid,
            description: product.description,
            productName: product.productName,
            quantityAvailable: product.quantityAvailable,
            published: product.published === true ? "yes" : "no",
            price: product.price,
            taxable: product.taxable === true ? "yes" : "no",
            taxRate: product.taxRate,
            upc: product.upc,
            sku: product.sku,
            barcode: product.barcode,
            // tags: product.tags,
            // dimensions: product.dimensions,
            // manufacturer: product.manufacturer,
            // materials: product.materials,            
        };
        return p;
    }

    doSubmit = async() => {
        try {
            const productData = this.normalizeProductData(this.state.product);
            await saveProduct(productData);
            toast.success("Your changes were saved!");
            setTimeout( () =>{
                window.location = '/mystore/edit/' + this.state.storeUuid + '/products';
            }, 3500);
        }
        catch(exception) {
            this.setState({working:false});
            toast.error("An unexpected error occurred!");
        }
    }

    normalizeProductData(product) {
        let productData = product; // default to the values in our product entity
        const data = this.state.data;

        // update values from the form values
        productData.published = data.published === "yes" ? true : false;
        productData.taxable = data.taxable === "yes" ? true : false;
        productData.storeUuid = this.state.storeUuid;
        productData.storeName = this.state.storeName;
        productData.merchantUuid = this.state.merchantUuid;

        productData.productName = data.productName;
        productData.description = data.description;
        productData.upc = data.upc;
        productData.sku = data.sku;
        productData.barcode = data.barcode;

        productData.taxRate = parseFloat(data.taxRate);
        productData.price = parseFloat(data.price);
        productData.quantityAvailable = parseInt(data.quantityAvailable);

        //@todo any other machinations
        return productData;
    }

    handleNewVariationClick = async(evt) => {
        evt.preventDefault();
        const currentTab = "product-" + this.state.product.uuid;
        this.setState({doDisplayVariationForm:true, currentTab});
    }
    handleCancelNewVariation = async(evt) => {
        evt.preventDefault();
        this.setState({doDisplayVariationForm:false});
    }
    handleSaveNewVariation = async(evt, variation) => {
        evt.preventDefault();
        let product = this.state.product;
        variation._id = "";
        variation.uuid = "";
        if(!product.variations) {
            product.variations = [];
        }
        product.variations.push(variation);
        const productData = this.normalizeProductData(product);
        await saveProduct(productData);
        // this.setState({product,doDisplayVariationForm:false});
        toast.success("The product variation was created");
        setTimeout( () =>{
            window.location = '/myproduct/edit/' + product.uuid;
        }, 1500);   
    }
    handleSaveVariation = async(evt, variation) => {
        evt.preventDefault();
        let product = this.state.product;
        const idx = this.getIndexFromUuid(product.variations,variation);
        if(null == idx) {
            return;
        }
        //@todo maybe better to replace certain fields instead of overwriting
        product.variations[idx] = variation;
        const productData = this.normalizeProductData(product);
        await saveProduct(productData);

        //window.location = '/myproduct/edit/' + product.uuid;
        //this.setState({product,doDisplayVariationForm:false});
        toast.success("The product variation was saved");
        setTimeout( () =>{
            window.location = '/myproduct/edit/' + product.uuid;
        }, 1500);
    }
    handleDeleteVariation = async(evt, variation) => {
        evt.preventDefault();
        let product = this.state.product;
        // console.log("product", product);
        // console.log("variation",variation);
        const idx = this.getIndexFromUuid(product.variations,variation);
        if(null == idx) {
            return;
        }
        product.variations.splice(idx, 1);
        const productData = this.normalizeProductData(product);
        await saveProduct(productData);
        toast.success("The product variation was deleted");
        setTimeout( () =>{
            window.location = '/myproduct/edit/' + product.uuid;
        }, 1500);
    }
    getIndexFromUuid(valueArray,value) {
        let fdx = null;
        for (var idx=0;idx<valueArray.length;idx++) { 
            const uniqueId = value.uuid ? value.uuid : value._id;
            if(valueArray[idx].uuid === uniqueId) {
                fdx = idx;
            }
        }
        return fdx;
    }

    handleTabClick = async (evt) => {
        const tabId = evt.target.id;
        const divId = tabId.replace("tab-","");
        this.setState({currentTab:divId})
    }
    handleSaveDimension = async(evt,dimensions) => {
        let product = this.state.product;
        product.dimensions = dimensions;
        const productData = this.normalizeProductData(product);
        await saveProduct(productData);
        this.setState({product});
    }
    handleCancelMfrLocation = async() => {
    }
    handleSaveMfrLocation = async(evt,location) => {
        let product = this.state.product;
        product.mfrLocation = []
        product.mfrLocation.push(location);
        const productData = this.normalizeProductData(product);
        try {
            await saveProduct(productData);
            this.setState({product,mfrLocation:location});
            toast.success("Your changes were saved!");
        }
        catch(exception) {
            this.setState({working:false});
            toast.error("An unexpected error occurred!");
        }
    }
    handleSaveShippingOptions = async(evt,shippingOptions) => {
        let product = this.state.product;
        product.shippingOptions = shippingOptions;
        await saveProduct(product);
        this.setState({product});
    }

    // functions for rendering
    getComponent(dt) {
        const {storeUuid,storeName,product,merchant,doDisplayVariationForm,shippingOptions} = this.state;
        const yesNoOptions = [
            {"_id":"yes","name":"yes"},
            {"_id":"no","name":"no"}
        ];
        //@todo this is crap- instead pass the store id & name to the shipping options table
        // update all other parents that use the s.o. table
        const store = {"uuid":storeUuid,"storeName":storeName};

        if(dt.type === "product-images") {
            //return (this.renderBannerImageForm());
            return(
                <EntityImages 
                parentType="product"
                category="product"
                parentUuid={product.uuid}
                storeUuid={storeUuid}
                parentEntity={product.productName}
                merchant={merchant}
                />
            );
        }
        else if (dt.type === "product-dimensions") {
            return (
                <PhysicalDimensions
                    dimensions={product.dimensions}
                    handleSave={this.handleSaveDimension}
                />
            );
        }
        else if (dt.type === "product-shipping") {
            return (
                <MyShippingOptions
                    shippingOptions={shippingOptions}
                    store={store}
                    product={product}
                    handleClone={this.handleCloneOptions}
                    handleSave={this.handleSaveShippingOptions}
                />
            );
        }
        else if (dt.type === "product-mfrLocation") {    
            return(<LocationEditForm 
                location={this.state.mfrLocation}
                handleSave={this.handleSaveMfrLocation}
                handleCancel={this.handleCancelMfrLocation}
                includeCountry={true}
                includeHood={false}
            />);
        }
        else if (dt.type === "product-variation") {
            return(
                <MyProductVariationEditForm 
                    variation={dt.data}
                    product={product}
                    store={store}
                    merchant={merchant}
                    handleSave={this.handleSaveVariation}
                    handleDelete={this.handleDeleteVariation}
                />
            );
        }
        else {
            return (                
                <React.Fragment>
                    <MyProductVariationCreateForm 
                        handleCancel={this.handleCancelNewVariation}
                        handleSave={this.handleSaveNewVariation}
                        doDisplayForm={doDisplayVariationForm}
                        product={product}
                    />
                    <div className="row">
                        <div className="col">
                            <form onSubmit={this.handleSubmit}>
                                { // name, label, inputType, helpId, helpText, classes, options, onChange
                                    this.renderInput(
                                    "productName", "Product Name", "text", 
                                    "", "", ""
                                    )
                                }
                                {
                                    this.renderInput(
                                    "description", "Description", "textarea",
                                    "", "", ""
                                    )
                                }
                                {
                                    this.renderInput(
                                    "published", "Is this product published?", "radios", 
                                    "publishedHelp", "If a product is published it is available for purchase in the market.", 
                                    "", yesNoOptions
                                    )
                                }
                                {
                                    this.renderInput(
                                    "upc", "Universal Product Code (UPC)", "text", 
                                    "", "", ""
                                    )
                                }
                                {
                                    this.renderInput(
                                    "sku", "Stock Keeping Unit (SKU)", "text", 
                                    "", "", ""
                                    )
                                }
                                {
                                    this.renderInput(
                                    "barcode", "Barcode", "text", 
                                    "", "", ""
                                    )
                                }
                                {
                                    this.renderInput(
                                    "quantityAvailable", "QuantityAvailable", "text", 
                                    "", "", ""
                                    )
                                }
                                {
                                    this.renderInput(
                                    "price", "Price", "text", 
                                    "", "", ""
                                    )
                                }
                                {
                                    this.renderInput(
                                    "taxable", "Is this product taxable?", "radios", 
                                    "taxableHelp", "If a product is taxable it will default to the store tax rate unless you change the tax rate.", 
                                    "", yesNoOptions
                                    )
                                }
                                {
                                    this.renderInput(
                                    "taxRate", "Tax Rate (%)", "text", 
                                    "taxRateHelp", "If the item is marked taxable and no tax rate is specified, the store's default tax rate will be used", ""
                                    )
                                }

                                { this.renderSubmitButton("Save") }
                            </form>
                        </div>
                    </div>

                </React.Fragment>
            );
        }
    }
    getClasses(keyName) {
        let classes = "nav-link";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }
    getTabClasses(keyName) {
        let classes = "tab-pane product-tab-pane";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }

    render() {
        const {displayTabs,product} = this.state;
        if(!product) {
            return (                
                <RouteParams onGetParams={this.getRouteParams} />
            );
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link className="breadcrumb-item" to={'/mybusiness/' + product.merchantUuid + '?tab=store-' + product.storeUuid}>
                                    Store
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link className="breadcrumb-item" to={'/mystore/edit/' + product.storeUuid + '/products'}>
                                    Products
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Product</li>
                        </ol>
                    </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h1>{product && product.productName + " - "}Edit Product</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <button className="btn btn-success m-2 float-right" onClick={this.handleNewVariationClick}>New Variation</button>
                    </div>
                </div>

                <div className="tab-ui store" role="navigation">
                    <nav className="nav nav-pills nav-justified">
                        { displayTabs.map(ds => 
                                <div data-toggle='tab' onClick={this.handleTabClick} id={"tab-" + ds.key} key={"tab-" + ds.key} className={this.getClasses(ds.key)} href={'#' + ds.key}>{ds.label}</div>
                            )
                        }
                    </nav>
                    <div className="tab-content">
                        { displayTabs.map(ds => 
                            <div id={ds.key} key={ds.key} className={this.getTabClasses(ds.key)}>
                                {this.getComponent(ds)}
                            </div>
                        )
                        }
                    </div>
                </div>
                <RouteParams onGetParams={this.getRouteParams} />
            </React.Fragment>
        )   
    }
}

export default MyStoreProductEditForm;