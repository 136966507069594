import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>About</h1>
                        <p>Planet B Market provides one-stop shopping for local and small USA-based businesses.
                            The market was inspired by all of the people who want to shop local and don't have the time;
                            by local businesses forced to close due to COVID and related supply chain issues;
                            and by the many, many small businesses who manage to survive and thrive in spite of all of the obstacles,
                            and in spite of having to compete against larger and overseas companies using unfair and unethical practices.</p>
                        <p>Planet B Market is based in Baltimore, Maryland.</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default About;