import React, { Component } from 'react';
import { getStore } from '../../services/searchService';
import CartItemView from "../common/cartItemView"
import {formatTimestamp} from "../../utils/format.js"

class OrderView extends Component {
    state = {  
        store: {}
    } 
   
    async componentDidMount() {
        const store = await getStore(this.props.order.storeUuid);
        this.setState({store});
    }

    render() { 
        const {order,isCustomer} = this.props;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h2 className="center-align">Order Details</h2>                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <p>Order date {formatTimestamp(order.created, 'timestamp')} GMT<br />
                        Last updated {formatTimestamp(order.updated, 'timestamp')} GMT<br />
                        Order Status: {order.orderState}<br />
                        Payment Status: {order.paymentStatus}</p>
                    </div>
                    <div className="col-6">
                        {this.renderShipping(order)}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="order-content">
                            <div className="totals">
                                { this.renderOrderTotals(order,isCustomer)}
                            </div>
                            {
                                order.orderItems.map(item => 
                                    this.renderOrderItem(item)
                                )                   
                            }
                        </div>                
                    </div>
                </div>
                {order.comments && order.comments.length > 0 &&
                    <div className="row">
                        <div className="col"><h2>Comments</h2>
                        {
                            order.comments.map(comment => 
                                this.renderComment(comment)
                            ) 
                        }
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

    renderShipping(order) {
        if(order.shipping && order.shipping.shipperName.toLowerCase() === "in-store pickup") {
            const store = this.state.store;
            return(
                <div className="row">
                    <div className="col shipping">
                        <div className="order-shipping">Pick Up Order From</div>
                        <div>{store.storeName}</div>
                        <div>{store.address1} {store.address2}</div>
                        <div>{store.city}, {store.state} {store.zipPlus4}</div>
                    </div>
                </div>
            );
        } else {
            if(order.shippingAddress && order.shippingAddress.country) {
                return (
                    <div className="row">
                        <div className="col shipping">
                            <div className="order-shipping">Shipping To</div>
                            <div>{order.shippingAddress.addressLabel}</div>
                            <div>{order.shippingAddress.address1} {order.shippingAddress.address2}</div>
                            <div>{order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.zipPlus4}</div>
                        </div>
                    </div>
                );
            }
        }

        return '';
    }
    renderComment(comment) {
        return(
            <div>
                {comment.commentText} {formatTimestamp(comment.created, "timestamp")} GMT
            </div>
        );
    }
    renderOrderTotals(cart,isCustomer) {
        const totalPrice = cart.productTotalPrice ? 
        cart.productTotalPrice + cart.productSalesTax + cart.shippingTotalPrice + cart.shippingSalesTax
        : 0.00;

        const allSalesTax = cart.productSalesTax + cart.shippingSalesTax;
        if(cart.orderItems.length) {
            return (
                <React.Fragment>
                    <div className="order-totals">Order Totals</div>
                    <div className="price-display"><div className="price-name">Products</div><div className="price-value">${cart.productTotalPrice.toFixed(2)}</div></div>
                    <div className="price-display last"><div className="price-name">Sales Tax</div><div className="price-value">${cart.productSalesTax.toFixed(2)}</div></div>
                    <div className="price-display"><div className="price-name">Shipping</div><div className="price-value">${cart.shippingTotalPrice.toFixed(2)}</div></div>
                    <div className="price-display last"><div className="price-name">Shipping Sales Tax</div><div className="price-value">${cart.shippingSalesTax.toFixed(2)}</div></div>
                    <div className="price-display"><div className="price-name">Order Total</div><div className="price-value">${totalPrice.toFixed(2)}</div></div>
                    {!isCustomer && <div className="price-display"><div className="price-name">Payment Processing Fee</div><div className="price-value">- ${cart.processingFee.toFixed(2)}</div></div>}
                    {!isCustomer && <div className="price-display"><div className="price-name">Sales Tax (retained and remitted by market)</div><div className="price-value">- ${allSalesTax.toFixed(2)}</div></div>}
                    {!isCustomer && <div className="price-display last"><div className="price-name">Payout to Your Store</div><div className="price-value">${cart.storeNetTotal.toFixed(2)}</div></div>}
                </React.Fragment>
            );
        }
        return '';
    }
    renderOrderItem(item) {
        return <CartItemView item={item} key={item.uuid} />
    }

}
 
export default OrderView;