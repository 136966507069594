import React, { Component } from 'react';
// import { toast } from 'react-toastify';
// import Pagination from '../common/pagination';
import MyShippingOptionsTable from './myShippingOptionsTable';
import ShippingOptionEdit from './shippingOptionEdit';

// Displays, and lets the user edit, shipping options for an entity
// e.g. for a store or for a specific product

class MyShippingOptions extends Component {

  state = {
      storeUuid: '',
      currentPage: 1,
      searchValue: null,
      shippingOptions: [],
      sortColumn: {path:'shipperName',order:'asc'},
      init:false,
      maxIndex: 0,
      selectedShippingOptions: [],
      currentShippingOption: {},
      doDisplayForm: false,
      shippers: []
    }
  
  handleFilterByPage = (page) => {    
      this.setState({currentPage: page});
  }

  handleSortByColumn = sortColumn => {     
    this.setState({sortColumn});
  }
  
  handleEditClick = (evt, shippingOption) => {
    evt.preventDefault();
    const doDisplayForm = true;
    this.setState({doDisplayForm,currentShippingOption:shippingOption});
  }

  handleNewClick = () => {
    const doDisplayForm = true;
    const currentShippingOption = {
      _id:'',
      shipperName: '',
      description: '',
      arrivalDaysEstimate: 0,
      costEstimate: 0.00
    };
    this.setState({doDisplayForm,currentShippingOption});
  }

  handleSelectClick = ({currentTarget}) => {
    // get option id from input id 
    // id format for input is option-select-[id]_[id]
    const idParts = currentTarget.id.split("_");
    if(idParts.length < 2) {
      return;
    }

    const optionId = idParts[1];
    let selectedShippingOptions = this.state.selectedShippingOptions;
    let valuePos = -1;
    for (var i=0;i<selectedShippingOptions.length;i++) {
      if(selectedShippingOptions[i] === optionId) {
          valuePos = i;
      }
    }   

    if(currentTarget.checked) {
      if(valuePos === -1) {
        selectedShippingOptions.push(optionId)
      }
    } else {
      if(valuePos > -1) {
        selectedShippingOptions.splice(valuePos, 1);
      }
    }
    this.setState({selectedShippingOptions});
  }

  handleSaveOption = async(evt, optionData) => {
    evt.preventDefault();
    let shippingOptions = this.state.shippingOptions;
    let maxIndex = this.state.maxIndex;
    if(null === shippingOptions) {
      shippingOptions = [];
    }

    let valuePos = -1;
    if(!optionData._id || (`${optionData._id}`).length === 0) {
      maxIndex = maxIndex + 1;
      optionData._id = `${maxIndex}`;
    }
    else {
      for (var i=0;i<shippingOptions.length;i++) {
          if(shippingOptions[i]._id === optionData._id) {
              valuePos = i;
          }
      }   
    }
    optionData.costEstimate = parseFloat(optionData.costEstimate);
    optionData.arrivalDaysEstimate = `${optionData.arrivalDaysEstimate}`
    if(valuePos === -1) {
      optionData.uuid = optionData._id;
      shippingOptions.push(optionData);
    }
    else {
      shippingOptions[valuePos] = optionData;
    }
    this.props.handleSave(evt,shippingOptions);
    this.setState({shippingOptions,currentShippingOption:{},doDisplayForm:false,maxIndex});
  }    

  handleDeleteOptions = async (evt) => {
    // when we're deleting, 
    // any option that doesn't have a valid uuid wasn't saved anyway
    evt.preventDefault();
    let shippingOptions = this.state.shippingOptions;
    let options = this.state.selectedShippingOptions;
    for (var i=0;i<options.length;i++) {            
        shippingOptions = this.deleteOptionById(options[i], shippingOptions);
    }       
    this.props.handleSave(shippingOptions);
    this.setState({shippingOptions, selectedShippingOptions:[]});
  }

  deleteOptionById(optionId, options) {      
    let valuePos = -1;  
    for (var idx=0;idx<options.length;idx++) {
        if(options[idx]._id === optionId) {
            valuePos = idx;
        }
    } 
    if(valuePos > -1) {
        options.splice(valuePos, 1);
    }
    return options;
  }

  async componentDidMount() {
      const storeUuid = this.props.store ? this.props.store.uuid : '';

      let shippingOptions = this.props.shippingOptions;
      const totalCount = shippingOptions ? shippingOptions.length : 0;

      // shipping options may have uuid but not _id
      if(shippingOptions !== null) {
        for (var i=0;i<shippingOptions.length;i++) {
          if(!shippingOptions[i]._id) {
            if(shippingOptions[i].uuid) {
              shippingOptions[i]._id = shippingOptions[i].uuid;
            } else {
              shippingOptions[i]._id = parseInt(i);
            }
          }
        } 
      }
      const maxIndex = totalCount;

      //@todo dynamically from API
      const shippers = ['Fedex','UPS','USPS','In-store Pickup','Local Courier'];
      this.setState({storeUuid,totalCount,shippingOptions,init:true,shippers,maxIndex});
  }

  constructor(props) {
      super(props);
      this.handleCancel = this.handleCancel.bind(this);
      this.handleSaveOption = this.handleSaveOption.bind(this);
      this.handleDeleteOptions = this.handleDeleteOptions.bind(this);
      // this.handleShopTypesChange = this.handleShopTypesChange.bind(this);
      // this.handleGroupsChange = this.handleGroupsChange.bind(this);
      // this.handleTagsChange = this.handleTagsChange.bind(this);
      // this.getParams = this.getParams.bind(this);
      // this.handleCloneOptions = this.handleCloneOptions(this);
  }
    
  renderOptions() {
    const {sortColumn, shippingOptions, selectedShippingOptions,
      totalCount, doDisplayForm, currentShippingOption, shippers } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="content">
              <button className="btn btn-success m-2 float-right" onClick={this.handleNewClick}>New Shipping Option</button>
              {shippingOptions && shippingOptions.length > 0 && <button className="btn btn-danger m-2 float-right" onClick={this.handleDeleteOptions}>Delete Shipping Options</button>}
              {this.getOptionsTable(shippingOptions, selectedShippingOptions, sortColumn)}
            </div>
          </div>
        </div>
        { this.renderEditForm(doDisplayForm,currentShippingOption,shippers) }
      </React.Fragment>
    );
  };

  handleCancel(evt) {
    evt.preventDefault();
    this.setState({doDisplayForm:false,currentShippingOption:{}});
  }

  renderEditForm(doDisplayForm,shippingOption,shippers) {
      if(!doDisplayForm) {
          return '';
      }
      return(
          <div className="floating-div">
              <ShippingOptionEdit 
                  shippingOption={shippingOption} 
                  handleSave={this.handleSaveOption}
                  shippers={shippers}
                  handleCancel={this.handleCancel}
              />
          </div>
      );
  }

  getViewClasses(viewType, selectedViewType) {
    let classes = "view-link " + viewType;
    if(selectedViewType === viewType) {
      classes += " active";
    }
    return classes;
  }

  getOptionsTable(shippingOptions, selectedShippingOptions, sortColumn) {
      if(!shippingOptions || shippingOptions.length === 0) {
        let parentName = null;
        if(this.props.variation) {
          parentName = "product";
        } else if (this.props.product) {
          parentName = "store";
        }
        const parentMessage = parentName !== null 
        ? "Shipping options will be inherited from the " + parentName + "."
        : "";
        return (
          <div key="div-warning" className="alert alert-warning" role="alert">
            <div>No shipping options are defined. {parentMessage}</div>
          </div>
        );
      }
      return(<MyShippingOptionsTable 
        shippingOptions={shippingOptions}
        sortColumn={sortColumn}
        onDelete={this.handleDelete}
        onSort={this.handleSortByColumn} 
        onSelect={this.handleSelectClick}
        onClickEdit={this.handleEditClick}
        selectedOptions={selectedShippingOptions}
        />
      );
  }

  render() { 
      return (
          <div>
              { this.renderOptions() }
          </div>
      );
  }

}
 
export default MyShippingOptions;