import React, {useState, useEffect} from 'react';

const ImageUpload = ({label, name, error, helpId, helpText, classes, fileTypes, onUpload, ...rest}) => {
    const helpDiv = !helpId.length ? '' : <small id={helpId} className="form-text text-muted">{helpText}</small>;
    const describedBy = !helpId.length ? '' : helpId;
    if(fileTypes.length === 0) {
        fileTypes = "image/*"
    }

    //const [crop, setCrop] = useState<Crop>()
    const [image, setImage] = useState([]);
    // const [imageData, setImageData] = useState([]);
    const [imageUrl, setImageUrl] = useState([]);

    useEffect(() => {
        if (Array.isArray(image)) return // && image.length === 0) return;
        setImageUrl(URL.createObjectURL(image));
    }, [image]);

    function onImageChange(evt) {
        if(evt.target.files[0]) {
            setImage(evt.target.files[0]);
            //onUpload(evt); //e.target.files[0])
        }
    }

    classes = "form-group image-upload" + (classes ? classes : "");
    return( 
        <div className={classes}>
            <label htmlFor={name}>{label}</label>
            <div><input id={name} name={name} type="file" accept={fileTypes} title="click to upload file" aria-describedby={describedBy} onChange={onImageChange}/></div>
            {helpDiv}
            { error && <div className="alert alert-danger" role="alert">
                {error}
            </div>
            }
        </div>
    )
}
 
export default ImageUpload;