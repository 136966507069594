import React, {Component} from 'react';

class PopWait extends Component {

    render() {
        if(this.props.visible) {
            return (
                <React.Fragment>
                    <div className="hover-div">
                        {this.props.message}
                    </div>
                </React.Fragment>
            );
        }
        else {
            return ('');
        }
    }
}

export default PopWait;