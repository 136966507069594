import React,{ useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getCustomerOrder } from '../../services/cartService';
import OrderView from '../common/orderView';

const MyOrder = ({label, name, error, helpId, helpText, ...rest}) => {
    const {id} = useParams();
    const [orderData, setOrderData] = useState();

    useEffect(async () => {
        const getOrderData = async () => {
            const order = await getCustomerOrder(id);
            return order;
        }

        if(!orderData) {
            const o = await getOrderData();
            setOrderData(o);
        } 

    },[]);

    if(orderData) {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12"><h1>Order Details</h1></div>
                </div>
                <div className="row">
                    <div className="col">
                        <OrderView order={orderData} isCustomer={true} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
    else {
        return ('');
    }
}
 
export default MyOrder;