import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryParams from './queryParams';
import { NavLink, Link } from "react-router-dom";
import SearchForm from './searchForm';
import CartLink from './cartLink';
import { getCartTotalProducts } from '../../services/cartService';

// this could also be a stateless functional component
class TopNav extends Component {

    state = {
        cartCount: 0,
        init: false,
        l: false,
    }

    getParams = async (queryParams) => {
        const l = queryParams.l == undefined ? false : true;
        this.setState({ l, init: true });
    }

    async componentDidMount() {
        const timer = setInterval(() => {
            this.setState({ cartCount: getCartTotalProducts() })
        }, 300);

    }

    render() {
        const { items, linkLabelProperty, linkFaProperty, routeProperty, selectedItem, onRouteSelect } = this.props;
        const { l, cartCount } = this.state;
        if (l) {
            return (
                <React.Fragment>
                    <div className="row topnav-landing1">
                        <div className="col-4 topnav-branding">
                            <Link tabIndex="0" className="navbar-brand" to="/">
                                <img className="topnav-logo" src="/planetb_green.png" alt="Planet B logo" />
                                <div><div className="topnav-text">Planet B Market</div><br />
                                    <p>One Stop Boutique Shopping</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <QueryParams onGetParams={this.getParams} />
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className="row topnav">
                    <div className="col-4 topnav-branding">
                        <Link tabIndex="0" className="navbar-brand" to="/">
                            <img className="topnav-logo" src="/planetb.png" alt="Planet B logo" />
                            <div className="topnav-text">Planet B Market</div>
                        </Link>
                    </div>
                    <div className="col-4">
                        <SearchForm />
                    </div>
                    <div className="col-4">
                        <nav className="navbar navbar-expand-md navbar-light bg-none">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto">
                                    {this.getCartLink(cartCount)}
                                    {items.map(item =>
                                        this.getNavLink(item, selectedItem, linkFaProperty, linkLabelProperty, routeProperty, onRouteSelect)
                                    )
                                    }
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <QueryParams onGetParams={this.getParams} />
            </React.Fragment>
        );
    };

    getCartLink(cartCount) {
        return (
            <li key="cart-link" className="nav-item nav-link cart-link">
                <CartLink cartCount={cartCount} />
            </li>
        );
    }

    getNavLink(item, selectedItem, linkFaProperty, linkLabelProperty, routeProperty, onRouteSelect) {
        if (item[linkFaProperty]) {
            return (
                <li key={item[routeProperty]} className={item[routeProperty] === selectedItem ? "nav-item nav-link active" : "nav-item nav-link"}>
                    <NavLink className="nav-link"
                        key={item[routeProperty] ? item[routeProperty] : "0"}
                        to={item[routeProperty]}
                        tabIndex="0"
                        title={item[linkLabelProperty]}
                        onClick={() => onRouteSelect(item)}>
                        <i className={item[linkFaProperty]} aria-label={item[linkLabelProperty]} aria-hidden="true"></i><br />{item[linkLabelProperty]}
                    </NavLink>
                </li>
            );
        }
        else {
            return (
                <li key={item[routeProperty]} className={item[routeProperty] === selectedItem ? "nav-item nav-link active" : "nav-item nav-link"}>
                    <NavLink className="nav-link"
                        key={item[routeProperty] ? item[routeProperty] : "0"}
                        to={item[routeProperty]}
                        tabIndex="0"
                        title={item[linkLabelProperty]}
                        onClick={() => onRouteSelect(item)}>
                        {item[linkLabelProperty]}
                    </NavLink>
                </li>
            );
        }
    }
}

TopNav.defaultProps = {
    linkLabelProperty: "label",
    linkFaProperty: "fa",
    routeProperty: "route"
};

TopNav.propTypes = {
    items: PropTypes.array.isRequired,
    linkLabelProperty: PropTypes.string,
    linkFaProperty: PropTypes.string,
    routeProperty: PropTypes.string,
    onRouteSelect: PropTypes.func
};

export default TopNav;
