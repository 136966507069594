import React, { Component } from 'react';

class Unauthorized extends Component {
    async componentDidMount() {
        if(!this.props.user) {
            window.location = '/';
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1 className="centered">Oops!</h1>
                        <p className="tall centered">
                            It looks like you don't have access to that page.<br />
                            Check the navbar in the header to see if you are logged in.<br />
                            Please send us an email if you are logged in and believe you should have access to the page you requested.
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Unauthorized;