//import logo from './logo.svg';
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import * as userService from './services/userService';
// import {getCartTotalProducts} from './services/cartService';

import TopNav from "./components/common/topNav";
import Footer from "./components/common/footer";

import About from './components/pages/about';
import Careers from './components/pages/careers';
import FAQ from './components/pages/faq';
import Privacy from './components/pages/privacy';
import Terms from './components/pages/terms';
import Home from './components/pages/home';

import Welcome from './components/pages/welcome';

import NotFound from "./components/notfound";
import LoginForm from "./components/loginForm";
import ForgotPasswordForm from "./components/customer/forgotPasswordForm";
import Logout from './components/logout';

import VerifyForm from './components/customer/verifyForm';
// import RegisterForm from "./components/customer/registerForm";
import MyAccount from './components/customer/myAccount';
import MyOrders from './components/customer/myOrders';
import MyOrder from './components/customer/myOrder';

// import Products from './components/products';
// import ProductView from './components/productView';

// import CartProducts from './components/checkout/cartProducts';
// import OrderCheckout from './components/checkout/orderCheckout';
// import OrderShipping from './components/checkout/orderShipping';
// import OrderPayment from './components/checkout/orderPayment';
// import OrderFinalize from './components/checkout/orderFinalize';

// import SearchPage from './components/searchPage';
import MissedSearches from './components/missedSearches';

// import TestView from './components/test';

// import Stores from './components/stores';
// import StoreView from './components/storeView';

import MyBusiness from './components/merchant/myBusiness';
import MyBusinessSignupForm from './components/merchant/myBusinessSignupForm';

import MyStoreProducts from './components/merchant/myStoreProducts';
import MyStoreProductCreateForm from './components/merchant/myStoreProductCreateForm';
import MyStoreProductEditForm from './components/merchant/myStoreProductEditForm';
//import MyStoreOrders from './components/merchant/myStoreOrders';
import MyStoreOrder from './components/merchant/myStoreOrder';
import MyStoreOrderShipSlip from './components/merchant/myStoreOrderShipSlip';

import Dashboard from './components/admin/dashboard';
import BusinessEditForm from './components/admin/businessEditForm';

import Unauthorized from './components/pages/unauthorized';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

class App extends Component {

  state = {
    currentPage: "products",
    cartCount: 0,
    // queryParams: {},
    // selectedFacets: [],
    // searchValue: '',
    init: false,
    user: {}
  };

  // this only gets called once during the lifecycle of the application
  // after successful login, reload the app to refresh the app
  // and cause this to run again
  async componentDidMount() {
    if (!this.state.init || !this.state.user || !this.state.user.uuid) {
      const userData = await userService.getUser();
      //const cartCount = await getCartTotalProducts();
      const cartCount = 0;
      const user = userData && userData.data ? userData.data : null;
      this.setState({ user, cartCount, init: true });
    }
  }

  handleRouteSelect = (link) => {
    this.setState({ currentPage: link.route });
  }

  // getParams = (queryParams) => {
  //   const searchValue = queryParams.q ? queryParams.q : '';
  //   const selectedFacets = queryParams.fq ? unpackFacets(queryParams.fq) : [];
  //   this.setState({queryParams, searchValue, selectedFacets});
  // }

  render() {
    const { user, cartCount, currentPage } = this.state;
    // searchValue,selectedFacets,

    const topNavItems = [
      //{ 'label': 'search', 'route': '/search', 'fa': 'fa fa-search' },
      //{'label': <CartLink cartCount={cartCount} />, 'route': '/cart'},
    ];

    if (user !== null) {
      // topNavItems.push({'label': user.name, 'route': '/profile'});
      // if (user.merchantUuid && user.merchantUuid.trim().length > 0) {
      //   topNavItems.push({ 'fa': 'fa fa-building', 'label': 'business', 'route': '/mybusiness/' + user.merchantUuid });
      // }
      //else 
      if (user.roleName && user.roleName === "admin") {
        topNavItems.push({ 'fa': 'fa fa-building', 'label': 'dashboard', 'route': '/dashboard' });
      }
      else {
        // topNavItems.push({'label': 'Browse', 'route': '/products'});
        // topNavItems.push({'label': 'Stores', 'route': '/stores'});  
      }
      topNavItems.push({ 'fa': 'fa fa-user', 'label': 'account', 'route': '/account' });
      topNavItems.push({ 'fa': 'fa fa-sign-out', 'label': 'logout', 'route': '/logout' });
    }
    else {
      // topNavItems.push({'label': 'Browse', 'route': '/products'});
      // topNavItems.push({'label': 'Stores', 'route': '/stores'});
      //topNavItems.push({ 'fa': 'fa fa-user', 'label': 'login', 'route': '/login' });
      //topNavItems.push({'fa': 'fa fa-user', 'label': 'register', 'route': '/register'});
    }

    return (
      <React.Fragment>
        <main role="main" className="container">
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Damion&family=IBM+Plex+Serif&family=Slabo+27px&family=Dancing+Script&family=IBM+Plex+Serif&family=IM+Fell+DW+Pica:ital@0;1&family=Seaweed+Script&display=swap');
          </style>
          <ToastContainer />
          <TopNav
            items={topNavItems}
            cartCount={cartCount}
            selectedItem={currentPage}
            onRouteSelect={this.handleRouteSelect}
            user={user}
          >
          </TopNav>
          <Routes>
            {/* <Route path="/test" element={<TestView />} /> */}

            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/careers" element={<Careers />} />

            <Route path="/welcome" element={<Welcome />} />

            <Route path="/signup" element={<MyBusinessSignupForm user={user} />} />
            <Route path="/verify" element={<VerifyForm user={user} />} />
            {/* <Route path="/register" element={<RegisterForm user={user} />} /> */}
            <Route path="/login" element={<LoginForm user={user} />} />
            <Route path="/forgot" element={<ForgotPasswordForm user={user} />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/business/:id" element={<BusinessEditForm />} />

            {/* <Route path="/stores" element={<Stores />} /> */}

            {/* <Route
              path="/product/:id"
              element={<ProductView />}
            />
            <Route
              path="/product/:id/:vid"
              element={<ProductView />}
            />
            <Route
              path="/products"
              element={<Products />}
            /> */}

            <Route
              path="/missedSearches"
              element={<MissedSearches />}
            />

            {/* <Route
              path="/cart"
              element={<CartProducts
              />}
            />

            <Route
              path="/checkout"
              element={<OrderCheckout
              />}
            />
            <Route
              path="/orderShipping"
              element={<OrderShipping
              />}
            />
            <Route
              path="/orderPayment"
              element={<OrderPayment
              />}
            />
            <Route
              path="/orderFinalize"
              element={<OrderFinalize
              />}
            /> */}

            <Route
              path="/account"
              element={<MyAccount user={user}
              />}
            />

            <Route
              path="/account/orders"
              element={<MyOrders
              />}
            />

            <Route
              path="/account/order/:id"
              element={<MyOrder
              />}
            />

            {/* <Route
              path="/store/:id"
              element={<StoreView />}
            />
 */}
            <Route
              path="/mybusiness/:id"
              element={
                <MyBusiness user={user} />
              }
            />

            <Route
              path="/mystore/edit/:id/products"
              element={
                <MyStoreProducts />
              }
            />

            <Route
              path="/mystore/:id/products/new"
              element={
                <MyStoreProductCreateForm user={user} />
              }
            />
            <Route
              path="/myproduct/edit/:id"
              element={
                <MyStoreProductEditForm user={user} />
              }
            />

            <Route
              path="/mystore/order/:id"
              element={<MyStoreOrder />}
            />

            <Route
              path="/mystore/order/:id/shipslip"
              element={<MyStoreOrderShipSlip />}
            />

            <Route
              path="/dashboard"
              element={<Dashboard />}
            />

            <Route path="/" element={<Home />} />
            {/* <Route path="/" element={<Products
              selectedFacets={selectedFacets}
              searchValue={searchValue} /> } /> */}

            {/* <Route path="/search" element={<SearchPage />} /> */}

            {/* <Route path="/" element={<Home />} /> */}

            <Route path="/unauthorized" element={<Unauthorized user={user} />} />

            { /* have to define any route we want to use, anything else gets sent to not found */}
            <Route path="/*" element={<NotFound />} />

          </Routes>
        </main>
        <Footer />
      </React.Fragment>
    );
  };
}


export default App;
