import React from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import {Link} from 'react-router-dom';

//import RouteParams from '../common/routeParams';
import InputForm from '../common/inputForm';

//import ImageUpload from '../common/imageUpload';
//import { saveImage } from '../../services/imageService';

import EntityImages from './entityImages';
import { saveMerchantStore } from '../../services/merchantService';
import { getUsStates, getGroups, getShopTypes} from '../../services/lookupService';
import MyShippingOptions from './myShippingOptions';

class MyStoreEditForm extends InputForm {

    state = {
        allStores: [],
        storeId: null,
        store: null, // original data loaded for this store
        data: {
            storeName: '',
            storeMfaEmail: '',
            storeMfaPhoneNumber: '',
            description: '',
            taxRate: 0.00,
            groups: [],
            shopTypes: [],
            //tags: [],
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipPlus4: '',
            neighborhood: '',
            active: "no"
        },
        shippingOptions: [],
        allGroups: [],
        allShopTypes: [],
        allTags: [],
        allStates: [],
        currentTab: '',
        displayTabs: [],
        init: false,
        message: '',
        errors: {}
    };

    constructor(props) {
        super(props);
        //this.handleSaveOption = this.handleSaveOption.bind(this);
        this.handleShopTypesChange = this.handleShopTypesChange.bind(this);
        this.handleGroupsChange = this.handleGroupsChange.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.handleSaveShippingOptions = this.handleSaveShippingOptions.bind(this);
    //     this.handleTagsChange = this.handleTagsChange.bind(this);
    //     this.handleDeleteOptions = this.handleDeleteOptions(this);
    //     this.handleCloneOptions = this.handleCloneOptions(this);
    }

    async componentDidMount() {
        if(this.state.init) {
            return;
        }
        try {
            const store = this.props.store
            let message = "";
            let allStates = await getUsStates();
            allStates = this.addDefaultToSelectList(allStates);

            let allGroups = await getGroups();
            allGroups = this.getSelectValuesFromStringArray(allGroups);
            let allShopTypes = await getShopTypes();
            allShopTypes = this.getSelectValuesFromStringArray(allShopTypes);

            if(!store.storeMfaEmail && !store.storeMfaPhoneNumber) {
                message = "Your store does not have an email address or phone number we can use for notifications. You will not receive any order notifications.";
            }

            const currentTab = "store-" + store.uuid;
            let displayTabs = [];
            displayTabs.push({
                type: "store",
                data: store,
                classes: "store active",
                label: "Details",
                key: currentTab
            });
            displayTabs.push({
                type: "store-images",
                data: store,
                classes: "store",
                label: "Images",
                key: currentTab + "-images"
            });
            displayTabs.push({
                type: "store-shipping",
                data: store,
                classes: "store",
                label: "Shipping",
                key: currentTab + "-shipping"
            });

            this.setState({
                storeId: store.uuid, 
                message,
                data: this.mapToViewModel(store), 
                store: store,
                shippingOptions: store.shippingOptions ? store.shippingOptions: [],
                allStates, allGroups, allShopTypes,
                init: true,
                displayTabs,
                currentTab
            });

        }
        catch(exception) {
            const err = this.getNiceErrorMessage(exception);
            toast.error(err);
           //if(exception.response && exception.response.status >= 400) {
                const errors = {...this.state.errors};
                errors.comments = err;
                this.setState({errors, finalState:'error'});
            //}
        }
    }
  
    // schema doesn't have to be part of the state
    // because the schema doesn't change
    schema = {
        _id: Joi.string(),
        storeName: Joi.string().required().min(2).max(500).label('Store Name'),

        storeMfaEmail: Joi.string().min(5).required().email().label('Email to use for order notifications'),
        storeMfaPhoneNumber: Joi.string().regex(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)
        .allow('').label('Phone number to use for order notifications'),

        description: Joi.string().max(2000).label('Description'),
        taxRate: Joi.number().min(0.0).required().label('Tax Rate'),

        shopTypes: Joi.array().items(Joi.string()).label('Shop Type'),
        groups: Joi.array().items(Joi.string()).label('Groups'),

        address1: Joi.string().required().min(7).max(200).label('Street Address'),
        address2: Joi.string().allow('').max(200).label('Unit, Floor or Other Details'),
        city: Joi.string().required().min(2).max(300).label('City'),
        state: Joi.string().required().min(2).label('State'),
        zipPlus4: Joi.string().required().min(5).max(10).label('Zip Code'),
        neighborhood: Joi.string().allow('').max(150).label('Neighborhood'),

        active: Joi.string().required().label('Is this store active?'),
    };

    addDefaultToSelectList(selectListValues) {
        let values = [];
        const v = {"_id":"","name":"-- Please select --"}
        values.push(v);
        values.push(...selectListValues)
        return values;
    }

    getSelectValuesFromStringArray(stringArray, withDefault = false) {
        let values = [];
        let v = {};
        if(withDefault) {
            v = {"_id":"","name":"-- Please select --"}
            values.push(v);
        }
        for (var i=0;i<stringArray.length;i++) {
            v = {"_id":stringArray[i],"name":stringArray[i]};
            values.push(v);
        }
        return values;
    }

    mapToViewModel(store) {
        let s = {
            _id:store.uuid,
            storeName:store.storeName,
            description:store.description,
            storeMfaEmail:store.storeMfaEmail,
            storeMfaPhoneNumber:store.storeMfaPhoneNumber,
            taxRate:parseFloat(store.taxRate),
            active:store.active === true ? "yes" : "no",
            address1: store.address1,
            address2: store.address2,
            city: store.city,
            state: store.state,
            zipPlus4: store.zipPlus4,
            neighborhood: store.neighborhood,
            shopTypes: store.shopTypes ? store.shopTypes : [],
            groups: store.groups ? store.groups : []
        };
        //tags
        return s;
    }

    normalizeData(storeData,data) {
        storeData.storeName = data.storeName;
        storeData.storeMfaEmail = data.storeMfaEmail;
        storeData.storeMfaPhoneNumber = data.storeMfaPhoneNumber;
        storeData.description = data.description;
        storeData.taxRate = parseFloat(data.taxRate);
        storeData.address1 = data.address1;
        storeData.address2 = data.address2;
        storeData.city = data.city;
        storeData.state = data.state;
        storeData.zipPlus4 = data.zipPlus4;
        storeData.neighborhood = data.neighborhood;
        storeData.groups = data.groups ? data.groups : [];
        storeData.shopTypes = data.shopTypes ? data.shopTypes : [];
        storeData.active = data.active === "yes" ? true : false;
        return storeData;
    }

    getNiceErrorMessage(exception) {
        let err = "An unexpected error occurred!";
        if(exception.response) {
            if(exception.response.data && exception.response.data.message) {
                err = exception.response.data.message;
            }
        }
        else if (exception.message) {
            err = exception.message;
        }
        return err;
    }

    updateValuesFromCheckbox(input, currentValues) {
        const selectedValue = input.value;
        const valuePos = currentValues.indexOf(selectedValue);
        if(input.checked) {
            // user selected this value; 
            // if it isn't in the array of current values, add it
            if(valuePos === -1) {
                currentValues.push(selectedValue);
            }
        } 
        else {
            // user de-selected this value
            // if it is in the array of current values, remove it
            if(valuePos >= 0) {
                currentValues.splice(valuePos, 1);
            }
        }
        return currentValues;
    }

    // functions for handling user actions
    doSubmit = async(evt) => {
        try {
            const storeData = this.normalizeData(this.state.store,this.state.data);
            // console.log(storeData);
            const response = await saveMerchantStore(this.state.storeId, storeData);
            if(response.data) {
                toast.success("Your changes were saved!");
                setTimeout( () =>{
                    window.location = '/mybusiness/'+this.state.store.merchantUuid+'?tab=store-'+this.state.storeId;
                }, 3500);
            }
            else {
                this.setState({working:false});
                toast.error("Dang! Something prevented your changes from being saved. This issue has been logged and our team will take a look.");
            }
        }
        catch(exception) {
            this.setState({working:false});
            const err = this.getNiceErrorMessage(exception);
            toast.error(err);
           //if(exception.response && exception.response.status >= 400) {
                const errors = {...this.state.errors};
                errors.comments = err;
                this.setState({errors, finalState:'error'});
            //}
        }
    }   

    handleShopTypesChange(evt) {
        const {currentTarget: input} = evt;
        const data = {...this.state.data};
        const errors = {...this.state.errors};
        const selectedValue = input.value;
        if(selectedValue) {            
            const valuePos = data.shopTypes.indexOf(selectedValue);
            if(valuePos >= 0) {
                data.shopTypes.splice(valuePos, 1);
            }
            else {
                data.shopTypes.push(selectedValue);
            }
            this.setState({data, errors});
        }
    }

    handleGroupsChange = async(evt) => {
        const {currentTarget: input} = evt;
        const data = {...this.state.data};
        const errors = {...this.state.errors};
        const selectedValue = input.value;
        if(selectedValue) {            
            const valuePos = data.groups.indexOf(selectedValue);
            if(valuePos >= 0) {
                data.groups.splice(valuePos, 1);
            }
            else {
                data.groups.push(selectedValue);
            }
            this.setState({data, errors});
        }
    }

    handleActiveChange = async(evt) => {
        const {currentTarget: input} = evt;
        const data = {...this.state.data};
        const errors = {...this.state.errors};
        const selectedValue = input.value;
        if(selectedValue) {       
            data.active = selectedValue;
            this.setState({data, errors});
        }
    }

    handleCloneShippingOptions = async (evt) => {
        console.log("Clone option/s was clicked", evt);
    }

    handleSaveShippingOptions = async(evt,shippingOptions) => {
        let store = this.state.store;
        store.shippingOptions = shippingOptions;
        this.normalizeData(store,this.state.data);
        await saveMerchantStore(this.state.storeId, store);
        this.setState({store});
    }
    handleTabClick = async (evt) => {
        const tabId = evt.target.id;
        const divId = tabId.replace("tab-","");
        this.setState({currentTab:divId})
    }
    // functions for rendering
    getComponent(dt) {
        const {shippingOptions, allShopTypes, allGroups, allStates, store} = this.state;
        const activeOptions = [
            {"_id": "yes", "name": "yes"},
            {"_id": "no", "name": "no"},
        ];

        if(dt.type === "store-images") {
            //return (this.renderBannerImageForm());
            return(
                <EntityImages parentType="store"
                category="banner"
                parentUuid={store.uuid}
                storeUuid={store.uuid}
                parentEntity={store.storeName}
                merchant={this.props.merchant}
                />
            );
        }
        else if (dt.type === "store-shipping") {
            return (
                <MyShippingOptions
                    shippingOptions={shippingOptions}
                    store={store}
                    handleClone={this.handleCloneShippingOptions}
                    handleSave={this.handleSaveShippingOptions}
                />
            );
        }
        else {
            return (
                <React.Fragment>
                <div className="row">
                    <div className="col">
                        <Link to={"/mystore/edit/" + store.uuid + "/products"}>
                            <button alt={"edit products for "+store.storeName} className="btn btn-success m-2 float-right">Edit Products</button>
                        </Link> 
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                        {
                            this.renderInput(
                            "storeName", "Store Name", "text", 
                            "", ""
                            )
                        }
                        {
                            this.renderInput(
                            "storeMfaEmail", "Notifications email", "text", 
                            "storeMfaEmailHelp", "(Optional) If you enter an email address here, the system will use it to send you order notifications."
                            )
                        }
                        {
                            this.renderInput(
                            "storeMfaPhoneNumber", "Notifications phone Number", "text", 
                            "storeMfaPhoneNumberHelp", "(Optional) If you enter a phone number here, the system will use it to notify you of new orders. Must be in the format 123-456-7890"
                            )
                        }
                        {
                            this.renderInput(
                            "shopTypes", "Select all types for this store", "checkboxes", 
                            "shopTypesHelp", "", "", allShopTypes, 
                            this.handleShopTypesChange                         
                            )
                        }
                        {
                            this.renderInput(
                            "groups", "Does this store belong to any of these groups?", "checkboxes", 
                            "groupsHelp", "", "", allGroups,
                            this.handleGroupsChange
                            )
                        }
                        {
                            // this.renderInput(
                            // "tags", "Tags, used for searching", "checkboxes", 
                            // "tagsHelp", "", "", allTags,
                            // this.handleTagsChange
                            // )
                        }
                        {
                            this.renderInput(
                            "description", "Description", "textarea", 
                            "", ""
                            )
                        }
                        {
                            this.renderInput(
                            "taxRate", "Default Sales Tax Rate", "text", 
                            "taxRateHelp", "Default tax rate for this store. Enter a decimal, 0.06 for 6%. Tax rates can be set per product."
                            )
                        }
                        {
                            this.renderInput(
                            "address1", "Address", "text", 
                            "", ""
                            )
                        }
                        {
                            this.renderInput(
                            "address2", "Unit, Floor or Other Details", "text", 
                            "", ""
                            )
                        }
                        {
                            this.renderInput(
                            "city", "City", "text", 
                            "", ""
                            )
                        }
                        {
                            this.renderInput(
                            "state", "State", "select", 
                            "", "", "", allStates
                            )
                        }
                        {
                            this.renderInput(
                            "zipPlus4", "Zip Code", "text", 
                            "", ""
                            )
                        }
                        {
                            this.renderInput(
                            "neighborhood", "Neighborhood", "text", 
                            "", ""
                            )
                        }
                        {
                            this.renderInput(
                            "active", "Is this store active?", "radios", 
                            "activeHelp", "If a store is not active, its products are not available in the market.",
                            "", activeOptions, this.handleActiveChange                            
                            )
                        }
                        { this.renderSubmitButton("Save") }
                    </form>
                    </div>
                </div>
                </React.Fragment>
            );
        }
    }
    getClasses(keyName) {
        let classes = "nav-link";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }
    getTabClasses(keyName) {
        let classes = "tab-pane store-tab-pane";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }

    render() {
        // const {store, allStates, allGroups, allShopTypes, shippingOptions,
        // displayTabs,currentTab} = this.state;
        const {displayTabs, init, message} = this.state;

        return (init &&
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Edit Store</h1>
                        {message && <p className="urgent">{message}</p>}
                    </div>
                </div>
                <div className="tab-ui store" role="navigation">
                    <nav className="nav nav-pills nav-justified">
                        { displayTabs.map(ds => 
                                <div data-toggle='tab' onClick={this.handleTabClick} id={"tab-" + ds.key} key={"tab-" + ds.key} className={this.getClasses(ds.key)} href={'#' + ds.key}>{ds.label}</div>
                            )
                        }
                    </nav>
                    <div className="tab-content">
                        { displayTabs.map(ds => 
                            <div id={ds.key} key={ds.key} className={this.getTabClasses(ds.key)}>
                                {this.getComponent(ds)}
                            </div>
                        )
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MyStoreEditForm;