import React, {Component} from 'react';

class PopMakeSure extends Component {

    render() {
        if(this.props.visible) {
            return (
                <React.Fragment>
                    <div className="hover-div">
                        {this.props.message}
                        <button type="button" onClick={this.props.handleConfirm} className={"btn btn-success"}>Yes</button>
                        <button type="button" onClick={this.props.handleCancel} className={"btn btn-danger"}>Cancel</button>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return ('');
        }
    }
}

export default PopMakeSure;