import React, { Component } from 'react';
import Table from "./common/table";
import { Link } from "react-router-dom";

class MissedSearchesTable extends Component {

    render() { 
        const {searches, sortColumn, onSort} = this.props;

        const tableColumns = [
            {
                name: 'Search Term', 
                path: "searchTerm",
                content: search => <Link to={`/search/${search.searchTerm}`}>{search.searchTerm}</Link>
            }  
        ];

        return (
            <Table 
                tableClasses="missed-searches-table"
                data={searches}
                tableColumns={tableColumns}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );    
    }
}

export default MissedSearchesTable;