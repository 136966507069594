import React from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import { capturePayment } from '../../services/merchantService';
import { getUserUuid } from '../../services/authenticationService';

class MyStoreOrderEditForm extends InputForm {

    state = {
        orderUuid: null,
        order: {},
        data: {
            agreed: "",
            shippingTotal: 0.00,
            comment: '',
            trackingNumber: '',
        },
        init: false,
        errors: {},
        schema: {
            
        }
    };

    constructor(props) {
        super(props);
        this.handleAgreedChange = this.handleAgreedChange.bind(this);
    }

    async componentDidMount() {
        if(!this.state.init) {
            const {order} = this.props;
            let {data} = this.state;
            const orderUuid = order.uuid;
            data.shippingTotal = order.shippingTotalPrice;
    
            let schema = {
                _id: Joi.string(),
                agreed: Joi.string().required().label('Confirm order details'),
                comment: Joi.string().allow("").label('Comment'),
                trackingNumber: Joi.string().allow("").label('Tracking Number'),
                shippingTotal: Joi.number().min(0.0).max(order.shippingTotalPrice).label('Shipping Total')
            };
            for(var i=0; i<order.orderItems.length; i++) {            
                const item = order.orderItems[i];
                const itemUuid = item.uuid;
                const qty = item.orderProduct.quantity;
                data["orderitem-"+itemUuid] = qty;
                schema["orderitem-"+itemUuid] = Joi.number().min(0).max(parseInt(qty)).required()
                    .label(`Quantity for ${this.generateProductName(item.orderProduct)}`);
            }
            this.setState({ order, orderUuid, data, schema, init:true });
        }
    }

    doSubmit = async() => {
        try {
            let order = this.state.order;
            const data = this.state.data;
            const orderUuid = this.state.orderUuid;

            // first total the quantity of items, and fail if the total is 0
            // user should cancel the order instead of capturing it.
            let totalQty = 0;
            for(var idx=0; idx<order.orderItems.length; idx++) {
                const itemUuid = order.orderItems[idx].uuid;
                if(data["orderitem-"+itemUuid]) {
                    const newQty = parseInt(data["orderitem-"+itemUuid]);
                    totalQty += newQty;
                }
            }
            if(totalQty === 0) {
                toast.error("You have removed all products from this order. If this is what you intended to do, please cancel the order instead of confirming it.");
                this.setState({working:false});
                return;
            } else {

                for(var idx=0; idx<order.orderItems.length; idx++) {
                    const itemUuid = order.orderItems[idx].uuid;
                    if(data["orderitem-"+itemUuid]) {
                        const newQty = parseInt(data["orderitem-"+itemUuid]);
                        if (order.orderItems[idx].orderProduct.quantity > newQty) {
                            order.orderItems[idx].orderProduct.quantity = newQty;
                        }
                    }
                }
    
                if(order.shippingTotalPrice > data.shippingTotal) {
                    order.shippingTotalPrice = parseFloat(data.shippingTotal);
                }
    
                if(data.comment.length > 0) {
                    const userUuid = getUserUuid();
                    const commentObj = {
                        userUuid: userUuid,
                        commentText: data.comment
                    };
                    if(null === order.comments) {
                        order.comments = [];
                    } 
                    order.comments.push(commentObj);
                }
    
                await capturePayment(orderUuid, order);
                toast.success("Capturing payment...");
                setTimeout( () =>{
                    window.location = '/mybusiness/' + order.merchantUuid + '?tab=orders';
                }, 3500);
   
            }
        }
        catch(exception) {
            console.log(exception);
            this.setState({working:false});
            toast.error("An unexpected error occurred!");
        }
    }

    handleAgreedChange = async(evt) => {
        const {currentTarget: input} = evt;
        const data = {...this.state.data};
        const errors = {...this.state.errors};
        const selectedValue = input.value;
        if(selectedValue) {
            if(input.checked) {
                data.agreed = "yes";
            } else {
                data.agreed = "";
            }
            // data.agreed = selectedValue;
            this.setState({data, errors});
        }
    }

    generateProductName(orderProduct) {
        let productName = orderProduct.product.productName;
        if(orderProduct.variation && orderProduct.variation.variationName) {
            productName += " " + orderProduct.variation.variationName;
        }
        return productName;
    }

    render() {
        const {order,data} = this.state;
        const agreedOptions = [
            {"_id": "yes", "name": "I have reviewed the information above and it is correct."},
            // {"_id": "no", "name": "no"},
        ];
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Capture Funds for Order</h1>
                        <p>Use the form below to optionally adjust the product quantities, shipping total; and optionally add a tracking number or comment. Product quantities and shipping costs can only be adjusted downward because the payment processor won't let us capture more funds than we have already authorized.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                "comment", "Comment (optional)", "textarea",
                                "", "", ""
                                )
                            }
                            {   order.orderItems &&
                                order.orderItems.map(orderItem => { 
                                        return(this.renderInput(
                                            "orderitem-"+orderItem.uuid, this.generateProductName(orderItem.orderProduct) + " Quantity", "text", 
                                            "", "", ""
                                        ))
                                    }
                                )
                            }
                            { // name, label, inputType, helpId, helpText, classes, options, onChange
                                this.renderInput(
                                "trackingNumber", "Tracking Number", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "shippingTotal", "Shipping Total", "text", 
                                "shippingTotalHelp", "Must be less than or equal to original shipping estimate", ""
                                )
                            }
                            {
                                this.renderInput(
                                "agreed", "Confirm order details", "checkboxes", 
                                "agreedHelp", "You must check this box to continue.",
                                "", agreedOptions, this.handleAgreedChange
                                )
                            }
                            { this.renderSubmitButton("Capture Funds") }
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )   
    }
}

export default MyStoreOrderEditForm;