import React, { Component, Fragment } from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import { saveMerchant } from '../../services/merchantService';
import { getOwnershipTypes, getBusinessTypes, getUsStates} from '../../services/lookupService';

class MyBusinessEditForm extends InputForm {

    state = {
        merchant: null, // original data loaded for this merchant
        data: {
            companyName: '',
            description: '',
            businessType1: '',
            businessType2: '',
            businessType3: '',
            ownership: [],
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            neighborhood: ''
        },
        allBusinessTypes: [],
        allOwnershipTypes: [],
        allStates: [],
        init: false,
        errors: {}
    };

    constructor(props) {
        super(props);
        //this.handleBusinessTypeChange = this.handleBusinessTypeChange.bind(this);
        this.handleOwnershipChange = this.handleOwnershipChange.bind(this);
    }

    // schema doesn't have to be part of the state
    // because the schema doesn't change
    schema = {
        _id: Joi.string(),
        companyName: Joi.string().required().min(2).max(500).label('Business Name'),
        description: Joi.string().max(2000).allow('').label('Description'),

        //businessTypes: Joi.array().items(Joi.string()).label('Business Type'),
        businessType1: Joi.string().label('Business Type').allow(''),
        businessType2: Joi.string().label('Other Business Type').allow(''),
        businessType3: Joi.string().label('Other Business Type').allow(''),
        ownership: Joi.array().items(Joi.string()).label('Ownership'),

        address1: Joi.string().required().min(7).max(200).label('Street Address'),
        address2: Joi.string().allow('').max(200).label('Unit, Floor or Other Details'),
        city: Joi.string().required().min(2).max(300).label('City'),
        state: Joi.string().required().min(2).label('State'),
        zipCode: Joi.string().required().min(5).max(10).label('Zip Code'),
        neighborhood: Joi.string().allow('').max(150).label('Neighborhood'),
    };

    // moving to new async design pattern: 
    // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    // from DannyMoshe comment on https://stackoverflow.com/questions/47970276/is-using-async-componentdidmount-good
    async componentDidMount() {
        const allOwnershipTypes = await getOwnershipTypes();
        const allBusinessTypes = await getBusinessTypes();
        const allStates = await getUsStates();
        const merchant = this.props.merchant;
        const data = this.mapToViewModel(merchant);
        this.setState({allOwnershipTypes,allBusinessTypes,allStates,data,init:true,merchant});
    }

    mapToViewModel(merchant) {
        let m = {
            _id: merchant.uuid,
            companyName: merchant.companyName,
            businessType1: '',
            businessType2: '',
            businessType3: '',
            description: merchant.description,
            ownership: merchant.ownership,
            address1: merchant.address1,
            address2: merchant.address2,
            city: merchant.city,
            state: merchant.state,
            zipCode: merchant.zipPlus4,
            neighborhood: merchant.neighborhood
        }
        if(merchant.businessTypes) {
            const btLen = merchant.businessTypes.length;
            if(btLen > 0) {
                m.businessType1 = merchant.businessTypes[0];
                if(btLen > 1) {
                    m.businessType2 = merchant.businessTypes[1];
                    if(btLen > 2) {
                        m.businessType3 = merchant.businessTypes[2];
                    }
                }
            }
        }
        return m;
    }

    normalizeData() {
        const merchant = this.state.merchant;
        const data = this.state.data;
        
        merchant.companyName = data.companyName;
        merchant.description = data.description;
        // merchant.businessTypes = data.businessTypes;
        merchant.businessTypes = [];
        if(data.businessType1.length) {
            merchant.businessTypes.push(data.businessType1);
        }
        if(data.businessType2.length) {
            merchant.businessTypes.push(data.businessType2);
        }
        if(data.businessType3.length) {
            merchant.businessTypes.push(data.businessType3);
        }
        merchant.ownership = data.ownership;
        merchant.address1 = data.address1;
        merchant.address2 = data.address2;
        merchant.city = data.city;
        merchant.state = data.state;
        merchant.zipPlus4 = data.zipCode;
        merchant.neighborhood = data.neighborhood;

        return merchant;
    }

    doSubmit = async() => {
        try {
            const merchant = this.normalizeData();
            const response = await saveMerchant(this.state.merchant.uuid, merchant);
            if(response.data) {
                toast.success("Your changes were saved!");
                setTimeout( () =>{
                    window.location = '/mybusiness/'+this.state.merchant.uuid;
                }, 3500);
            }
            else {
                this.setState({working:false});
                toast.error("Dang! Something prevented your changes from being saved. This issue has been logged and our team will take a look.");
            }
        }
        catch(exception) {
            this.setState({working:false});
            const err = this.getNiceErrorMessage(exception);
            toast.error(err);
           //if(exception.response && exception.response.status >= 400) {
                const errors = {...this.state.errors};
                errors.comments = err;
                this.setState({errors, finalState:'error'});
            //}
        }
    }

    getNiceErrorMessage(exception) {
        let err = "An unexpected error occurred!";
        if(exception.response) {
            if(exception.response.data && exception.response.data.message) {
                err = exception.response.data.message;
            }
        }
        else if (exception.message) {
            err = exception.message;
        }
        return err;
    }

    // handleBusinessTypeChange(evt) {
    //     const {currentTarget: input} = evt;
    //     const data = {...this.state.data};
    //     const errors = {...this.state.errors};
    //     const selectedValue = input.value;
    //     if(selectedValue) {            
    //         const valuePos = data.businessTypes.indexOf(selectedValue);
    //         if(valuePos >= 0) {
    //             data.businessTypes.splice(valuePos, 1);
    //         }
    //         else {
    //             data.businessTypes.push(selectedValue);
    //         }
    //         this.setState({data, errors});
    //     }
    // }

    handleOwnershipChange(evt) {
        const {currentTarget: input} = evt;
        const data = {...this.state.data};
        const errors = {...this.state.errors};
        const selectedValue = input.value;
        if(selectedValue) {            
            const valuePos = data.ownership.indexOf(selectedValue);
            if(valuePos >= 0) {
                data.ownership.splice(valuePos, 1);
            }
            else {
                data.ownership.push(selectedValue);
            }
            this.setState({data, errors});
        }
    }

    addDefaultToSelectList(selectListValues) {
        let values = [];
        const v = {"_id":"","name":"-- Please select --"}
        values.push(v);
        values.push(...selectListValues)
        return values;
    }

    getSelectValuesFromStringArray(stringArray, withDefault = false) {
        let values = [];
        let v = {};
        if(withDefault) {
            v = {"_id":"","name":"-- Please select --"}
            values.push(v);
        }
        for (var i=0;i<stringArray.length;i++) {
            v = {"_id":stringArray[i],"name":stringArray[i]};
            values.push(v);
        }
        return values;
    }

    render() {
        const {allStates, allOwnershipTypes, allBusinessTypes, init} = this.state;
        if(!init) {
            return ('');
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Edit Business</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                "companyName", "Business Name", "text", 
                                "", ""
                                )
                            }
                            {/* {
                                this.renderInput(
                                "businessTypes", "Select all types for this business", "checkboxes", 
                                "businessTypesHelp", "", "", allBusinessTypes,
                                this.handleBusinessTypesChange
                                )
                            } */}
                            {
                                this.renderInput(
                                "businessType1", "Select up to three types for this business", "select", 
                                "businessType1Help", "", "", this.addDefaultToSelectList(allBusinessTypes)
                                )
                            }
                            {
                                this.renderInput(
                                "businessType2", "", "select", 
                                "businessType2Help", "", "", this.addDefaultToSelectList(allBusinessTypes)
                                )
                            }
                            {
                                this.renderInput(
                                "businessType3", "", "select", 
                                "businessType3Help", "", "", this.addDefaultToSelectList(allBusinessTypes)
                                )
                            }

                            {
                                this.renderInput(
                                "ownership", "Which of these are true for the company owners?", "checkboxes", 
                                "ownershipHelp", "", "checkboxes-ownership", this.getSelectValuesFromStringArray(allOwnershipTypes, false),
                                this.handleOwnershipChange
                                )
                            }
                            {
                                this.renderInput(
                                "description", "Description", "textarea", 
                                "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "address1", "Address", "text", 
                                "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "address2", "Unit, Floor or Other Details", "text", 
                                "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "city", "City", "text", 
                                "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "state", "State", "select", 
                                "", "", "", this.addDefaultToSelectList(allStates)
                                )
                            }
                            {
                                this.renderInput(
                                "zipCode", "Zip Code", "text", 
                                "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "neighborhood", "Neighborhood", "text", 
                                "", ""
                                )
                            }
                            { this.renderSubmitButton("Save") }
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MyBusinessEditForm;