import React, { Component } from 'react';
import QueryParams from './common/queryParams';
import Pagination from './common/pagination';
import MissedSearchesTable from './missedSearchesTable';
import {getMissedSearches} from '../services/searchService';
import _ from 'lodash';

class MissedSearches extends Component {

    state = {
        searches: [],
        pageInfo: {
          rowCount: 0,
          pageCount: 0,
          pageSize: 10,
          currentPage: 0
        },
        searchValue: '',
        sortColumn: {path:'searchTerm',order:'asc'},
        pageSize: 10,
    }
    
    getParams = async (queryParams) => {
      const searchValue = queryParams.q ? queryParams.q : '';
      const start = 0;
      const limit = this.state.pageInfo.pageSize;
      const searchData = await getMissedSearches(searchValue, start, limit);
      this.setState({searches:searchData.documents, pageInfo:searchData.pageInfo})
    }

    handleFilterByPage = async (page) => {    
      const pageInfo = this.state.pageInfo;
      pageInfo.currentPage = page;
      const start = pageInfo.pageSize * (page-1);
      const searchData = await getMissedSearches(this.state.searchValue, start, pageInfo.pageSize);
      this.setState({searches:searchData.documents, pageInfo:searchData.pageInfo});
    }

    handleSortByColumn = sortColumn => {
      //@todo sort using query to API
      this.setState({sortColumn});
    }

    getProductsMessage() {
      //const storeName = this.state.currentStore._id === '0' ? "" : " " + this.state.currentStore.name;
      const {rowCount, pageSize, currentPage} = this.state.pageInfo;
      const {searchValue} = this.props;
      
      if(rowCount === 0) {
        let searchStr = "";
        if(searchValue.trim().length > 0) {
          searchStr = ' for \'' + searchValue + '\'';
        }
        return('No matching missed searches' + searchStr);
      }
      else {

          const pageStart = (((currentPage - 1) * pageSize) + 1);
          let pageEnd = pageStart + pageSize - 1;
          if(pageEnd > rowCount) {
            pageEnd = rowCount;
          }
          const pageMessage = pageStart + " to " + pageEnd;
          const matchMessage = searchValue && searchValue.length ? " missed searches matching '" + searchValue + "'" : " missed searches";
          return ('Showing ' + pageMessage + " of " + rowCount + matchMessage);
        }
    }
    
    renderSearches() {
      const {sortColumn, searches, pageInfo} = this.state;
      const {rowCount, pageSize, currentPage} = pageInfo;

      return (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <h1>Missed Searches</h1>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="content">
                <p>{this.getMessage(rowCount)}</p>

                <Pagination 
                  currentPage={currentPage}
                  itemsCount={rowCount} 
                  pageSize={pageSize}
                  onPageChange={this.handleFilterByPage} />               

                <MissedSearchesTable 
                    searches={searches}
                    sortColumn={sortColumn}
                    onSort={this.handleSortByColumn} 
                    />
                    
                <Pagination 
                  currentPage={currentPage}
                  itemsCount={rowCount} 
                  pageSize={pageSize}
                  onPageChange={this.handleFilterByPage} />
              </div>
            </div>
          </div>
          <QueryParams onGetParams={this.getParams} />
        </React.Fragment>
      );
    };

    render() { 
        return (
            <div>
                { this.renderSearches() }
            </div>
        );
    }

}
 
export default MissedSearches;
