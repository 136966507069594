import React, { Component } from 'react';
import EntityImages from './entityImages';

class CartItemView extends Component {
    state = {  } 
    render() { 
        const {orderProduct, shipping, productTotalPrice, productSalesTax} = this.props.item;
        const totalPrice = productTotalPrice + productSalesTax;
        return (
            <React.Fragment>
                <div className="cart-item">
                    <div>
                        <div className="cart-item-name">{orderProduct.product.productName} {orderProduct.variation && orderProduct.variation.variationName}</div> 
                        <div className="cart-item-quantity">({orderProduct.quantity})</div>
                    </div>
                    <div className="cart-item-totals">
                        <EntityImages entityType="product" category="product" entityId={orderProduct.product.uuid} className="grid-image"/>
                        <div className="cart-item-prices">
                            <div className="price-display"><div className="price-name">Product(s) price</div><div className="price-value">${productTotalPrice.toFixed(2)}</div></div>
                            <div className="price-display"><div className="price-name">Sales tax</div><div className="price-value">${productSalesTax.toFixed(2)}</div></div>
                            <div className="price-display last"><div className="price-name">Product Total</div><div className="price-value">${totalPrice.toFixed(2)}</div></div>
                        </div>
                        { this.renderShipping(shipping)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    renderShipping(shipping) {
        if(!shipping || !shipping.shipper || shipping.shipper.length === 0) {
            return '';
        }
        else {
            return (
                <div className="cart-item-shipping">shipper {shipping.shipperName} {shipping.costEstimate}</div>
            );
        }
    }
}
 

export default CartItemView;