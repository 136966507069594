import http from "./httpService";
import config from "../config.json";
// import { string } from "prop-types";
// import { flatMap } from "lodash";

const searchEndpoint = `${config.searchApiRouteBase}`;
const missedSearchesEndpoint = `${config.searchApiRouteBase}/missedSearches`;

export async function doSearch(q, fq, start, limit) {
    let url = `${searchEndpoint}`;
    let queryParams = [];
    if(q && q.length) {
        queryParams.push("q=" + q);
    }
    //console.log("start",start);
    if(start) {
        queryParams.push("start=" + start)
    }
    if(limit) {
        queryParams.push("limit=" + limit)
    }

    if(fq && fq.length) {
        let fqs = [];
        for(var i=0; i<fq.length; i++) {
            const thisFq = encodeURI(fq[i].name + ":" + fq[i].value);
            fqs.push(thisFq);
        }
        if(fqs.length) {
            // weirdly, using encodeURI here 
            // does not effectively encode the separator string [&]
            // so brute force the use of the url-encoded separator here 
            // between the uri-encoded values
            queryParams.push("fq=" + fqs.join("%5B%26%5D"));
        }
        // fq=ownership:Women Owned[&]neighborhood:Canton
        // becomes
        // fq=ownership%3AWomen%20Owned%5B%26%5Dneighborhood%3ACanton
    }

    if(queryParams.length) {
        url += "?" + queryParams.join("&");
    }
    //console.log("url",url);
    let response = await http.get(url);
    return response.data;
}

export async function getMissedSearches() {
    let response = await http.get(missedSearchesEndpoint);
    return response.data;
}

export async function getStores(q, start, limit) {
    let response = await http.get(`${searchEndpoint}/stores?q=${q}&start=${start}&limit=${limit}`);
    return response.data;
}

export async function getStore(id) {
    let response = await http.get(searchEndpoint + "/stores/" + id);
    return response.data; 
}

export async function getStoreProducts(storeId, start, limit) {
    let response = await http.get(`${searchEndpoint}/stores/${storeId}/products?start=${start}&limit=${limit}`);
    return response.data;
}
  