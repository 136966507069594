import React, { Component, Fragment } from 'react';
import Joi from 'joi-browser';
import InputForm from '../common/inputForm';

class ShippingOptionEdit extends InputForm {

    state = {
        data: {
            _id:'',
            shipperName: '', 
            description: '',
            arrivalDaysEstimate: "0", 
            costEstimate: 0.00
        },
        errors: {}
    };
  
    schema = {
        _id: Joi.string(),
        shipperName: Joi.string().required().label('Shipper Name'),
        description: Joi.string().required().label('Description'),
        arrivalDaysEstimate: Joi.string().required().label('Arrival Estimate (days)'),
        costEstimate: Joi.number().min(0.00).required().label('Cost Estimate'),
    };

    async componentDidMount() {
        let shippingOption = this.props.shippingOption; 
        this.setState({data:this.mapToViewModel(shippingOption)});
    }
  
    mapToViewModel(shippingOption) {
        return {
            _id: shippingOption.uuid,
            shipperName: shippingOption.shipperName,
            description: shippingOption.description,
            arrivalDaysEstimate: shippingOption.arrivalDaysEstimate,
            costEstimate: shippingOption.costEstimate
        };
    }

    handleSave = async(evt, optionData) => {
        evt.preventDefault();
        // bubble the save to the parent
        await this.props.handleSave(evt,optionData);     
    } 
  
    getSelectValuesFromStringArray(stringArray, withDefault = false) {
        let values = [];
        let v = {};
        if(withDefault) {
            v = {"_id":"","name":"-- Please select --"}
            values.push(v);
        }
        for (var i=0;i<stringArray.length;i++) {
            v = {"_id":stringArray[i],"name":stringArray[i]};
            values.push(v);
        }
        return values;
    }

    render() {
        const {parentEntity, handleShippingOption, handleCancel} = this.props;
        const shipperData = this.getSelectValuesFromStringArray(this.props.shippers, true);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Edit Shipping Option for {parentEntity}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={handleShippingOption}>
                            {
                                this.renderInput(
                                "shipperName", "Shipper Name", "select", 
                                "", "", "", shipperData
                                )
                            }
                            {
                                this.renderInput(
                                "description", "Description", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "arrivalDaysEstimate", "Arrival Estimate (days)", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "costEstimate", "Cost Estimate ($)", "text", 
                                "", "", ""
                                )
                            }
                            { /*this.renderSubmitButton("Save")*/ }
                            <button className="btn btn-dark btn-rightspace float-left" onClick={(evt) => this.handleSave(evt,this.state.data)}>Save</button>
                            <button className="btn btn-danger btn-rightspace float-left" onClick={handleCancel}>Cancel</button>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )   
    }
}

export default ShippingOptionEdit;