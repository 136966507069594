import React from 'react';
// , { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import Joi from 'joi-browser';
//import { toast } from 'react-toastify';
import QueryParams from './common/queryParams';
import InputForm from './common/inputForm';
import * as userService from '../services/userService';

class LoginForm extends InputForm {
    
    state = {
        data: {username: '', password: ''},
        errors: {}
    };

    // schema doesn't have to be part of the state
    // because the schema doesn't change
    schema = {
        username: Joi.string().required().email().label('Username'),
        password: Joi.string().required().label('Password'),
    };

    getParams = async (queryParams) => {
        if (queryParams && queryParams.u) {
            let data = this.state.data;
            data.username = queryParams.u;
            this.setState(data);
        }
    }

    doSubmit = async() => {
        try {
            await userService.login(this.state.data);
            // this will fire an email to user if all goes well
            window.location = '/verify?u=' + this.state.data.username;    
        }
        catch(exception) {
            console.log(exception);
            //console.log('exception response', exception.response);
            if(exception.response && exception.response.status >= 400) {
                const errors = {...this.state.errors};
                if(exception.response.status === 409) {
                    errors.password = exception.response.data.message;
                }
                else {
                    errors.username = exception.response.data.message;
                }
                this.setState({errors,working:false});
            }
        }
    }

    render() {
        const user = this.props.user;
        if(user && user.uuid) {
            window.location = '/account';
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Login</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                "username", "Username", "text", 
                                "usernameHelp", "The email address you used when creating your account."
                                )
                            }
                            {
                                this.renderInput(
                                "password", "Password", "password", 
                                "", ""
                                )
                            }
                            <div className="row">
                                <div className="col">
                                    { this.renderSubmitButton("Login") }
                                </div>
                            </div>
                            <div className="row topmar">
                                <div className="col col-md-6">                                
                                    <Link to={"/forgot"} className="float-left">
                                        <button alt={"forgot password"} className="btn btn-success">Forgot Password?</button>
                                    </Link> 
                                    <br />&nbsp;
                                </div>
                                <div className="col col-md-6">                                
                                    <Link to={"/register"} className="float-right">
                                        <button alt={"register for an account"} className="btn btn-success">Create New Account</button>
                                    </Link> 
                                    <br />&nbsp;
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <QueryParams onGetParams={this.getParams} />
            </React.Fragment>
        )
    }
}

export default LoginForm;