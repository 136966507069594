import React from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import { registerMerchant } from '../../services/userService';
import { getBusinessTypes, getOwnershipTypes, getUsStates } from '../../services/lookupService';
import config from "../../config.json";

class MyBusinessSignupForm extends InputForm {

    state = {
        data: {
            companyName: '',
            description: '',
            businessTypes: [],
            ownership: [],

            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            neighborhood: '',

            username: '',
            password: ''
        },
        allBusinessTypes: [],
        allOwnershipTypes: [],
        allStates: [],
        init: false,
        errors: {},
        created: false
    };

    constructor(props) {
        super(props);
        this.handleBusinessTypesChange = this.handleBusinessTypesChange.bind(this);
        this.handleOwnershipChange = this.handleOwnershipChange.bind(this);
    }

    // schema doesn't have to be part of the state
    // because the schema doesn't change
    schema = {
        _id: Joi.string(),
        companyName: Joi.string().required().min(2).max(500).label('Store Name'),
        description: Joi.string().allow('').max(800).label('Description'),
        businessTypes: Joi.array().items(Joi.string()).label('Store Type'),
        ownership: Joi.array().items(Joi.string()).label('Ownership'),

        username: Joi.string().min(5).required().email().label('Email Address'),
        password: Joi.string().min(9).allow('')
            .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{9,}$/,
                'password'),

        address1: Joi.string().required().min(7).max(200).label('Street Address'),
        address2: Joi.string().allow('').max(200).label('Unit, Floor or Other Details'),
        city: Joi.string().required().min(2).max(300).label('City'),
        state: Joi.string().required().min(2).label('State'),
        zipCode: Joi.string().required().min(5).max(10).label('Zip Code'),
        neighborhood: Joi.string().allow('').max(150).label('Neighborhood'),
    };

    // moving to new async design pattern: 
    // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    // from DannyMoshe comment on https://stackoverflow.com/questions/47970276/is-using-async-componentdidmount-good
    async componentDidMount() {
        const allBusinessTypes = await getBusinessTypes();
        const allOwnershipTypes = await getOwnershipTypes();
        const allStates = await getUsStates();
        this.setState({ allBusinessTypes, allOwnershipTypes, allStates, init: true });
    }

    doSubmit = async () => {
        try {
            const data = this.state.data;
            const response = await registerMerchant(data);
            if (response.data) {
                toast.success("Your account was created!");
                this.setState({ created: true });
            }
            else {
                this.setState({ working: false });
                toast.error("Dang! Something prevented your changes from being saved. This issue has been logged and our team will take a look and get back to you.");
            }
        }
        catch (exception) {
            this.setState({ working: false });
            const err = this.getNiceErrorMessage(exception);
            toast.error(err);
            //if(exception.response && exception.response.status >= 400) {
            const errors = { ...this.state.errors };
            errors.comments = err;
            this.setState({ errors, finalState: 'error' });
            //}
        }
    }

    getNiceErrorMessage(exception) {
        let err = "An unexpected error occurred!";
        if (exception.response) {
            if (exception.response.data && exception.response.data.message) {
                err = exception.response.data.message;
            }
        }
        else if (exception.message) {
            err = exception.message;
        }
        return err;
    }

    handleBusinessTypesChange(evt) {
        const { currentTarget: input } = evt;
        let data = this.state.data;
        const errors = this.state.errors;
        const selectedValue = input.value;
        if (selectedValue) {
            const valuePos = data.businessTypes.indexOf(selectedValue);
            if (valuePos >= 0) {
                data.businessTypes.splice(valuePos, 1);
            }
            else {
                data.businessTypes.push(selectedValue);
            }
            this.setState({ data, errors });
        }
    }

    handleOwnershipChange(evt) {
        const { currentTarget: input } = evt;
        let data = this.state.data;
        const errors = this.state.errors;
        const selectedValue = input.value;
        if (selectedValue) {
            const valuePos = data.ownership.indexOf(selectedValue);
            if (valuePos >= 0) {
                data.ownership.splice(valuePos, 1);
            }
            else {
                data.ownership.push(selectedValue);
            }
            this.setState({ data, errors });
        }
    }

    addDefaultToSelectList(selectListValues) {
        let values = [];
        const v = { "_id": "", "name": "-- Please select --" }
        values.push(v);
        values.push(...selectListValues)
        return values;
    }

    getSelectValuesFromStringArray(stringArray, withDefault = false) {
        let values = [];
        let v = {};
        if (withDefault) {
            v = { "_id": "", "name": "-- Please select --" }
            values.push(v);
        }
        for (var i = 0; i < stringArray.length; i++) {
            v = { "_id": stringArray[i], "name": stringArray[i] };
            values.push(v);
        }
        return values;
    }

    render() {
        const { allStates, allOwnershipTypes, allBusinessTypes, init, created } = this.state;
        if (!init) {
            return ('');
        }

        const user = this.props.user;
        if (user && user.uuid) {
            //window.location = '/account';
            return (
                <React.Fragment>
                    <h1>It looks like you are already signed in</h1>
                    <p>Please logout using the link at top right, then return here to register your store.</p>
                </React.Fragment>
            );
        }

        if (created) {
            return (
                <React.Fragment>
                    <h1>Success!</h1>
                    <p>Your store and user account were created.
                        An email with details has been sent to the email address you provided.
                        To verify your email address ({this.state.data.username}),
                        please grab the code from the email that was sent to you,
                        and click here&nbsp;<a href={`${config.reactUrl}/verify`} title="verify your email address">{config.reactUrl}/verify</a> to verify your email address.
                        If you don't see an email in your inbox, please check
                        your Spam and other folders.
                    </p>
                    <p>After verifying your email address, you can sign in and build out your online store,
                        including products, shipping options and more.</p>
                    <p>Our team will review your registration and may reach out via email with questions.</p>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Register Store</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                    "username", "Email Address", "text",
                                    "usernameHelp", "The email address you will use to sign in your account."
                                )
                            }
                            {
                                this.renderInput(
                                    "password", "Password", "password",
                                    "passwordHelp", "At least 9 characters, must include at least one each of uppercase (A-Z) lowercase (a-z) digit (0-9) special character (#@$!%*?&)"
                                )
                            }
                            {
                                this.renderInput(
                                    "companyName", "Store Name", "text",
                                    "", ""
                                )
                            }
                            {
                                this.renderInput(
                                    "businessTypes", "Select all types for this store", "checkboxes",
                                    "businessTypesHelp", "", "", allBusinessTypes,
                                    this.handleBusinessTypesChange
                                )
                            }

                            {
                                this.renderInput(
                                    "ownership", "Which of these are true for the company owners?", "checkboxes",
                                    "ownershipHelp", "", "", this.getSelectValuesFromStringArray(allOwnershipTypes, false),
                                    this.handleOwnershipChange
                                )
                            }
                            {
                                this.renderInput(
                                    "description", "Description", "textarea",
                                    "", ""
                                )
                            }
                            {
                                this.renderInput(
                                    "address1", "Address", "text",
                                    "", ""
                                )
                            }
                            {
                                this.renderInput(
                                    "address2", "Unit, Floor or Other Details", "text",
                                    "", ""
                                )
                            }
                            {
                                this.renderInput(
                                    "city", "City", "text",
                                    "", ""
                                )
                            }
                            {
                                this.renderInput(
                                    "state", "State", "select",
                                    "", "", "", this.addDefaultToSelectList(allStates, true)
                                )
                            }
                            {
                                this.renderInput(
                                    "zipCode", "Zip Code", "text",
                                    "", ""
                                )
                            }
                            {
                                this.renderInput(
                                    "neighborhood", "Neighborhood", "text",
                                    "", ""
                                )
                            }
                            {this.renderSubmitButton("Register Store")}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MyBusinessSignupForm;