import React, { Component } from 'react';

class Privacy extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Privacy Policy</h1>
                        <p>Version 1.2 effective 2023-07-31</p>
                        <h2>Zero Data Sold</h2>
                        <p>Planet B does not sell customer or merchant data, and will not knowingly make use of third-party services that sell customer or merchant data.</p>
                        <h2>Logging and Third Parties</h2>
                        <p>Some data is logged and stored on Planet B servers, to assist in troubleshooting and sleuthing required in the event of reported issues, and certain data is shared with third parties as described below.</p>
                        <h3>Payments</h3>
                        <p>We use Stripe for payment processing, storing customer addresses, paying merchants, analytics,
                            and other business services. Stripe collects identifying information about the devices that
                            connect to its services. Stripe uses this information to operate and improve the services it
                            provides to us, including for fraud detection.
                            <br />You can learn more about Stripe and read its privacy policy at <a title="link to Stripe privacy policy" href="https://stripe.com/privacy">https://stripe.com/privacy</a>
                        </p>
                        <h3>Communications</h3>
                        <p>We use third-party services to send emails and text messages for multi-factor authentication,
                            and to send emails regarding orders and support issues.
                            Planet B and the third party service providers store records of outgoing communications,
                            including email addresses and phone numbers used for the communications,
                            as well as the content of those emails and text messages.
                        </p>
                        <h2>Data Security</h2>
                        <p>There are two types of data that Planet B stores and protects:</p>
                        <ol>
                            <li>Payment methods and addresses for customers, and payment receiving details for merchants; these details are stored securely on the Stripe platform.</li>
                            <li>Personally-identifiable information (PII), including email address, customer name, and optionally a phone number for customers; no two pieces of PII are stored in the same location, and certain PII is encrypted.</li>
                        </ol>
                        <p>All actions within the system are fully logged and timestamped.</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Privacy;