import React, { Component } from 'react';
import Table from "./table";
import { Link } from "react-router-dom";
import {formatTimestamp} from "../../utils/format.js"

class OrdersTable extends Component {
    
    getOrderUpdatedTimestamp(updated) {
        return formatTimestamp(updated, 'timestamp') + " GMT";
    }

    render() { 
        const {sortColumn, orders, onSort, storePage, orderPage} = this.props;

        const tableColumns = [
            {
                name: 'Order Created', 
                path: "created",
                content: order => <Link to={`${orderPage}${order.uuid}`}>{formatTimestamp(order.created, 'timestamp')} GMT</Link>
            },
            {
                name: 'Last Updated',
                path: "updated",
                content: order => <React.Fragment>{this.getOrderUpdatedTimestamp(order.updated)}</React.Fragment>
            },
            {
                name: 'Store', 
                path: "storeName",
                content: order => <Link to={`${storePage}${order.storeUuid}`}>{order.storeName}</Link>
            },
            {name: 'Order Status', path: 'orderState'},
            {name: 'Payment Status', path: 'paymentStatus'},
            {name: 'Shipping', path: 'shippingText'},
            {name: 'Qty Items', path: 'countItems'},
            {name: 'Order Total $', path: 'totalText', format: 'currency'}
        ];

        return (
            <Table 
                tableClasses="orders-table"
                data={orders}
                tableColumns={tableColumns}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );    
    }
}

export default OrdersTable;