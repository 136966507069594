import React, { Component } from 'react';
//import QueryParams from '../common/queryParams';
import RouteParams from '../common/routeParams';
import Pagination from '../common/pagination';
import MyBusinessEditForm from './myBusinessEditForm';
import MyStoreEditForm from './myStoreEditForm';
import OrdersTable from '../common/ordersTable';
import * as merchantService from '../../services/merchantService';
import { toast } from 'react-toastify';

// top level page for a business, with tabs for business and each store
class MyBusiness extends Component {

    state = {
        id: '',
        currentTab: '',
        user: null,
        merchant: null,
        stores: null,
        orders: [],
        displayTabs : [],
        error: null,
        start: 0,
        limit: 25,
        searchValue: null,
        sortColumn: '', // orders
        init:false,
    }

    getParams = async (routeParams) => {
        if(!this.state.init) {
            const id = routeParams.id ? routeParams.id : '';
            let error = null;
            let merchant, stores = null;
            let orders = [];
            let displayTabs = [];
            let currentTab = "";

            try {
                merchant = await merchantService.getMerchantWithStores(id);
                stores = merchant.stores;
                orders = await this.getOrders(this.state.start);

                // create an array of the tabs for display
                displayTabs.push({
                    type: "orders",
                    data: orders,
                    classes: "merchant active",
                    label: "Customer Orders",
                    key: "orders"
                });
                displayTabs.push({
                    type: "merchant",
                    data: merchant,
                    classes: "merchant active",
                    label: "Company and Location",
                    key: "merchant-" + merchant.uuid
                });
                merchant.stores.forEach(sto => displayTabs.push({
                    type: "store",
                    data: sto,
                    classes: "store",
                    label: sto.storeName + " - Online Store",
                    key: "store-" + sto.uuid
                })); 

                let tabIds = [];
                displayTabs.forEach(dt => tabIds.push(dt.key)); 

                // default to the orders tab
                currentTab = "orders";
            }
            catch(exception) {
                if(exception.response && exception.response.status >= 400) {
                    if(exception.response.status === 401) {
                        window.location = '/unauthorized';
                    }
                    toast.error(exception.response.data.message);
                    error = exception.response.data.message;
                }
                else if (exception.message) {
                    toast.error(exception.message);
                    error = exception.message;
                }
            }

            this.setState({id,currentTab,merchant,stores,orders,displayTabs,error,init:true});
        }
    }

    // const searchValue = qParams.q ? qParams.q : '';
    // const limit = qParams.limit ? qParams.limit : 2;

    //@todo look for start, limit in the querystring
    //@todo look for tab in the querystring
    /*
        // we might have a tab specified in the querystring
        if(qParams.tab && tabIds.indexOf(qParams.tab) > -1) {
            setCurrentTab(qParams.tab);
        }
        else { // default to the orders tab
            setCurrentTab("orders");
        }

    */

    render() { 
        const {merchant, displayTabs, init} = this.state;
        const messages = merchant ? this.getMessages(merchant) : [];
        if((true === init) && merchant) {
            return (
                <React.Fragment>
                    { messages.map(m => 
                        <div key={"div-"+m.messageType} className={"alert alert-"+m.messageType} role="alert">
                            { m.data.map((d, i) => 
                                <div key={m.messageType + "-" + i}>{d}</div>
                            )
                            }
                        </div>
                        )
                    }
                
                    {/* {merchant && merchant.verified && <div className="row">
                        <div className="col">
                            <Link to="/mystore/new">
                                <button className="btn btn-success m-2 float-right">Create a New Store</button>
                            </Link> 
                        </div>
                    </div>} */}
                    <h1>Business Details</h1>
                    <div className="tab-ui merchant" role="navigation">
                        <nav className="nav nav-pills nav-left">
                            { displayTabs.map(ds => 
                                    <div data-toggle='tab' onClick={this.handleTabClick} id={"tab-" + ds.key} key={"tab-" + ds.key} className={this.getClasses(ds.key)} href={'#' + ds.key}>{ds.label}</div>
                                )
                            }
                        </nav>
                        <div className="tab-content">
                            { displayTabs.map(ds => 
                                <div id={ds.key} key={ds.key} className={this.getTabClasses(ds.key)}>
                                    {this.getComponent(ds)}
                                </div>
                            )
                            }
                        </div>
                    </div>
                    <RouteParams onGetParams={this.getParams} />
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <p>Please wait...</p>
                <RouteParams onGetParams={this.getParams} />
            </React.Fragment>
        );
    }

    handleTabClick = async(evt) => {
        const tabId = evt.target.id;
        const currentTab = tabId.replace("tab-","");
        this.setState({currentTab});
    }

    getComponent(ds) {
        const {merchant, orders} = this.state;
        if(ds.type === "merchant") {
            return(
                <MyBusinessEditForm 
                    merchant={merchant}
                />
            );
        } else if (ds.type === "orders") {
            return(
            <React.Fragment>
                <Pagination 
                    currentPage={orders.pageInfo.currentPage}
                    itemsCount={orders.pageInfo.rowCount} 
                    pageSize={orders.pageInfo.pageSize}
                    onPageChange={this.handleFilterByPage} />
                <OrdersTable 
                    orders={orders.documents}
                    sortColumn="created"
                    onSort={this.handleSortByColumn} 
                    orderPage="/mystore/order/"
                    storePage="/store/"
                    />

            </React.Fragment>);
        }
        else {
            return(<MyStoreEditForm store={ds.data} merchant={merchant} />);
        }
    
    }

    getClasses(keyName) {
        let classes = "nav-link merchant-nav-link";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }

    getTabClasses(keyName) {
        let classes = "tab-pane";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }
    
    getMessages(merchant) {
        let warning = [];
        let danger = [];
        if(merchant) {
            if(merchant.blocked) {
                danger.push("This business is blocked. Please contact Planet B support for more details.");
            }
            if(!merchant.verified) {
                warning.push("This business has not been approved. You can still edit details and products for the business and store.");
            }
            //@todo also check if the record is incomplete
            //@todo also possibly info, success messages
        }

        let ret = [];
        if(warning.length > 0) {
            ret.push({"messageType": "warning", "data": warning });
        }
        if(danger.length > 0) {
            ret.push({"messageType": "danger", "data": danger});
        }
        return (ret);
    }
    
    async getOrders(start) {
        let orderData = await merchantService.getMerchantOrders(start, this.state.limit);
        for (var i=0;i<orderData.documents.length;i++) {
            orderData.documents[i].shippingText = orderData.documents[i].shipping.shipperName;
            orderData.documents[i].totalText = orderData.documents[i].storeNetTotal;
        }
        return orderData;
    }

    handleFilterByPage = async (page) => { 
        let orders = this.state.orders;
        const start = orders.pageInfo.pageSize * (page-1);
        orders = await this.getOrders(start);
        this.setState({orders, start});
    }

    handleSortByColumn = sortColumn => {
        //@todo sort using query to API
        this.setState({sortColumn});
    }
    
}

export default MyBusiness;