import React, {useState, useEffect} from 'react';

const FileUpload = ({label, name, error, helpId, helpText, classes, fileTypes, onUpload, ...rest}) => {
    const helpDiv = !helpId.length ? '' : <small id={helpId} className="form-text text-muted">{helpText}</small>;
    const describedBy = !helpId.length ? '' : helpId;

    const [file, setFile] = useState([]);
    const [fileUrl, setFileUrl] = useState([]);

    useEffect(() => {
        if (Array.isArray(file)) return // && file.length === 0) return;
        setFileUrl(URL.createObjectURL(file));
    }, [file]);

    function onFileChange(evt) {
        if(evt.target.files[0]) {
            setFile(evt.target.files[0]);
        }
    }

    classes = "form-group file-upload" + (classes ? classes : "");
    return( 
        <div className={classes}>
            <label htmlFor={name}>{label}</label>
            <div><input id={name} name={name} type="file" accept={fileTypes} title="click to upload file" aria-describedby={describedBy} onChange={onFileChange}/></div>
            {helpDiv}
            { error && <div className="alert alert-danger" role="alert">
                {error}
            </div>
            }
        </div>
    )
}
 
export default FileUpload;