import React from 'react';

const InputCheckboxes = ({label, name, error, helpId, helpText, options, classes, onChange, value, ...rest}) => {
    const helpDiv = !helpId.length ? '' : <small id={helpId} className="form-text text-muted">{helpText}</small>;
    const describedBy = !helpId.length ? '' : helpId;
    classes = "form-group " + (classes ? classes : "");

    return( 
        <div className={classes}>
            <label htmlFor={name}>{label}</label>
            <div className="form-checkboxes">
            { error && <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            }
            {
                options.map(option => 
                    renderSingleCheckbox(name, describedBy, option, value, onChange) 
                )
            }   
            {helpDiv}
            </div>
        </div>
    )
}
function renderSingleCheckbox(name, describedBy, option, value, onChange)  {
    let selected = false;
    if(value) {
        if(Array.isArray(value) && value.indexOf(option._id) > -1) {
            selected = true;
        } else {
            if(value.length && value === option._id) {
                selected = true;
            }
        }
    }
    return (
        <div className="form-checkbox col col-sm-6" key={option._id}>
            <input 
                aria-describedby={describedBy} 
                type="checkbox" 
                name={name} 
                className="form-control"
                id={name + "_" + option._id} 
                value={option._id}
                defaultChecked={selected}
                onChange={(evt) => onChange(evt) }
                />
            <label htmlFor={name + "_" + option._id}>{option.name}</label>
        </div>
    );

}
 
export default InputCheckboxes;