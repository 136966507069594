import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import RouteParams from '../common/routeParams';
import InputForm from '../common/inputForm';
import { saveProduct } from '../../services/productService';
import { getMerchantStore } from '../../services/merchantService';

class MyStoreProductCreateForm extends InputForm {

    state = {
        product: {},
        productUuid: '',
        merchantUuid: null,
        storeUuid: null,
        storeName: null,
        data: {
            productName: '', 
            quantityAvailable: 0, 
            published: "yes",
            price: 0.00,
            taxable: "yes",
            taxRate: 0.00,
            description: '',
            sku: '',
            barcode: '',
            upc: '',
        },
        errors: {}
    };

    getRouteParams = async(routeParams) => {
        const {user} = this.props;
        if(null === user) {
            window.location = '/login';
        }
  
        const storeUuid = routeParams.id ? routeParams.id : ''; 
        let storeName = '';
        let store = {};
        let merchantUuid = '';
        let data = this.state.data;
        try {
            // based on the incoming id param, get the store details
            const response = await getMerchantStore(storeUuid);
            store = response.data;
            storeName = store.storeName;
            data.taxRate = parseFloat(store.taxRate); // default product tax rate
            merchantUuid = store.merchantUuid;
        }
        catch(exception) {
            console.log(exception);
            if(exception.response && exception.response.status === 404) {
                // redirect to the Notfound page
                // replace the history so that clicking the back button 
                // goes to the last page prior to the one with the bad product id
                return this.props.history.replace('/notfound');
            }
            else if(exception.response.status === 401) {
                window.location = '/unauthorized';
            }
            else {
                console.log(exception);
                toast.error('An unhandled exception occurred!');
            }
        }
        this.setState({merchantUuid, storeUuid, storeName, store, data})
    }
  
    schema = {
        _id: Joi.string(),
        productName: Joi.string().required().label('Product Name'),
        description: Joi.string().allow("").label('Description'),
        upc: Joi.string().allow("").label('Universal Product Code (UPC)'),
        sku: Joi.string().allow("").label('Stock Keeping Unit (SKU)'),
        barcode: Joi.string().allow("").label('Barcode'),
        quantityAvailable: Joi.number().min(0).required().label('Quantity Available'),
        published: Joi.string().required().label('Published'),
        //Joi.number().min(0).max(100).required().label('Number in Stock'),
        // productRating: Joi.number().min(1).max(10).required().label('Rate'),
        price: Joi.number().min(0.0).required().label('Price'),
        taxable: Joi.string().required().label('Taxable'),
        taxRate: Joi.number().min(0.0).required().label('Tax Rate')
    };

    doSubmit = async() => {
        try {
            const productData = this.normalizeData();
            const savedData = await saveProduct(productData);
            toast.success("Your changes were saved!");
            setTimeout( () =>{
                window.location = '/myproduct/edit/' + savedData.uuid;
            }, 1500);
        }
        catch(exception) {
            this.setState({working:false});
            toast.error("An unexpected error occurred!");
        }
    }

    normalizeData() {
        let productData = this.state.data;
        
        productData.published = productData.published === "yes" ? true : false;
        productData.taxable = productData.taxable === "yes" ? true : false;

        productData.storeUuid = this.state.storeUuid;
        productData.storeName = this.state.storeName;
        productData.merchantUuid = this.state.merchantUuid;

        productData.taxRate = parseFloat(productData.taxRate);
        productData.price = parseFloat(productData.price);
        productData.quantityAvailable = parseInt(productData.quantityAvailable);

        //@todo any other machinations
        return productData;
    }

    render() {
        const {store} = this.state;
        const yesNoOptions = [
            {"_id":"yes","name":"yes"},
            {"_id":"no","name":"no"}
        ];
        if(!store) {
            return (                
                <RouteParams onGetParams={this.getRouteParams} />
            );
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Create Product</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link className="breadcrumb-item" to={'/mybusiness/' + store.merchantUuid + '?tab=store-' + store.uuid}>
                                    Store
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link className="breadcrumb-item" to={'/mystore/edit/' + store.uuid + '/products'}>
                                    Products
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Create Product</li>
                        </ol>
                    </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            { // name, label, inputType, helpId, helpText, classes, options, onChange
                                this.renderInput(
                                "productName", "Product Name", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "description", "Description", "textarea",
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "published", "Is this product published?", "radios", 
                                "publishedHelp", "If a product is published it is available in the market.", 
                                "", yesNoOptions
                                )
                            }
                            {
                                this.renderInput(
                                "upc", "Universal Product Code (UPC)", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "sku", "Stock Keeping Unit (SKU)", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "barcode", "Barcode", "text", 
                                "", "", ""
                                )
                            }
                            {/* {
                                this.renderInput(
                                "genreId", "Genre", "select", 
                                "", "", "", this.state.allStores
                                )
                            } */}
                            {
                                this.renderInput(
                                "quantityAvailable", "QuantityAvailable", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "price", "Price", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "taxable", "Is this product taxable?", "radios", 
                                "taxableHelp", "If a product is taxable it will default to the store tax rate unless you change the tax rate.", 
                                "", yesNoOptions
                                )
                            }
                            {
                                this.renderInput(
                                "taxRate", "Tax Rate", "text", 
                                "taxRateHelp", "Enter a decimal, e.g. 0.06 for 6%. If the item is marked taxable and no tax rate is specified, the store's default tax rate will be used", ""
                                )
                            }

                            { this.renderSubmitButton("Save") }
                        </form>
                    </div>
                </div>
                <RouteParams onGetParams={this.getRouteParams} />
            </React.Fragment>
        )   
    }
}

export default MyStoreProductCreateForm;