import _ from 'lodash';

export function getContextualPages(currentPage, pagesCount) {
    let pages = [];
    let numPageLinksToDisplay = pagesCount < 5 ? pagesCount : 5;
    const seeMore = "...";

    if(pagesCount < 2) {
        return pages;
    }

    if(currentPage < numPageLinksToDisplay || (currentPage === numPageLinksToDisplay && currentPage === pagesCount)) {
        // just include the first n pages
        pages = _.range(1, numPageLinksToDisplay+1);
        if(pagesCount > pages.length + 1) {
            pages[numPageLinksToDisplay] = seeMore;
            pages[numPageLinksToDisplay+1] = pagesCount;
        } else if(pagesCount === pages.length + 1) {
            pages[numPageLinksToDisplay] = pagesCount;
        }
    } else {
        let lastPage = 1;
        if ((currentPage + numPageLinksToDisplay - 2) > pagesCount) {
           lastPage = pagesCount + 1;
        } else {
            lastPage = currentPage + numPageLinksToDisplay - 2;
        }
        pages = _.range(lastPage-numPageLinksToDisplay, lastPage);
        pages.unshift(1,seeMore);
        if (lastPage === pagesCount) {
            pages.push(pagesCount);
        } else if(lastPage < pagesCount + 1) {
            pages.push(seeMore);
            pages.push(pagesCount);
        }
    }
    return pages;
}

