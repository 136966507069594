
export function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString('en-US', options);

    //     console.log(new Intl.DateTimeFormat('en-US', 
    //     {
    //         year: 'numeric', 
    //         month: '2-digit',
    //         day: '2-digit', 
    //         hour: '2-digit', 
    //         minute: '2-digit'
    //     })
    // .format());
 }
  
  export default {
      formatDate
  }
