import React, { Component } from 'react';
import Table from "../common/table";
import Pagination from '../common/pagination';
import { toast } from 'react-toastify';
import * as adminService from '../../services/adminService';
import SearchBox from '../common/searchBox';
import { Link } from "react-router-dom";
import {formatTimestamp} from "../../utils/format.js"

class DashboardUserTable extends Component {
        
    state = {  
        userData: [],
        searchValue:'',
        limit: 10,
        sortColumn: {path:"created", order:"desc"},
        init: false
    } 

    async componentDidMount() {
        if(this.state.init) {
            return;
        }
        const userData = await this.getData(0, this.state.limit, this.state.sortColumn.path, this.state.sortColumn.order);
        this.setState({userData,init:true});
    }

    getData = async(start, limit, sortColumn, sortOrder, searchValue) => {
        let userData = [];
        try {
            let response = await adminService.getDashboardData('user',searchValue,start,limit);
            if(response.data) {
                userData = response.data;
            }
        } catch(exception) {
            if(exception.response.data) {
                const msg = exception.response.data.message ? exception.response.data.message : "an unhandled exception occurred";
                toast.error(msg);
            } else {
                toast.error("an unhandled exception occurred");
            }
        }
        return userData;
    }

    handleFilterByPage = async (page) => {    
        const pageInfo = this.state.userData.pageInfo;
        pageInfo.currentPage = page;
        const start = pageInfo.pageSize * (page-1);
        
        const userData = await this.getData(start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, this.state.searchValue);
        this.setState({userData});
    }
    
    handleSortByColumn = async sortColumn => { 
        const pageInfo = this.state.userData.pageInfo;
        const userData = await this.getData(pageInfo.start, pageInfo.pageSize, sortColumn.path, sortColumn.order, this.state.searchValue);
        this.setState({userData,sortColumn});
    }

    handleSearch = async searchValue => {
        const pageInfo = this.state.userData.pageInfo;
        const userData = await this.getData(pageInfo.start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, searchValue);
        this.setState({searchValue, userData});
    }

    render() { 
        const {userData, searchValue, sortColumn} = this.state;
        const tableColumns = [
            {
                name: 'Username', 
                path: "username",
                content: user => <Link to={`/account/${user.uuid}`}>{user.username}</Link>
            },
            {
                name: 'Role', 
                path: 'roleName'
            },
            {
                name: 'Business', 
                path: 'merchantUuid',
                content: user => user.roleName === "merchant" ?
                <Link to={`/mybusiness/${user.merchantUuid}`}>{user.merchantName}</Link>
                : ""
            },
            {
                name: 'Verified?', 
                path: 'verified',
                content: user => user.verified === true ? "yes" : "no"
            },
            {
                name: 'Blocked?', 
                path: 'blocked',
                content: user => user.blocked === true ? "yes" : "no"
            },
            {
                name: 'Flooded?',
                path: 'flooded',
                content: user => user.flooded === true ? `yes (${user.flood})` : "no"
            },
            {
                name: 'Created',
                path: 'created',
                content: user => formatTimestamp(user.created, "timestamp")
            },
            {
                name: 'Updated',
                path: 'updated',
                content: user => formatTimestamp(user.updated, "timestamp")
            }
        ];

        if(userData.pageInfo) {
            //if(merchantData && merchantData.pageInfo && null !== merchantData.documents) {
            return (
                <React.Fragment>
                    <Pagination 
                        currentPage={userData.pageInfo.currentPage}
                        itemsCount={userData.pageInfo.rowCount} 
                        pageSize={userData.pageInfo.pageSize}
                        onPageChange={this.handleFilterByPage} />    
                    <SearchBox value={searchValue} onChange={this.handleSearch} />  
                    <Table 
                        tableClasses="pb-table dashboard-table"
                        data={userData.documents}
                        tableColumns={tableColumns}
                        sortColumn={sortColumn}
                        onSort={this.handleSortByColumn}
                    />
                </React.Fragment>
            );    
        } else {
            return (
                <React.Fragment>
                    <SearchBox value={searchValue} onChange={this.handleSearch} />
                    <p>No matching records.</p>
                </React.Fragment>
            );       
        }
    }
}

export default DashboardUserTable;