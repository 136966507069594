import React, { Component } from 'react';
import Input from './input';

const SearchBox = ({value, onChange}) => {
    return (
        <Input 
            type="text" 
            placeholder="Search..."
            label='' 
            name="search"
            error=''
            value={value}
            onChange={e => onChange(e.currentTarget.value)}
            helpId='' helpText=''
        />
    );
}
 
export default SearchBox;