import Raven from "raven-js";

function init() {
    Raven.config("https://691463dede6049a295f64007bf79373d@o1200246.ingest.sentry.io/6324172",
        {
            release: "1-0-0",
            environment: "dev-test"
        }
    ).install();
}
function log(error) {
    Raven.captureException(error);
}

export default {
    init,
    log    
}