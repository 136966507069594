import React from 'react';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';
import InputForm from '../common/inputForm';
import FileUpload from '../common/fileUpload';

class MyStoreProductsImport extends InputForm {

    state = {
        storeUuid: '',
        parentEntity: '',
        init:false,
        data: {
            _id:'',
            notes: ''
        },
        errors: {}
    }
 
    schema = {
        _id: Joi.string(),
        notes: Joi.string().required().label('Import notes'),
    };

    async componentDidMount() {
        const {storeUuid,parentEntity} = this.props
        this.setState({storeUuid,parentEntity,init:true});
    }

    render() { 
        const {parentEntity,init} = this.state;
        if(!init || !this.props.doDisplayForm) {
            return('');
        }
        return (
            <div className="floating-div">
                <div className="row">
                    <div className="col">
                        <h1>Import Products for {parentEntity}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>You can upload an export file from Shopify, or&nbsp;
                             <a href="/planetb_products_import_example.csv" title="download example CSV file">download an example import file</a>
                            that you can open in Excel, Google Sheets or Open Office to enter your own products.
                        </p>
                        
                        <form>
                            <FileUpload 
                                label="Comma-separated values (CSV) file" 
                                name="upload" // this has to match the name of the field expected by the API
                                fileTypes=".csv"
                                error="" 
                                helpId="productImportHelp"
                                helpText=""
                                classes=""
                            />
                            {
                                this.renderInput(
                                "notes", "Import Notes", "textarea", 
                                "notesHelp", "Notes for this set of products being imported, optional", ""
                                )
                            }
                            <button className="btn btn-dark btn-rightspace float-left" onClick={this.props.handleImportProducts}>Import Products</button>
                            <button className="btn btn-danger btn-rightspace float-left" onClick={this.props.handleImportCancel}>Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        )   
    }

}
 
export default MyStoreProductsImport;