import React from 'react';
import InputForm from '../common/inputForm';
import Joi from 'joi-browser';
import Table from "../common/table";
import InputCheckboxes from '../common/inputCheckboxes';
import { getDimensionUnits } from '../../services/lookupService';

// Displays, and lets the user edit, dimensions for a product or product variation
class PhysicalDimensions extends InputForm {

  state = {
    init:false,
    data: {
      _id:null,
      name: '',
      unit: '',
      value: 0.00
    },
    errors:{},
    dimensions: [],
    selectedDimensions: [],
    maxIndex: 0,
    doDisplayForm: false,
    dimensionNames: [],
    dimensionUnits: []
  }

  schema = {
    _id: Joi.string().allow(''),
    name: Joi.string().required().label('Dimension Name'),
    unit: Joi.string().required().label('Unit of Measure'),
    value: Joi.number().min(0.00).required().label('Value'),
  };

  emptyDimension = {
    _id:null,
    name: '',
    unit: '',
    value: 0.00
  };

  handleSubmit = (evt) => {
    //evt.preventDefault();
  }

  handleEditClick = (evt, currentDimension) => {
    evt.preventDefault();
    const doDisplayForm = true;
    const data = currentDimension;
    const dimensionUnits = this.getUnitsForDimensionName(this.state.dimensionNames,data.name);
    this.setState({doDisplayForm,data,dimensionUnits});
  }

  handleNewClick = (evt) => {
    evt.preventDefault();
    const doDisplayForm = true;
    let data = this.emptyDimension;
    this.setState({doDisplayForm,data});
  }

  handleSelectClick = ({currentTarget}) => {
    // get option id from input id 
    // id format for input is option-select-[id]_[id]
    const idParts = currentTarget.id.split("_");
    if(idParts.length < 2) {
      return;
    }

    const optionId = idParts[1];
    let selectedDimensions = this.state.selectedDimensions;
    let valuePos = -1;
    for (var i=0;i<selectedDimensions.length;i++) {
      if(selectedDimensions[i] === optionId) {
          valuePos = i;
      }
    }   

    if(currentTarget.checked) {
      if(valuePos === -1) {
        selectedDimensions.push(parseInt(optionId))
      }
    } else {
      if(valuePos > -1) {
        selectedDimensions.splice(valuePos, 1);
      }
    }
    this.setState({selectedDimensions});
  }

  handleSave = async(evt, data) => {
    evt.preventDefault();
    let dimensions = this.state.dimensions;
    let maxIndex = this.state.maxIndex;

    data.value = parseFloat(data.value);
    let dataIdx = -1;
    if(null === data._id || (`${data._id}`).length === 0) {
      maxIndex = maxIndex + 1;
      data._id = parseInt(maxIndex);
    }
    else {
        for (var idx=0;idx<dimensions.length;idx++) {
          if(dimensions[idx]._id === data._id) {
            dataIdx = idx;
          }
        }   
    }
    if(dataIdx === -1) {
      dimensions.push(data);
    }
    else {
      dimensions[dataIdx] = data;
    }

    data = this.emptyDimension;
    this.setState({dimensions,data,doDisplayForm:false,maxIndex});
    this.props.handleSave(evt,dimensions);
  }    

  handleDelete = async (evt) => {
    evt.preventDefault();
    let dimensions = this.state.dimensions;
    let data = this.state.selectedDimensions;
    for (var i=0;i<data.length;i++) {            
        dimensions = this.deleteOptionById(data[i], dimensions);
    }    
    this.setState({dimensions, selectedDimensions:[]});
    this.props.handleSave(dimensions);
  }

  handleCancel(evt) {
    evt.preventDefault();
    const data = this.emptyDimension;
    this.setState({doDisplayForm:false,data});
  }

  handleDimensionNameChange(evt) {
    const dimensionUnits = this.getUnitsForDimensionName(this.state.dimensionNames,evt.target.value);
    const data = {...this.state.data};
    const errors = {...this.state.errors};
    const input = evt.currentTarget;

    // validate the user's text
    const errorMessage = this.validateProperty(input);
    if(errorMessage) 
        errors[input.name] = errorMessage;
    else 
        delete errors[input.name];

    data[input.name] = input.value;
    this.setState({dimensionUnits,data,errors});
  }

  addDefaultToSelectList(selectListValues) {
    let values = [];
    const v = {"_id":"","name":"-- Please select --"}
    values.push(v);
    values.push(...selectListValues)
    // for (var i=0;i<selectListValues.length;i++) {
    //     values.push(selectListValues[i]);
    // }
    return values;
  }

  getUnitsForDimensionName(dimensionNames, dimensionName) {
    let values = [];
    let v = {};
    v = {"_id":"","name":"-- Please select --"}
    values.push(v);
    for (var idx=0;idx<dimensionNames.length;idx++) {
      if(dimensionNames[idx].name === dimensionName) {
        const dimensionUnits = dimensionNames[idx].values;
        for (var jdx=0;jdx<dimensionUnits.length;jdx++) {
          v = dimensionUnits[jdx];
          values.push(v);
        }
      }
    }
    return values;
  }

  deleteOptionById(optionId, options) {      
    let valuePos = -1;  
    for (var idx=0;idx<options.length;idx++) {
        if(options[idx]._id === optionId) {
            valuePos = idx;
        }
    } 
    if(valuePos > -1) {
        options.splice(valuePos, 1);
    }
    return options;
  }

  async componentDidMount() {
    let dimensions = this.props.dimensions;
    // create an id for each dimension, for use in the table
    let maxIndex = this.state.maxIndex;
    if(!dimensions) {
      dimensions = [];
    }
    for (var idx=0;idx<dimensions.length;idx++) {            
      if(!dimensions[idx]._id) {
        dimensions[idx]._id = parseInt(idx);
      }
      maxIndex = dimensions[idx]._id;
    } 
    let dimensionNames = await getDimensionUnits();
    const dimensionUnits = this.getUnitsForDimensionName(dimensionNames, "");
    dimensionNames = this.addDefaultToSelectList(dimensionNames);
    this.setState({dimensions,init:true,
      dimensionNames,dimensionUnits,maxIndex});
  }

  constructor(props) {
      super(props);
      this.handleCancel = this.handleCancel.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleDimensionNameChange = this.handleDimensionNameChange.bind(this);
  }

  render() { 
    const {dimensions, selectedDimensions, doDisplayForm, dimensionNames, dimensionUnits } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="content">
              <button className="btn btn-success m-2 float-right" onClick={this.handleNewClick}>New</button>
              {dimensions && dimensions.length > 0 && <button className="btn btn-danger m-2 float-right" onClick={this.handleDelete}>Delete</button>}
              {this.getDimensionsTable(dimensions, selectedDimensions)}                    
            </div>
          </div>
        </div>
        { this.renderEditForm(doDisplayForm,dimensionNames,dimensionUnits) }
      </React.Fragment>
    );
  }

  renderEditForm(doDisplayForm,dimensionNames,dimensionUnits) {
      if(!doDisplayForm) {
          return '';
      }
      return(
        <div className="floating-div">
          <div className="row">
            <div className="col">
                <form onSubmit={this.handleSubmit}>
                    {
                      this.renderInput(
                        "name", "Dimension Name", "select", 
                        "nameHelp", "", "", dimensionNames, this.handleDimensionNameChange
                      )
                    }
                    {
                      this.renderInput(
                        "unit", "Unit of Measure", "select", 
                        "unitHelp", "", "", dimensionUnits
                      )
                    }
                    {
                        this.renderInput(
                        "value", "Value", "text", 
                        "", "", ""
                      )
                    }
                    <button className="btn btn-dark btn-rightspace float-left" onClick={(evt) => this.handleSave(evt,this.state.data)}>Save</button>
                    <button className="btn btn-danger btn-rightspace float-left" onClick={this.handleCancel}>Cancel</button>
                </form>
            </div>
          </div>
      </div>
    );
  }

  getViewClasses(viewType, selectedViewType) {
    let classes = "view-link " + viewType;
    if(selectedViewType === viewType) {
      classes += " active";
    }
    return classes;
  }

  getDimensionsTable(dimensions, selectedDimensions) {
      if(!dimensions) {
          return ('');
      }
      const tableColumns = [
        {
            name: '',
            key: 'select',
            path: "select-link",
            content: dimension => <InputCheckboxes
                name={"option-select-"+dimension._id}
                classes=''
                label={''} 
                options={[{_id:dimension._id, name:''}]}
                onChange={(evt) => this.handleSelectClick(evt) }
                error={''}
                helpId={''} 
                helpText={''}
                value={selectedDimensions}
            />
        },
        {
            name: 'Dimension', 
            key: 'edit',
            path: "edit-link",                
            content: dimension =>
                <React.Fragment><button className="btn btn-success btn-sm" onClick={(evt) => this.handleEditClick(evt,dimension)}>Edit</button>
                {dimension.name}</React.Fragment>
        },
        {
          name: 'Value', 
          key: 'value',
          path: "value",                
          content: dimension =>
              <React.Fragment>{dimension.value + " " + dimension.unit}</React.Fragment>
        }
    ];
    return (
        <Table 
            tableClasses="pb-table dimensions-table"
            data={dimensions}
            tableColumns={tableColumns}
            sortColumn="name"
            //onSort={onSort}
        />
    );    
  }
}
 
export default PhysicalDimensions;