import React from 'react';

const InputSelect = ({label, name, error, helpId, helpText, options, classes, onChange, value, ...rest}) => {
    const helpDiv = !helpId.length ? '' : <small id={helpId} className="form-text text-muted">{helpText}</small>;
    const describedBy = !helpId.length ? '' : helpId;
    classes = "form-group " + (classes ? classes : "");
    return( 
        <div className={classes}>
            <label htmlFor={name}>{label}</label>
            { error && <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            }
            {renderSelect(name,describedBy,onChange,options,value)}
            {helpDiv}
        </div>
    )
}
function renderSelect(name,describedBy,onChange,options,value, ...rest) {
    if(null !== onChange) {
        return(
            <select 
            {... rest}
            name={name} 
            id={name} 
            defaultValue={value}
            className="form-control"
            aria-describedby={describedBy} 
            onChange={onChange}
            >
            {
                options.map(option => 
                    <option key={option._id} value={option._id}>{option.name}</option>
                )
            }   
            </select>
        );
    }
    else {
        return(
            <select 
            {... rest}
            name={name} 
            id={name} 
            defaultValue={value}
            className="form-control"
            aria-describedby={describedBy} 
            >
            {
                options.map(option => 
                    <option key={option._id} value={option._id}>{option.name}</option>
                )
            }   
            </select>
        );    
    }
}
// function renderOption(option,value) {
//     if(value === option._id) {
//         return (
//             <option selected key={option._id} value={option._id}>{option.name}</option>
//         );
//     }
//     else {
//         return (<option key={option._id} value={option._id}>{option.name}</option>);
//     }    
// }
export default InputSelect;