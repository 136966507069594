import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Home extends Component {

    render() {
        return (
            <div className="search-page">
                <div className="tagline fat-bottom">Register to sell on Planet B</div>
                <div className="align-center extra-fat-bottom">
                    <Link to={"/signup"}>
                        <button alt={"sign up to sell"} className="btn btn-success">Click here to sign up</button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Home;