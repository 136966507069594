import http from "./httpService";
import config from "../config.json";

const lookupApiRouteBase = config.lookupApiRouteBase;

export async function getOwnershipTypes() {
    const response = await http.get(`${lookupApiRouteBase}ownershipTypes`);
    if (response.data) {
        return response.data;
    }
    return [];
}
export async function getBusinessTypes() {
    const response = await http.get(`${lookupApiRouteBase}businessTypes`);
    if (response.data) {
        return response.data;
    }
    return [];
}
export async function getUsStates() {
    const response = await http.get(`${lookupApiRouteBase}usStates`);
    if (response.data) {
        return response.data;
    }
    return [];
}
export async function getCountries() {
    const response = await http.get(`${lookupApiRouteBase}countries`);
    if (response.data) {
        return response.data;
    }
    return [];
}
export async function getGroups() {
    const response = await http.get(`${lookupApiRouteBase}groups`);
    if (response.data) {
        return response.data;
    }
    return [];
}
export async function getShopTypes() {
    const response = await http.get(`${lookupApiRouteBase}storeTypes`);
    if (response.data) {
        return response.data;
    }
    return [];
}
export async function getDimensionUnits() {
    const response = await http.get(`${lookupApiRouteBase}dimensions`);
    if (response.data) {
        return response.data;
    }
    return [];
}
