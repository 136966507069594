import axios from "axios";
import authenticationService from "./authenticationService";
import logger from "./logService";

// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['Access-Control-Allow-Headers'] = "access-control-allow-origin, access-control-allow-headers,Authorization,Set-Cookie,X-Xsrf-Token";

// headers: {
    //"Access-Control-Allow-Origin": "*", 
    // "Access-Control-Allow-Headers": "access-control-allow-origin, access-control-allow-headers"
//},

// define an interceptor for the response
// pass handler functions as parameters
// first param is success handler, second is error handler
axios.interceptors.response.use(axiosResponse => {
    //console.log("auth token from API response", axiosResponse.headers['Authorization']);
    authenticationService.saveHeaders(axiosResponse.headers);
    // if(axiosResponse.request.responseURL) {
    //     console.log("url",axiosResponse.request.responseURL);
    // } else {
    //     console.log("huh", axiosResponse.request);
    // }
    //this is critical- if we handle the response, we have to manually return it
    return axiosResponse;
}, axiosError => {
    // console.log("error",axiosError.response.request.responseURL);
    const expectedResponse = 
        axiosError.response && 
        axiosError.response.status >=200 &&
        axiosError.response.status < 300;
    if(axiosError.response && !expectedResponse) {
        logger.log(axiosError);
        const resp = axiosError.response;
        if(resp.status === 401) {
            if(resp.data && resp.data.message && resp.data.message === "Token is expired") {
                authenticationService.logout();
            }
        }
        // this is critical- if we handle the error, we have to manually reject/return it
        return Promise.reject(axiosError);
    }
});

// Request interceptor to add headers
// Add the Authorization header to every request
// Add the custom x-xsrf-token header to every request
axios.interceptors.request.use(function (config) {
    //var token = authenticationService.getJwt();
    var jti = authenticationService.getJti();
    if(jti) {
        setAuthorization(jti); 
        //config.headers['Authorization'] =  "Bearer " + token;
        config.headers['x-xsrf-token'] = jti;
        config.withCredentials = true; // send the cookie back to the server
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

function setAuthorization(jti) {
//   axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  axios.defaults.headers.common['x-xsrf-token'] = jti;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    //doowadidy: axios.get    // you can map a function to anything
}