import { indexOf, isArray } from 'lodash';
import React from 'react';

const InputRadios = ({label, name, error, helpId, helpText, options, classes, onChange, value, ...rest}) => {
    const helpDiv = !helpId.length ? '' : <small id={helpId} className="form-text text-muted">{helpText}</small>;
    const describedBy = !helpId.length ? '' : helpId;
    classes = "form-group " + (classes ? classes : "");
    return( 
        <div className={classes}>
            <label htmlFor={name}>{label}</label>
            <div className="form-radios">
            { error && <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            }
            {
                options.map(option => 
                    renderSingleRadio(name, describedBy, option, value, onChange)
                )
            }   
            {helpDiv}
            </div>
        </div>
    )
}
function renderSingleRadio(name, describedBy, option, value, onChange) {
    let selected = false;
    if(Array.isArray(value) && value.indexOf(option._id) > -1) {
        selected = true;
    } else {
        if(value.length && value === option._id) {
            selected = true;
        }
    }
    return(
        <div className="form-radio" key={name + "_" + option._id}>
            <input 
            aria-describedby={describedBy} 
            type="radio" 
            name={name} 
            className="form-control"
            id={name + "_" + option._id} 
            value={option._id}
            onChange={onChange}
            defaultChecked={selected}
            />
            <label htmlFor={name + "_" + option._id}>{option.name}</label>
        </div>
    );
}
export default InputRadios;