import React, { Component } from 'react';

class TableHeader extends Component {
    raiseSort = path => {
        const sortColumn = {...this.props.sortColumn};
        sortColumn.order = (path === sortColumn.path)
          ? (sortColumn.order === 'asc' ? 'desc' : 'asc')
          : 'asc';
        sortColumn.path = path;
        this.props.onSort(sortColumn);
    };

    getRenderIcon = column => {
        const {sortColumn} = this.props;

        if(!column.path || column.path !== sortColumn.path) {
            return null;
        }

        if(sortColumn.order === 'asc') {
            return <i className="fa fa-sort-asc"></i>;
        }
        else {
            return <i className="fa fa-sort-desc"></i>;
        }
}

    render() { 
        const {tableColumns} = this.props;
        //console.log(tableColumns);

        return (
            <thead>
                <tr>
                    { tableColumns.map(column => 
                        <th key={column.name || column.key}
                            onClick={() => this.raiseSort(column.path)}>{column.name} {this.getRenderIcon(column)}</th>
                        )
                    }
                </tr>
            </thead>
        );
    }
}
 
export default TableHeader;