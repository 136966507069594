import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class CartLink extends Component {
    render() { 
        return ('');
        // return (
        //     <NavLink className="nav-link" 
        //         key="cart-link"                         
        //         to="/cart"
        //         title="shopping cart">
        //         <i className="fa fa-shopping-cart" aria-label="shopping cart" aria-hidden="true"></i> {this.getCartCount()}
        //     </NavLink>
        // );

    }

    getCartCount() {
        return "";
        // if(this.props.cartCount === 0)
        //     return "";
        // else
        //     return <div className="cart-text">({this.props.cartCount})</div>;
    }
}
 
export default CartLink;
