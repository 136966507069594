import http from "./httpService";
import config from "../config.json";
import {getUser} from "./userService";
//import { options } from "joi-browser";
const orderApiRouteBase = config.orderApiRouteBase;
const storeApiRouteBase = config.storeApiRouteBase;

export async function getMerchantOrders(start, limit) {
    const userData = await getUser();
    const user = userData && userData.data ? userData.data : null;
    if(!user.merchantUuid) {
        return {};
    }
    const response = await http.get(`${orderApiRouteBase}orders/store/${user.storeUuid}?start=${start}&limit=${limit}`);
    return response && response.data ? response.data : {};
}

export async function getMerchantOrder(orderId) {
    const userData = await getUser();
    const user = userData && userData.data ? userData.data : null;
    if(!user.merchantUuid) {
        return {};
    }
    const response = await http.get(`${orderApiRouteBase}orders/${orderId}`);
    if (response.data) {
        return response.data;
    }
    return {};
}

export async function cancelOrder(orderUuid, comment) {
    const response = await http.post(`${orderApiRouteBase}orders/${orderUuid}/cancel`, comment);
    return response;
}

export async function capturePayment(orderUuid, order) {
    const response = await http.post(`${orderApiRouteBase}orders/${orderUuid}/capture`, order);
    return response;
}

export async function getMerchantWithStores(merchantId) {
    const response = await http.get(`${storeApiRouteBase}merchant/${merchantId}/stores`);
    const stores = response && response.data ? response.data : [];
    return stores;
}

export async function getMerchantStore(storeId, start, limit) {
    const response = await http.get(`${storeApiRouteBase}stores/${storeId}?start=${start}&limit=${limit}`);
    return response;
}

export async function getStoreProducts(storeId,start,limit) {
    const response = await http.get(`${storeApiRouteBase}store/${storeId}/products?start=${start}&limit=${limit}`);
    const products = response && response.data ? response.data : [];
    return products;
}

export async function getMerchant(merchantId) {
    const response = await http.get(`${storeApiRouteBase}merchants/${merchantId}`);
    return response;
}

export async function saveMerchant(merchantId, merchant) {
    const response = await http.put(`${storeApiRouteBase}merchants/${merchantId}`, merchant);
    return response;
}

export async function saveMerchantStore(storeId, store) {
    const response = await http.put(`${storeApiRouteBase}stores/${storeId}`, store);
    return response;
}
