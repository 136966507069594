import http from "./httpService";
import config from "../config.json";
// import * as storesAPI from "./storeService";

const importApiRouteBase = config.importApiRouteBase;
const productApiRouteBase = config.productApiRouteBase;

export function getFacets(products) {
  //@todo deprecate this after cleaning up storeProducts.jsx
  return [];
}

//@todo figure out if this is needed
export function unpackFacets(facetQueryStringValue) {
  let facets = [];

  if(facetQueryStringValue && facetQueryStringValue.length) {
      facets = facetQueryStringValue.split("+");
      if(facets.length) {
        facets.map((facetItem,idx) => {
          const f = facetItem.split(":");
          if(f && f.length) {
            facets[idx] = {name:f[0], value:f[1]};
          }       
          return facetItem;
        })
      }
  }
  return facets;
}

export async function getProduct(id) {
  let response = await http.get(`${productApiRouteBase}products/${id}`);
  return response.data;
}

export async function saveProduct(product) {
    // const productToSave = {
    //   uuid: product.uuid,
    //   title: product.title,
    //   storeId: product.storeId,
    //   inStock: product.inStock,
    //   productRating: product.productRating
    // }
    let id = "new";
    if(product.uuid && product.uuid.trim().length > 0) {
      id = product.uuid;
    }
    const response = await http.put(`${productApiRouteBase}products/${id}`, product);
    return response.data;
}

export async function deleteProduct(id) {
  const response = await http.delete(`${productApiRouteBase}products/${id}`);
  return response.data;
}

export async function importProducts(storeUuid, formdata, headers) {
  const axiosConfig = {headers:
    { 'content-type': 'multipart/form-data' }
  };
  // 'Content-Type': `multipart/form-data; boundary=----------------planetB`
  let response = await http.post(`${importApiRouteBase}import/products/` + storeUuid, 
  formdata, axiosConfig);
  return response.data;
}
