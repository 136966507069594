import React, { Component } from 'react';

class NotFound extends Component {
    render() { 
        return(
            <div>
            <h1>Page not found</h1>
            <p>You step in the stream but the water is gone. This page is not here.</p>
            </div>
        );
    }
}
 
export default NotFound;