import React from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import QueryParams from '../common/queryParams';
import { getCart, getLocalCart, saveCart, saveLocalCart } from '../../services/cartService';
import * as userService from '../../services/userService';

class VerifyForm extends InputForm {

    state = {
        data: {
            username: '',
            mfa: ''
        },
        errors: {}
    };

    schema = {
        username: Joi.string().min(5).required().email().label('Username'),
        mfa: Joi.string().min(4).max(10).allow("").label('Verification Code')
    };

    getParams = async (queryParams) => {
        if (queryParams && queryParams.u) {
            let data = this.state.data;
            data.username = queryParams.u;
            this.setState(data);
        }
    }

    //delay = ms => new Promise(res => setTimeout(res, ms));

    doSubmit = async () => {
        try {
            if (this.state.data.mfa.trim().length === 0) {
                toast.error("Please enter the code that was sent to your email address");
            }
            else if (this.state.data.username.trim().length === 0) {
                toast.error("Please enter your username (email address)");
            }
            else {
                try {
                    // have to get the cart before authenticating
                    let cart = await getLocalCart();
                    let response = await userService.verify(this.state.data);
                    // sadly we have to wait for the cookie to get set
                    // otherwise various calls fail when redirects happen
                    //await this.delay(1000);    
                    if (response.data) {
                        const u = response.data;
                        if (u.roleName === "merchant" && u.merchantUuid && u.merchantUuid.length) {
                            window.location = '/mybusiness/' + u.merchantUuid;
                        }
                        else if (u.roleName === "admin") {
                            window.location = '/dashboard';
                        }
                        else {
                            // this is a regular user
                            // if there's a shopping cart sitting in local storage
                            // then set the cart user id and redirect to the checkout page
                            if (cart) {
                                cart = await saveCart(cart, cart.uuid);
                                if(cart) {
                                    window.location = '/cart';
                                }
                            } else {
                                // if we got here, the user might or might not have a saved cart on the server
                                // try to load their cart, and if successful save it to local storage
                                cart = await getCart();
                                await saveLocalCart(cart);
                                if(cart) {
                                    window.location = '/cart';
                                }
                            }
                        }
                    }
		            window.location = '/';
                }
                catch (exception) {
                    this.setState({ working: false });
                    if (exception.response && exception.response.status >= 400) {
                        console.log(exception);
                        const errors = { ...this.state.errors };
                        errors.mfa = exception.response.data.message;
                        this.setState({ errors });
                    }
                }
            }
        }
        catch (exception) {
            this.setState({ working: false });
            console.log('exception response', exception.response);
            if (exception.response && exception.response.status >= 400) {
                const errors = { ...this.state.errors };
                if (exception.response.status === 409) {
                    errors.username = exception.response.data.message;
                }
                else {
                    errors.username = exception.response.data.message;
                }
                this.setState({ errors });
            }
        }
    }

    render() {
        const user = this.props.user;
        if (user && user.uuid) {
            window.location = '/account';
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Verify Email Address</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                    "username", "Username", "text",
                                    "usernameHelp", "The email address you used when creating your account."
                                )
                            }
                            {
                                this.renderInput(
                                    "mfa", "Magic login code", "text",
                                    "mfaHelp", "The code that was emailed to you"
                                )
                            }
                            {this.renderSubmitButton("Verify Email Address")}
                        </form>
                    </div>
                </div>
                <QueryParams onGetParams={this.getParams} />
            </React.Fragment>
        )
    }
}

export default VerifyForm;