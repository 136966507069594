import React, { Component } from 'react';

class FAQ extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Frequently Asked Questions</h1>
                        <div className="faq-qa">
                            <div className="faq-question">What is Planet B Market?</div>
                            <div className="faq-answer">This is an online marketplace for local and small businesses. Planet B Market provides one-stop shopping to make it easy for customers to buy from small businesses they want to target.</div>
                        </div>
                        <div className="faq-qa">
                            <div className="faq-question">How does it work?</div>
                            <div className="faq-answer">Small businesses create their online stores in Planet B Market.
                                &nbsp;Customers shop across all of the market's stores, and can filter by store location, ownership and more.
                                &nbsp; Customers can place orders containing items from one or several stores.
                                &nbsp;The market platform magically calculates, collects, and remits sales tax for orders.
                                &nbsp;Each merchant receives notifications when items from their stores are purchased, and handles order fulfillment for their items.</div>
                        </div>
                        <div className="faq-qa">
                            <div className="faq-question">Who can sell online?</div>
                            <div className="faq-answer">Businesses must be:
                                <ul>
                                    <li>located in one of the 50 states of the USA, or in the District of Columbia</li>
                                    <li>operating legally within their jurisdiction</li>
                                    <li>either be a brick-and-mortar retailer or a manufacturer/artisan</li>
                                    <li>be a small enterprise, with four (4) or fewer locations</li>
                                </ul>
                                Please read the&nbsp;<a href="/terms" title="terms of service">Terms of Service</a> and
                                &nbsp;<a href="/privacy" title="privacy policy">Privacy Policy</a> so you understand what you're committing to.
                            </div>
                        </div>
                        <div className="faq-qa">
                            <div className="faq-question">How much does it cost?</div>
                            <div className="faq-answer">There is no fee to shop online. <br />
                                For sellers, Planet B charges a payment processing fee of 2.9% and 30 cents per transaction.
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FAQ;