import React, { Component } from 'react';
import AccountProfileForm from './accountProfileForm';
import { Link } from "react-router-dom";

class MyAccount extends Component {
    render() { 
        const {user} = this.props;
        return (
            <React.Fragment>
                <h1>My Account</h1>
                <Link to="/account/orders" title="view my orders">My Orders</Link>
                <br />&nbsp;<br />
                <div className="row">
                    <div className="col">
                        <AccountProfileForm user={user}/>
                    </div>
                    {/* <div className="col-3">
                        <Link to="/account/orders" title="">My Orders</Link><br />
                        <Link to="/account/paymentmethods" title="">My Payment Methods</Link>
                    </div> */}
                </div>
            </React.Fragment>
        );
    }
}
 
export default MyAccount;