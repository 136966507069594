import React, { Component } from 'react';
import _ from 'lodash';

class Grid extends Component {

    getGridClasses = (gridClasses) => {
        return "grid row " + gridClasses;
    }

    renderInnerContents = (datum, cellDefinition) => {
        let val = '';
        if(cellDefinition.content) {
            val = cellDefinition.content(datum)
        }
        else {
            val = _.get(datum,cellDefinition.path)
        }
        if(cellDefinition.format) {
            if(cellDefinition.format === 'decimal') {
                val = parseFloat(val).toFixed(2);
            } else if(cellDefinition.format === 'currency') {
                val = '$' + parseFloat(val).toFixed(2);
            }
        }
        return val;
    }

    getDatumId = (datum) => {
        return datum._id ? datum._id : datum.uuid;
    }

    // getImageUri = (datum) => {
    //     if(datum.images && datum.images[0].url) {
    //         return datum.images[0].url
    //     }
    //     if(datum.media && datum.media[0].url) {
    //         return datum.media[0].url
    //     }
    //     else {
    //         return "/default.jpg";
    //     }
    // }

    render() { 
        const {data, gridDefinition, gridClasses} = this.props;
        return (                  
            <div className={this.getGridClasses(gridClasses)}>
                { 
                    data.map(datum => 
                        <div className="grid-item col" key={this.getDatumId(datum)}>
                            {/* <div className="grid-image">
                                <img src={this.getImageUri(datum)} alt={datum.title} />
                            </div> */}
                            <div className="grid-data">
                                { 
                                    gridDefinition.map(def => 
                                        <div key={this.getDatumId(datum) + "_" + def.key} className={def.class ? def.class : ""}>
                                            {this.renderInnerContents(datum,def)}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }                                
            </div>
        );
    }
}
 
export default Grid;