import { useEffect } from 'react'; 
//import React, { useState, useEffect } from 'react'; 
import { useSearchParams } from 'react-router-dom';

//import _ from 'lodash';

const QueryParams = (props) => {
  const [searchParams] = useSearchParams();
  const onGetParams = props.onGetParams;

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    // raise the params up to the parent to handle
    onGetParams(currentParams);
  }, [searchParams,onGetParams]);

  return '';
};

export default QueryParams;