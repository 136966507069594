import http from "./httpService";
import config from "../config.json";
//import { intersect } from "joi-browser";
const adminApiRouteBase = config.adminApiRouteBase;

export async function getDashboardData(dataType,searchterm,start,limit) {
    let querystring = buildQuerystring(searchterm, start, limit);
    if(querystring.length > 0) {
        querystring = "?" + querystring;
    }
    let response = await http.get(`${adminApiRouteBase}dashboard/${dataType}${querystring}`);
    return response;
}

export async function indexProducts(doDelete) {
    let response = await http.get(`${adminApiRouteBase}doindex?del=${doDelete}`);
    return response;
}

function buildQuerystring(searchterm,start,limit) {
    let querystringParts = [];
    let querystring = "";
    if(searchterm && searchterm.trim().length > 0) {
        querystringParts.push({"name":"q","value":searchterm});
    }

    if(start) {
        const iStart = parseInt(start);
        if(iStart > -1) {
            querystringParts.push({"name":"start","value":iStart});
        }
    }

    if(limit) {
        const iLimit = parseInt(limit);
        if(iLimit > -1) {
            querystringParts.push({"name":"limit","value":iLimit});
        }
    }
    
    for (var i=0;i<querystringParts.length;i++) {
        if(querystring.length > 0) {
            querystring += "&";
        }
        querystring += querystringParts[i].name + "=" + querystringParts[i].value;
    }

    return querystring;
}