export async function saveHeaders(headers) {
  if(headers && headers["x-xsrf-token"]) {
    localStorage.setItem("jti", headers["x-xsrf-token"]);
  }
  if(headers && headers["x-userid"]) {
    localStorage.setItem("user", headers["x-userid"]);
  }
}

export async function logout() {
  localStorage.removeItem("jti");
  localStorage.removeItem("user");
  localStorage.removeItem("cart");
}

// since we're storing a httponly cookie, it actually isn't accessible via js
// export function getJwt() {
//   // get the jwt from that cookie ("auth") value and return it
//   //return Cookies.get("auth")
//   console.log("document cookie", document.cookie);
//   const cookies = document.cookie.split(";");
//   console.log("cookies",cookies);
//   let jwtVal = "";
//   for (var i=0;i<cookies.length;i++) {
//     const kv = cookies[i].split("=");
//     if(kv.length == 2 && kv[0] === "auth") {
//       jwtVal = kv[1].trim();
//       console.log("jwtVal", jwtVal);
//     }
//   }
//   return jwtVal;
// }

export function getJti() {
  return localStorage.getItem("jti");
}

export function getUserUuid() {
  return localStorage.getItem("user");
}

export default {
  saveHeaders,
  logout,
  //getJwt,
  getJti,
  getUserUuid
}