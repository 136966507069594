import React, { Component, Fragment } from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import * as userService from '../../services/userService';

class ForgotPasswordForm extends InputForm {

    state = {
        data: {username: ''},
        errors: {},
        mfaEnabled: false
    };

    schema = {
        username: Joi.string().min(5).required().email().label('Username'),
    };

    doSubmit = async() => {
        try {
            await userService.forgotPassword(this.state.data);
            window.location = '/verify?u=' + this.state.data.username;
        }
        catch(exception) {
            this.setState({working:false});
            console.log('exception response', exception.response);
            if(exception.response && exception.response.status >= 400) {
                const errors = {...this.state.errors};
                if(exception.response.status === 409) {
                    errors.username = exception.response.data.message;
                }
                else {
                    errors.username = exception.response.data.message;
                }
                this.setState({errors});
            }
        }
    }

    render() {
        const user = this.props.user;
        if(user && user.uuid) {
            window.location = '/account';
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>Forgot Password</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                "username", "Username", "text", 
                                "usernameHelp", "The email address you used when creating your account."
                                )
                            }
                            { this.renderSubmitButton("Send Authorization Code") }
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    getMfaClasses() {
        if(this.state.mfaEnabled) {
            return "";
        } 
        else {
            return "hidden";
        }
    }
}

export default ForgotPasswordForm;