import React, { Component } from 'react';
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

class Table extends Component {
    raiseSort = path => {
        const sortColumn = {...this.props.sortColumn};
        sortColumn.order = (path === sortColumn.path)
          ? (sortColumn.order === 'asc' ? 'desc' : 'asc')
          : 'asc';
        sortColumn.path = path;
        this.props.onSort(sortColumn);
    };

    getTableClasses = (tableClasses) => {
        return "table " + tableClasses;
    }

    render() { 
        const {tableColumns, sortColumn, data, tableClasses, onSort, classField} = this.props;
        return (                  
            <table className={this.getTableClasses(tableClasses)}>
                <TableHeader 
                    tableColumns={tableColumns}
                    sortColumn={sortColumn}
                    onSort={onSort}
                />
                <TableBody 
                    classField={classField}
                    data={data}
                    tableColumns={tableColumns}
                />
            </table>
        );
    }
}
 
export default Table;