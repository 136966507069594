import React, { Component, Fragment } from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import * as userService from '../../services/userService';

class AccountProfileForm extends InputForm {

    state = {
        user: {},
        data: {
            username: '', 
            password: '', 
            name: '',
            phoneNumber: '',
        },
        errors: {},
        init: false
    };

    // schema doesn't have to be part of the state
    // because the schema doesn't change
    schema = {
        username: Joi.string().min(5).required().email().label('Username'),
        password: Joi.string().min(9).allow('')
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{9,}$/, 
        'password')
        .label('Password'),
        name: Joi.string().min(5).required().label('Full Name'),
        phoneNumber: Joi.string().regex(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)
        .allow('').label('Phone Number'),
    };

    componentDidMount = async() => {
        try {        

            const response = await userService.getUser();
            if(null == response) {
                window.location = '/login';
            }
            const user = response.data;
            if(null === user) {
                window.location = '/login';
            }  
            const data = {
                username: user.username, 
                password: '', 
                name: user.name,
                phoneNumber: user.phoneNumber,
            };
            this.setState({data,init:true,user});
        }
        catch(exception) {
            this.setState({working:false});
            if(exception.response && exception.response.status === 400) {
                if( exception.response.status === 401) {
                    window.location = '/login';
                }
                const errors = {...this.state.errors};
                errors.username = exception.response.data;
                this.setState({errors});
            }
        }
    }

    doSubmit = async () => {
        try {
            const {data, user} = this.state;
            const usernameChanged = user.username !== data.username;

            user.username = data.username;
            user.password = data.password;
            user.phoneNumber = data.phoneNumber;
            user.name = data.name;
            const response = await userService.saveProfile(user);

            if(usernameChanged) {
                toast.info("Your username was changed. Please log in again to verify your new email address.");
                await userService.logout();
                setTimeout( () =>{
                    window.location = '/login?u=' + this.state.data.username;    
                }, 4000);                
            }
            else {
                window.location = '/';
            }
        }
        catch(exception) {
            this.setState({working:false});
            const errors = {...this.state.errors};
            if(exception.response && exception.response.status === 400) {                
                errors.username = exception.response.data.message ? exception.response.data.message : "an unhandled exception occurred";
            } else {
                if(exception.response.data) {
                    const msg = exception.response.data.message ? exception.response.data.message : "an unhandled exception occurred";
                    errors.username = msg;
                    toast.error(msg);
                } else {
                    toast.error("an unhandled exception occurred");
                }
            }
            this.setState({errors});
        }
    }

    render() {
        if(!this.state.init || !this.state.user) {
            return('');
        }
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <p>By default, multi-factor authentication (MFA) messages will be sent to your email address.
                        <br />You can optionally enter a phone number below if you prefer to receive authentication codes via text message.
                        <br />The phone number entered below will only be used for MFA communications. See <a href="/privacy">Planet B Privacy Policy</a></p>
                        <form onSubmit={this.handleSubmit}>
                            {
                                this.renderInput(
                                "username", "Username", "text", 
                                "usernameHelp", "The email address used to create your account."
                                )
                            }
                            {
                                this.renderInput(
                                "password", "Password", "password", 
                                "passwordHelp", "At least 9 characters, must include at least one each of uppercase (A-Z) lowercase (a-z) digit (0-9) special character (#@$!%*?&)"
                                )
                            }
                            {
                                this.renderInput(
                                "phoneNumber", "Phone Number", "text", 
                                "phoneNumberHelp", "(Optional) By default the system will send you an email with your login code. If you enter a phone number here, the system will text you your login code. Must be in the format 123-456-7890"
                                )
                            }
                            {
                                this.renderInput(
                                "name", "Name", "text", 
                                "nameHelp", "Your full name."
                                )
                            }
                            { this.renderSubmitButton("Save Changes") }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default AccountProfileForm;