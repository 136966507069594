import React from 'react';
import RouteParams from '../common/routeParams';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import { getMerchant, saveMerchant } from '../../services/merchantService';

class BusinessEditForm extends InputForm {

    state = {
        merchant: null, // original data loaded for this merchant
        data: {
            _id:null,
            blocked: "no",
            verified: "no",
            uploadsAllowed: "no",
        },
        init: false,
        errors: {}
    };

    schema = {
        _id: Joi.string(),
        blocked: Joi.string().label('Blocked'),
        verified: Joi.string().label('Verified'),
        uploadsAllowed: Joi.string().label('Uploads Allowed'),
    };

    getParams = async (routeParams) => {
        if(!this.state.init) {
            const id = routeParams.id ? routeParams.id : '';
            //@todo fail if user doesn't have admin role
            try {
                const response = await getMerchant(id);
                const merchant = response.data;
                let data = this.state.data;
                if(merchant) {
                    data._id = merchant.uuid;
                    data.blocked = merchant.blocked ? "yes" : "no";
                    data.verified = merchant.verified ? "yes" : "no";
                    data.uploadsAllowed = merchant.uploadsAllowed ? "yes" : "no";
                }
                console.log("data",data);
                this.setState({merchant,data,init:true});
            }
            catch(exception) {
                this.setState({working:false});
                const err = this.getNiceErrorMessage(exception);
                toast.error(err);
            //if(exception.response && exception.response.status >= 400) {
                    const errors = {...this.state.errors};
                    errors.comments = err;
                    this.setState({errors, finalState:'error'});
                //}
            }
        }
    }

    doSubmit = async() => {
        try {
           const {merchant:m, data} = this.state;

            let merchant = m;
            merchant.blocked = data.blocked == "yes" ? true : false;
            merchant.verified = data.verified == "yes" ? true : false;
            merchant.uploadsAllowed = data.uploadsAllowed == "yes" ? true : false;

            const response = await saveMerchant(merchant.uuid, merchant);
            if(response.data) {
                toast.success("Your changes were saved!");
                setTimeout( () =>{
                    window.location = '/business/'+merchant.uuid;
                }, 3500);
            }
            else {
                this.setState({working:false});
                toast.error("Dang! Something prevented your changes from being saved. This issue has been logged and our team will take a look.");
            }
        }
        catch(exception) {
            this.setState({working:false});
            const err = this.getNiceErrorMessage(exception);
            toast.error(err);
           //if(exception.response && exception.response.status >= 400) {
                const errors = {...this.state.errors};
                errors.comments = err;
                this.setState({errors, finalState:'error'});
            //}
        }
    }

    getNiceErrorMessage(exception) {
        let err = "An unexpected error occurred!";
        if(exception.response) {
            if(exception.response.data && exception.response.data.message) {
                err = exception.response.data.message;
            }
        }
        else if (exception.message) {
            err = exception.message;
        }
        return err;
    }

    render() {
        const {merchant,init} = this.state;
        if(!init) {
            return (
                <React.Fragment>
                    <p>Please wait...</p>
                    <RouteParams onGetParams={this.getParams} />
                </React.Fragment>
            );
        } else {
            const yesNoOptions = [
                {"_id":"yes","name":"yes"},
                {"_id":"no","name":"no"}
            ];
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col">
                            <h1>Edit Business {merchant.companyName}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <form onSubmit={this.handleSubmit}>
                                {
                                    this.renderInput(
                                    "blocked", "Blocked", "radios", 
                                    "blockedHelp", "Blocking the merchant will block all related user accounts so that they cannot log in; and none of their products will be displayed in the market", 
                                    "", yesNoOptions
                                    )
                                }
                                {
                                    this.renderInput(
                                    "verified", "Verified", "radios", 
                                    "verifiedHelp", "If a merchant is not verified, their products are not displayed in the market", 
                                    "", yesNoOptions
                                    )
                                }
                                {
                                    this.renderInput(
                                    "uploadsAllowed", "Uploads Allowed", "radios", 
                                    "uploadsAllowedHelp", "If uploads are allowed, the merchant can upload images for their products, and can import products", 
                                    "", yesNoOptions
                                    )
                                }
                                { this.renderSubmitButton("Save") }
                            </form>
                        </div>
                    </div>
                    <RouteParams onGetParams={this.getParams} />
                </React.Fragment>
            );
        }
    }
}

export default BusinessEditForm;